"use strict";

export default class {
    constructor(dataObject = null) {
        this.Id = 0;
        this.Weight = ''; //number
        this.TrackingNumber = '';

        // Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.Weight = dataObject.weight;
                this.TrackingNumber = dataObject.trackingNumber;
            }
            catch (e) {
                throw "Data import error @ TrackingNumberDetails data.";
            }
        }
    }
}