import { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Dimmer, Form, Input, Loader, Message, Modal, Tab, Table } from 'semantic-ui-react';

import { useAppContext } from '../../Contexts/AppContext';
import Functions from '../../Functions';
import * as Messages from '../../Resources/Messages';
import ErrorMessage from '../ErrorMessage';

function generateInitialState(Reducer) {
    const userList = Reducer.userMaster.map(x => Object.assign(Object.create(x), x));
    const loginUsersId = userList.find(x => x.userId == Reducer.userInformation.UserId)?.id;

    return {
        userList, // List of users currently displayed on the screen
        updateUserIds: [], // List of IDs for the modified UserMaster
        loginUsersId, // ID of the currently logged-in user in the UserMaster
        validationErrors: {},
        isUpdateSuccess: false,
        isAddUserSuccess: false,

        // Add user modal
        isAddUserModalOpen: false,
        userId: '',
        userName: '',
    };
}

export default function UsersTab(props) {
    const { Reducer, Actions } = useAppContext();
    const [state, setState] = useState(() => generateInitialState(Reducer));
    const prevReducer_userMasterFetching = useRef(Reducer.userMasterFetching);
    const prevReducer_userSettingUpdating = useRef(Reducer.userSettingUpdating);
    const prevReducer_customerUserAdding = useRef(Reducer.customerUserAdding);
    const prevReducer_manualDownloading = useRef(Reducer.manualDownloading);
    const prevReducer_logouting = useRef(Reducer.logouting);
    const prevState_isAddUserModalOpen = useRef(state.isAddUserModalOpen);

    useEffect(() => {
        return () => {
            loadingErrorFlagInitialization(true);
        };
    }, []);

    useEffect(() => {
        // Initial communication has been completed
        if (prevReducer_userMasterFetching.current && !Reducer.userMasterFetching && !Reducer.userMasterFetchingError) {
            let userList = Reducer.userMaster.map(x => Object.assign(Object.create(x), x))
            let loginUsersId = userList.find(x => x.userId == Reducer.userInformation.UserId).id
            setState(prev => ({ ...prev, userList, loginUsersId }));
        }
        prevReducer_userMasterFetching.current = Reducer.userMasterFetching;
    }, [Reducer.userMasterFetching, Reducer.userMasterFetchingError]);

    useEffect(() => {
        // UserMaster has been successfully updated
        if (prevReducer_userSettingUpdating.current && !Reducer.userSettingUpdating && !Reducer.userSettingUpdatingError) {
            let userList = Reducer.userMaster.map(x => Object.assign(Object.create(x), x))
            setState(prev => ({
                ...prev,
                userList,
                isUpdateSuccess: true,
                updateUserIds: [],
            }));
            props.setHasChanged(false);
        }
        prevReducer_userSettingUpdating.current = Reducer.userSettingUpdating;
    }, [Reducer.userSettingUpdating, Reducer.userSettingUpdatingError]);

    useEffect(() => {
        // User addition has been completed
        if (prevReducer_customerUserAdding.current && !Reducer.customerUserAdding && !Reducer.customerUserAddingError) {
            let userList = Reducer.userMaster.map(x => Object.assign(Object.create(x), x));
            setState(prev => ({
                ...prev,
                userList,
                isAddUserSuccess: true,
                isAddUserModalOpen: false,
                userId: '',
                userName: '',
            }));
        }
        prevReducer_customerUserAdding.current = Reducer.customerUserAdding;
    }, [Reducer.customerUserAdding, Reducer.customerUserAddingError]);

    useEffect(() => {
        // User addition modal has been opened
        if (!prevState_isAddUserModalOpen.current && state.isAddUserModalOpen) {
            let userList = Reducer.userMaster.map(x => Object.assign(Object.create(x), x));
            setState(prev => ({
                ...prev,
                userList,
                updateUserIds: [],
                isUpdateSuccess: false,
            }));
            props.setHasChanged(false);
        }
        prevState_isAddUserModalOpen.current = state.isAddUserModalOpen;
    }, [state.isAddUserModalOpen]);

    useEffect(() => {
        if (
            (!prevReducer_manualDownloading.current && Reducer.manualDownloading)
            || (!prevReducer_logouting.current && Reducer.logouting)
        ) {
            setState(prev => ({ ...prev, validationErrors: {} }));
        }
        prevReducer_manualDownloading.current = Reducer.manualDownloading;
        prevReducer_logouting.current = Reducer.logouting;
    }, [Reducer.manualDownloading, Reducer.logouting]);

    function loadingErrorFlagInitialization(isUnmount = false) {
        if (!isUnmount || !Reducer.userMasterFetchingError) {
            Actions.userSettingUpdatingError(false);
            Actions.customerUserAddingError(false);
            Actions.masterDataFetchingError(false);
            Actions.userMasterFetchError(false);
            Actions.logoutFetchingError(false);
            Actions.manualDownloadingError(false);
        }
    }

    function onTableChange(env, data) {
        const { name, value, userMasterId } = data;
        let userList = state.userList.map(x => Object.assign(Object.create(x), x));
        let index = userList.findIndex(x => x.id == userMasterId);
        userList[index][name] = value;
        let updateUserIds = [...state.updateUserIds];
        if (!updateUserIds.includes(userMasterId)) {
            updateUserIds.push(userMasterId);
        }
        setState(prev => ({ ...prev, userList, updateUserIds }));
        props.setHasChanged(true);
    }

    function onUpdateClick() {
        // validation check
        let updatedUserList = [];
        let validationErrors = {};
        state.updateUserIds.forEach(id => {
            let user = state.userList.find(x => x.id == id);
            if (!validationErrors?.userId) {
                let userId = user.userId.trim().toLowerCase();
                if (!userId) {
                    validationErrors.userId = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERID_EMPTY;
                } else if (Functions.byteLengthOf(userId) > 100) {
                    validationErrors.userId = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERID_LENGTH;
                } else if (state.userList.filter(x => x.userId.trim().toLowerCase() == userId).length > 1) {
                    validationErrors.userId = `"${user.userId}" ${Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERID_DUPLICATE}`;
                }
            }
            if (!validationErrors?.userName) {
                if (Functions.byteLengthOf(user.userName) > 255) {
                    validationErrors.userName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERNAME_LENGTH;
                }
            }
            updatedUserList.push(user);
        })
        setState(prev => ({ ...prev, validationErrors, isAddUserSuccess: false, isUpdateSuccess: false }));
        if (updatedUserList.length == 0) {
            window.alert(Messages.REPAIRREQUEST_MSG_WARNINGMESSAGE_NOCONTENT);
            return;
        }
        if (Object.keys(validationErrors).length == 0) {
            // 自分のUserIdを変更していた場合、セッションが削除されるので、更新後にメッセージを表示する
            let ownUserMaster = Reducer.userMaster.find(x => x.userId == Reducer.userInformation.UserId);
            let isChangeOwnUserId = false;
            if (updatedUserList.some(x => x.id == ownUserMaster.id)) {
                var updateUser = updatedUserList.find(x => x.id == ownUserMaster.id);
                isChangeOwnUserId = updateUser.userId != ownUserMaster.userId;
            }
            Actions.updateUserSetting(updatedUserList, Reducer.csrftoken, isChangeOwnUserId);
        }

        loadingErrorFlagInitialization();
    }

    function onAddUserModalClick() {
        if (!state.isAddUserModalOpen && (state.updateUserIds.length > 0)) {
            if (!window.confirm(Messages.REPAIRREQUEST_MSG_WARNINGMESSAGE_ADDUSER)) {
                return;
            }
        } else if (state.isAddUserModalOpen && state.validationErrors.length > 0) {
            return;
        }
        setState(prev => ({
            ...prev,
            isAddUserModalOpen: !state.isAddUserModalOpen,
            userId: '',
            userName: '',
            validationErrors: {},
            isAddUserSuccess: false,
        }));

        loadingErrorFlagInitialization();
    }

    function onAddUserFormChange(evn, data) {
        setState(prev => ({ ...prev, [data.name]: data.value }));
    }

    function onAddUserSubmitClick() {
        Actions.customerUserAddingError(false);
        let validationErrors = {};
        let userId = state.userId.trim().toLowerCase();
        let userName = state.userName;
        if (!userId) {
            validationErrors.userId = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERID_EMPTY;
        } else if (userId.length > 100) {
            validationErrors.userId = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERID_LENGTH;
        } else if (Reducer.userMaster.find(x => x.userId == userId)) {
            validationErrors.userId = `"${state.userId}" ${Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERID_DUPLICATE}`;
        }
        if (userName.length > 255) {
            validationErrors.userName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERNAME_LENGTH;
        }
        setState(prev => ({ ...prev, validationErrors, isAddUserSuccess: false }));
        if (Object.keys(validationErrors).length == 0) {
            Actions.addCustomerUser(userId, userName, Reducer.csrftoken);
        }
    }

    // Remove space from the input UserId
    function onUserIdBlur() {
        let userList = state.userList.map(user => {
            user.userId = user.userId.replace(/\s+/g, '');
            return user;
        });
        let userId = state.userId.replace(/\s+/g, '');
        setState(prev => ({ ...prev, userList, userId }));
    }

    let defaultBtnColor = 'blue';
    let styleCell = { padding: '0.3em 0.5em' };

    let userInformation = Reducer.userInformation;
    let managerFlag = userInformation.ManagerFlag;

    let errorMessage = null;
    if (Object.keys(state.validationErrors).length) {
        let errors = Object.keys(state.validationErrors).map(x => <>{state.validationErrors[x]}<br /></>);
        errorMessage = <Message error>{errors}</Message>;
    } else if (Reducer.userMasterFetchingError || Reducer.userSettingUpdatingError || Reducer.customerUserAddingError || Reducer.manualDownloadingError || Reducer.logoutingError) {
        errorMessage = <ErrorMessage content={Reducer.errorMessage} active page />;
    }

    let successMessage = null;
    if (state.isUpdateSuccess || state.isAddUserSuccess) {
        successMessage = <Message info>{Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_USERSETTING}</Message>;
    }

    let userTable = state.userList.map(user => {
        // errorFlag
        let userIdErrorFlag = false;
        if (state.validationErrors.userId) {
            if (user.userId === null || user.userId === '' || Functions.byteLengthOf(user.userId) > 100) {
                userIdErrorFlag = true;
            }
        }
        let userNameErrorFlag = false;
        if (state.validationErrors.userName) {
            if (user.userName && Functions.byteLengthOf(user.userName) > 255) {
                userNameErrorFlag = true;
            }
        }

        return (
            <Table.Row key={user.id}>
                <Table.Cell>
                    {managerFlag
                        ? <Input fluid name='userId' value={user.userId} userMasterId={user.id} onChange={onTableChange}
                            onBlur={onUserIdBlur} error={userIdErrorFlag} />
                        : user.userId}
                </Table.Cell>
                <Table.Cell>
                    {managerFlag
                        ? <Input fluid name='userName' value={user.userName} userMasterId={user.id} onChange={onTableChange}
                            error={userNameErrorFlag} />
                        : user.userName}
                </Table.Cell>
                <Table.Cell>
                    {/* Enable for the logged-in user cannot be modified */}
                    <Checkbox toggle name='accountEnable' checked={user.accountEnable} value={!user.accountEnable} onChange={onTableChange} userMasterId={user.id} disabled={!managerFlag || user.id == state.loginUsersId} />
                </Table.Cell>
                <Table.Cell>
                    {user.updateDate ? Functions.dateForm(user.updateDate) : '-'}
                </Table.Cell>
                <Table.Cell>
                    {user.latestLogin ? Functions.dateForm(user.latestLogin) : '-'}
                </Table.Cell>
            </Table.Row>
        );
    });

    let addUserModal;
    if (state.isAddUserModalOpen) {
        let userId = state.userId;
        let userName = state.userName;

        // errorFlag
        let userIdErrorFlag = false;
        if (state.validationErrors.userId) {
            if (userId === null || userId === '' || Functions.byteLengthOf(userId) > 100) {
                userIdErrorFlag = true;
            }
        }
        let userNameErrorFlag = false;
        if (state.validationErrors.userName) {
            if (userName && Functions.byteLengthOf(userName) > 255) {
                userNameErrorFlag = true;
            }
        }

        addUserModal = (
            <Modal open>
                <Modal.Header>Add user to {userInformation.CustomerName}(#{userInformation.CustomerNumber})</Modal.Header>
                <Modal.Content>
                    {errorMessage}
                    <Form>
                        <Form.Input label='User ID' required name='userId' onChange={onAddUserFormChange} value={userId} onBlur={onUserIdBlur} error={userIdErrorFlag} />
                        <Form.Input label='User name' name='userName' onChange={onAddUserFormChange} value={userName} error={userNameErrorFlag} />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button content='Submit' color='blue' onClick={onAddUserSubmitClick} />
                    <Button content='Cancel' onClick={onAddUserModalClick} />
                </Modal.Actions>
                <Dimmer active={Reducer.customerUserAdding}>
                    <Loader content='Loading...' size='huge' />
                </Dimmer>
            </Modal>
        );
    }

    return (
        <Tab.Pane>
            {state.isAddUserModalOpen ? null : errorMessage}
            {successMessage}
            {managerFlag
                ? <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button content='Add user' icon='plus' onClick={onAddUserModalClick} color={defaultBtnColor}
                        disabled={Reducer.userMasterFetchingError} />
                    <Button content='Update' icon='sync alternate' color='green' onClick={onUpdateClick}
                        disabled={Reducer.userMasterFetchingError} />
                </div>
                : null}
            <Table celled striped size='small' compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={styleCell} width={6}>User ID</Table.HeaderCell>
                        <Table.HeaderCell style={styleCell} width={6}>User name</Table.HeaderCell>
                        <Table.HeaderCell style={styleCell} width={0}>Enable</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Update date</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Latest login</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {userTable}
                </Table.Body>
            </Table>
            {addUserModal}
        </Tab.Pane>
    );
}
