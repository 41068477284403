import { Button, Dimmer, Header, Icon, Message } from 'semantic-ui-react';

import { useAppContext } from '../Contexts/AppContext';
import * as Constant from '../Constants/Constant';
import Functions from '../Functions';

export default function ErrorMessage(props) {
    const { Reducer, Actions } = useAppContext();

    function onContactUsClick() {
        Actions.setTabStatus(Constant.REPAIRREQUEST_STAT_CONATCTUS);
    }

    let content = props.content ?? null;
    let active = props.active ?? false;
    let page = props.page ?? false;

    if (Functions.isIncludes(Reducer.comparedPhrase, content)) {
        let button;
        if (Functions.isIncludes([Reducer.comparedPhrase[0]], content)) {
            button = <Button basic inverted color='blue' size='large' style={{ marginLeft: 'auto' }}
                content='Reload' icon='redo' onClick={() => window.location.reload()} />;
        } else if (Functions.isIncludes([Reducer.comparedPhrase[1]], content)) {
            button = <Button basic inverted color='blue' size='large' style={{ marginLeft: 'auto' }}
                content='Go to Contact Us' icon='mail' onClick={onContactUsClick} />;
        }

        return (
            <Dimmer active={active} page={page}>
                <div className='flex-column'>
                    <Header as='h2' icon inverted>
                        <Icon name='warning sign' />
                        {content}
                    </Header>
                    <div style={{ display: 'flex', minWidth: '50vw' }}>
                        {button}
                    </div>
                </div>
            </Dimmer>
        );
    }

    return <Message error content={content} style={{ whiteSpace: 'pre' }} />;
}
