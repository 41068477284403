import AppConfig from '../AppConfig';
import * as Constant from '../Constants/Constant';
import * as Messages from '../Resources/Messages';
import { saveAs } from 'file-saver';
import moment from 'moment';

export default class Actions {
    constructor(dispatch) {
        this.Dispatch = dispatch;
    }

    // SessionCheck
    // #region
    sessionCheck() {
        fetch(AppConfig.ApiUrlLogin, { mode: 'cors', credentials: 'include' })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                if (response.userInformation != null && response.csrfToken != null) {
                    this.setUserInformation(response.userInformation);
                    this.serCsrftoken(response.csrfToken);
                    if (response.userInformation.repairCenterFlag) {
                        this.setTabStatus(Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY);
                    } else {
                        this.setTabStatus(Constant.REPAIRREQUEST_STAT_SELECT_COMPONENT);
                    }
                }
                this.sessionChecking(false);
            })
            .catch(() => {
                this.sessionChecking(false);
            });
    }
    sessionChecking(bool) {
        this.Dispatch({
            type: Constant.ROOT_ACT_SESSIONCHECK_FETCHING,
            bool: bool,
        });
    }
    // #endregion

    // LoginCheck
    // #region
    LoginCheck(userId, password) {
        this.loginCheckingError(false);
        this.loginChecking(true);
        fetch(AppConfig.ApiUrlLogin, {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId, password })
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                this.loginChecking(false);
                return response;
            })
            .then(response => response.json())
            .then(response => {
                if (response.userInformation != null && response.csrfToken != null) {
                    this.setUserInformation(response.userInformation);
                    this.serCsrftoken(response.csrfToken);
                    if (response.userInformation.repairCenterFlag) {
                        this.setTabStatus(Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY);
                    } else {
                        this.setTabStatus(Constant.REPAIRREQUEST_STAT_SELECT_COMPONENT);
                    }
                } else {
                    this.loginCheckingError(true, Messages.REPAIRREQUEST_MSG_ERROROCCURREDATDB);
                }
            })
            .catch(e => {
                this.loginCheckingError(true, e.message);
                this.loginChecking(false);
            });
    }
    loginCheckingError(bool, message = '') {
        this.Dispatch({
            type: Constant.LOGIN_ACT_LOGINCHECK_FETCHINGERROR,
            bool: bool,
            message: message,
        });
    }
    loginChecking(bool) {
        this.Dispatch({
            type: Constant.LOGIN_ACT_LOGINCHECK_FETCHING,
            bool: bool,
        });
    }
    // #endregion

    // Login - InformationFetch
    // #region
    informationFetch() {
        this.informationFetchingError(false);
        this.informationFetching(true);
        fetch(AppConfig.ApiUrlLogin + '/information', { mode: 'cors', credentials: 'include' })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                this.informationFetching(false);
                return response;
            })
            .then(response => response.json())
            .then(information => {
                this.informationFetchSuccess(information);
            })
            .catch(() => {
                this.informationFetchingError(true);
                this.informationFetching(false);
            });
    }
    informationFetchingError(bool) {
        this.Dispatch({
            type: Constant.LOGIN_ACT_INFORMATIONFETCH_FETCHINGERROR,
            bool: bool,
        });
    }
    informationFetching(bool) {
        this.Dispatch({
            type: Constant.LOGIN_ACT_INFORMATIONFETCH_FETCHING,
            bool: bool,
        });
    }
    informationFetchSuccess(information) {
        if (!information) {
            information = {};
        }
        this.Dispatch({
            type: Constant.LOGIN_ACT_INFORMATIONFETCH_SUCCESS,
            information: information,
        });
    }
    // #endregion

    // Shipping History
    // #region
    // partialFlag-> True: 最新100件の更新、False: 全件取得
    requestFetch(csrftoken, partialFlag) {
        this.requestFetchingError(false);
        this.requestFetching(true);
        fetch(AppConfig.ApiUrlHistory + (partialFlag ? '/partial' : ''), {
            mode: 'cors', credentials: 'include',
            headers: { 'csrftoken': JSON.stringify(csrftoken) }
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                this.requestFetching(false);
                return response;
            })
            .then(response => response.json())
            .then(response => {
                this.requestFetchSuccess(response);
            })
            .catch((e) => {
                this.requestFetchingError(true, e.message);
                this.requestFetching(false);
            });
    }
    requestFetchingError(bool, message = '') {
        this.Dispatch({
            type: Constant.HISTORY_ACT_REQUESTFETCH_LOADINGERROR,
            bool: bool,
            message: message,
        });
    }
    requestFetching(bool) {
        this.Dispatch({
            type: Constant.HISTORY_ACT_REQUESTFETCH_LOADING,
            bool: bool,
        });
    }
    requestFetchSuccess(response) {
        this.Dispatch({
            type: Constant.HISTORY_ACT_REQUESTFETCH_SUCCESS,
            repairRequestHeaders: response.headers,
            repairRequestDetails: response.details,
            trackingNumberDetails: response.trackingNumberDetails,
        });
    }
    // #endregion

    // Reuest - master fetch
    // #region
    // ShipTo,Category,WarrantyMaster,CustomerAddressBookデータを取得
    masterDataFetch(csrftoken) {
        this.masterDataFetchingError(false);
        this.masterDataFetching(true);
        fetch(AppConfig.ApiUrlRequest, {
            mode: 'cors', credentials: 'include',
            headers: { 'csrftoken': JSON.stringify(csrftoken) },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                this.masterDataFetchingSuccess(response);
                this.setCustomerAddressBook(response.customerAddressBook);
                this.setRepairServicePlanMaster(response.repairServicePlanMaster, response.repairServicePlanPrices);
                this.setModelRuleMaster(response.modelRuleMaster);
                this.setModelRulesForRepairServicePlanCoverage(response.coverages);
                this.masterDataFetching(false);
            })
            .catch(e => {
                this.masterDataFetchingError(true, e.message);
                this.masterDataFetching(false);
            });
    }
    masterDataFetchingError(bool, message = '') {
        this.Dispatch({
            type: Constant.REQUEST_ACT_MASTERDATAFETCH_FETCHINGERROR,
            bool: bool,
            message: message,
        });
    }
    masterDataFetching(bool) {
        this.Dispatch({
            type: Constant.REQUEST_ACT_MASTERDATAFETCH_FETCHING,
            bool: bool,
        });
    }
    masterDataFetchingSuccess(response) {
        this.Dispatch({
            type: Constant.REQUEST_ACT_MASTERDATAFETCH_SUCCESS,
            ...response,
        });
    }
    //#endregion

    // Request - submit
    // #region
    submitRequest(csrftoken, request, isSaveAddressBook) {
        this.requestSubmittingError(false);
        this.requestSubmitting(true);
        fetch(AppConfig.ApiUrlRequest + '/submitRequest', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(csrftoken)
            },
            body: JSON.stringify({ request, isSaveAddressBook }),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                this.requestSubmitting(false);
                return response;
            })
            .then(response => response.json())
            .then(response => {
                this.requestSubmitSuccess(response);
            })
            .catch(e => {
                this.requestSubmittingError(true, e.message);
                this.requestSubmitting(false);
            });
    }
    requestSubmittingError(bool, message = '') {
        this.Dispatch({
            type: Constant.REQUEST_ACT_REQUESTSUBMIT_FETCHINGERROR,
            bool: bool,
            message: message,
        });
    }
    requestSubmitting(bool) {
        this.Dispatch({
            type: Constant.REQUEST_ACT_REQUESTSUBMIT_FETCHING,
            bool: bool,
        });
    }
    requestSubmitSuccess(response) {
        this.Dispatch({
            type: Constant.REQUEST_ACT_REQUESTSUBMIT_SUCCESS,
            id: response.id,
            trackingNumberDetails: response.trackingNumberDetails,
        });
    }
    completeRequestModal(bool) {
        this.Dispatch({
            type: Constant.REQUEST_STAT_COMPLETEMODAL,
            bool,
        });
    }
    // #endregion

    // Log out
    // #region
    logout() {
        this.logoutFetchingError(false);
        this.logoutFetching(true);
        fetch(AppConfig.ApiUrlLogin + '/logout', {
            mode: 'cors', method: 'delete', credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                this.logoutFetching(false);
                this.logoutSuccess();
            })
            .catch(e => {
                this.logoutFetchingError(true, e.message);
                this.logoutFetching(false);
            });
    }
    logoutFetchingError(bool, message = '') {
        this.Dispatch({
            type: Constant.ROOT_ACT_LOGOUT_FETCHINGERROR,
            bool: bool,
            message: message,
        });
    }
    logoutFetching(bool) {
        this.Dispatch({
            type: Constant.ROOT_ACT_LOGOUT_FETCHING,
            bool: bool,
        });
    }
    logoutSuccess() {
        this.Dispatch({
            type: Constant.ROOT_ACT_LOGOUT_SUCCESS,
        });
    }
    // #endregion

    // User Setting
    //#region
    userMasterFetch(csrftoken) {
        this.userMasterFetchError(false);
        this.userMasterFetching(true);
        fetch(AppConfig.ApiUrlUserSetting, {
            mode: 'cors', credentials: 'include',
            headers: {
                'csrftoken': JSON.stringify(csrftoken)
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                this.userMasterFetchingSuccess(response.userMaster);
                this.setCustomerAddressBook(response.customerAddressBook);
                this.setCustomerEndUsers(response.customerEndUsers);
                this.userMasterFetching(false);
            })
            .catch(e => {
                this.userMasterFetchError(true, e.message);
                this.userMasterFetching(false);
            });
    }
    userMasterFetchError(bool, message = '') {
        this.Dispatch({
            type: Constant.USERSETTING_ACT_USERMASTERFETCH_FETCHINGERROR,
            bool: bool,
            message: message,
        });
    }
    userMasterFetching(bool) {
        this.Dispatch({
            type: Constant.USERSETTING_ACT_USERMASTERFETCH_FETCHING,
            bool: bool,
        });
    }
    userMasterFetchingSuccess(userMaster) {
        this.Dispatch({
            type: Constant.USERSETTING_ACT_USERMASTERFETCH_SUCCESS,
            userMaster: userMaster,
        });
    }

    updateUserSetting(userList, csrftoken, isChangeOwnUserId) {
        this.userSettingUpdatingError(false);
        this.userSettingUpdating(true);
        fetch(AppConfig.ApiUrlUserSetting + '/update', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(csrftoken)
            },
            body: JSON.stringify(userList),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                this.updateUserSettingSuccess(response.returnUserMaster, isChangeOwnUserId);
                if (!isChangeOwnUserId) {
                    this.setUserInformation(response.userInformation);
                }
                this.userSettingUpdating(false);
            })
            .catch(e => {
                this.userSettingUpdatingError(true, e.message);
                this.userSettingUpdating(false);
            });
    }
    userSettingUpdatingError(bool, message = '') {
        this.Dispatch({
            type: Constant.USERSETTING_ACT_UPDATE_FETCHINGERROR,
            bool: bool,
            message: message,
        });
    }
    userSettingUpdating(bool) {
        this.Dispatch({
            type: Constant.USERSETTING_ACT_UPDATE_FETCHING,
            bool: bool,
        });
    }
    updateUserSettingSuccess(userMaster, isChangeOwnUserId) {
        this.Dispatch({
            type: Constant.USERSETTING_ACT_UPDATE_SUCCESS,
            userMaster: userMaster,
            isChangeOwnUserId: isChangeOwnUserId,
        });
    }

    addCustomerUser(userId, userName, csrftoken) {
        this.customerUserAddingError(false);
        this.customerUserAdding(true);
        fetch(AppConfig.ApiUrlUserSetting + '/insert', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(csrftoken)
            },
            body: JSON.stringify({ userId, userName }),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                this.addCustomerUserSuccess(response);
                this.customerUserAdding(false);
            })
            .catch(e => {
                this.customerUserAddingError(true, e.message);
                this.customerUserAdding(false);
            });
    }
    customerUserAddingError(bool, message = '') {
        this.Dispatch({
            type: Constant.USERSETTING_ACT_INSERT_FETCHINGERROR,
            bool: bool,
            message: message,
        });
    }
    customerUserAdding(bool) {
        this.Dispatch({
            type: Constant.USERSETTING_ACT_INSERT_FETCHING,
            bool: bool,
        });
    }
    addCustomerUserSuccess(userMaster) {
        this.Dispatch({
            type: Constant.USERSETTING_ACT_INSERT_SUCCESS,
            userMaster: userMaster,
        });
    }
    // #endregion

    // File download (Shipping label / Packing slip)
    //#region
    fileDownload(csrftoken, labelType, repairRequestHeaderId) {
        this.fileDownloadingError(false);
        this.fileDownloading(true);
        fetch(AppConfig.ApiUrlFileDownload + '/' + labelType + '/?id=' + repairRequestHeaderId, {
            mode: 'cors', credentials: 'include',
            headers: { 'csrftoken': JSON.stringify(csrftoken) },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                this.fileDownloading(false);
                return response;
            })
            .then(response => response.blob())
            .then(blob => {
                saveAs(blob, labelType + '_' + moment(Date()).format('MMDDYYYY'));
            })
            .catch(e => {
                this.fileDownloadingError(true, e.message);
                this.fileDownloading(false);
            });
    }
    fileDownloading(bool) {
        this.Dispatch({
            type: Constant.FILEDOWNLOAD_ACT_DOWNLOADING,
            bool,
        });
    }
    fileDownloadingError(bool, message = '') {
        this.Dispatch({
            type: Constant.FILEDOWNLOAD_ACT_DOWNLOADINGERROR,
            bool,
            message,
        });
    }
    //#endregion

    // File download (Manual)
    //#region
    manualDownload(csrftoken) {
        this.manualDownloadingError(false);
        this.manualDownloading(true);
        fetch(AppConfig.ApiUrlFileDownload + '/manual', {
            mode: 'cors', credentials: 'include',
            headers: { 'csrftoken': JSON.stringify(csrftoken) },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                return response;
            })
            .then(response => response.blob())
            .then(blob => {
                saveAs(blob, Constant.FILEDOWNLOAD_NAME_MANUAL);
            })
            .catch(e => {
                this.manualDownloadingError(true, e.message);
            })
            .finally(() => {
                this.manualDownloading(false);
            });
    }
    manualDownloading(bool) {
        this.Dispatch({
            type: Constant.FILEDOWNLOAD_ACT_MANUALDOWNLOADING,
            bool,
        });
    }
    manualDownloadingError(bool, message = '') {
        this.Dispatch({
            type: Constant.FILEDOWNLOAD_ACT_MANUALDOWNLOADINGERROR,
            bool,
            message,
        });
    }
    //#endregion

    // Get CustomerAddressBook
    //#region
    customerAddressBookFetch(csrftoken) {
        this.customerAddressBookFetchingError(false);
        this.customerAddressBookFetching(true);
        fetch(AppConfig.ApiUrlRequest + '/customerAddressBook', {
            mode: 'cors', credentials: 'include',
            headers: { 'csrftoken': JSON.stringify(csrftoken) },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(customerAddressBook => {
                this.setCustomerAddressBook(customerAddressBook);
                this.customerAddressBookFetching(false);
            })
            .catch(e => {
                this.customerAddressBookFetchingError(true, e.message);
                this.customerAddressBookFetching(false);
            });
    }
    customerAddressBookFetchingError(bool, message = '') {
        this.Dispatch({
            type: Constant.ROOT_ACT_CUSTOMERADDRESSBBOK_FETCHINGERROR,
            bool: bool,
            message: message,
        });
    }
    customerAddressBookFetching(bool) {
        this.Dispatch({
            type: Constant.ROOT_ACT_CUSTOMERADDRESSBBOK_FETCHING,
            bool: bool,
        });
    }
    //#endregion

    // Protection plan - fetch protection plan data list
    //#region
    fetchProtectionPlanList(csrftoken, isAllItem) {
        this.protectionPlanListFetchingError(false);
        this.protectionPlanListFetching(true);
        fetch(AppConfig.ApiUrlProtectionPlan + (isAllItem ? '' : '/partial'), {
            mode: 'cors', credentials: 'include',
            headers: {
                'csrftoken': JSON.stringify(csrftoken)
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                this.updateProtectionPlan(response.protectionPlanHeaders, response.protectionPlanDetails);
                this.protectionPlanListFetching(false);
            })
            .catch(e => {
                this.protectionPlanListFetchingError(true, e.message);
                this.protectionPlanListFetching(false);
            });
    }
    protectionPlanListFetchingError(bool, message = '') {
        this.Dispatch({
            type: Constant.PROTECTIONPLAN_ACT_PROTECTIONPLANLIST_FETCHINGERROR,
            bool: bool,
            message: message,
        });
    }
    protectionPlanListFetching(bool) {
        this.Dispatch({
            type: Constant.PROTECTIONPLAN_ACT_PROTECTIONPLANLIST_FETCHING,
            bool: bool,
        });
    }
    //#endregion

    // Protectoin plan - fetch protection plan data by headerId
    //#region
    fetchProtectionPlan(csrftoken, headerId) {
        this.protectionPlanFetchingError(false);
        this.protectionPlanFetching(true);
        fetch(AppConfig.ApiUrlProtectionPlan + '/' + headerId, {
            mode: 'cors', credentials: 'include',
            headers: {
                'csrftoken': JSON.stringify(csrftoken)
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                this.updateProtectionPlan([response.protectionPlanHeader], response.protectionPlanDetails);
                this.protectionPlanFetching(false);
            })
            .catch(e => {
                this.protectionPlanFetchingError(true, e.message);
                this.protectionPlanFetching(false);
            });
    }
    protectionPlanFetchingError(bool, message = '') {
        this.Dispatch({
            type: Constant.PROTECTIONPLAN_ACT_PROTECTIONPLAN_FETCHINGERROR,
            bool: bool,
            message: message,
        });
    }
    protectionPlanFetching(bool) {
        this.Dispatch({
            type: Constant.PROTECTIONPLAN_ACT_PROTECTIONPLAN_FETCHING,
            bool: bool,
        });
    }
    //#endregion

    // Protection plan - fetch End-user information
    //#region
    fetchCustomerEndUsers(csrftoken) {
        this.customerEndUsersFetchingError(false);
        this.customerEndUsersFetching(true);
        fetch(AppConfig.ApiUrlProtectionPlan + "/EndUsers", {
            mode: 'cors', credentials: 'include',
            headers: {
                'csrftoken': JSON.stringify(csrftoken)
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(customerEndUsers => {
                this.setCustomerEndUsers(customerEndUsers);
                this.customerEndUsersFetching(false);
            })
            .catch(e => {
                this.customerEndUsersFetchingError(true, e.message);
                this.customerEndUsersFetching(false);
            });
    }
    customerEndUsersFetchingError(bool, message = '') {
        this.Dispatch({
            type: Constant.PROTECTIONPLAN_ACT_CUSTOMERENDUSERS_FETCHINGERROR,
            bool,
            message,
        });
    }
    customerEndUsersFetching(bool) {
        this.Dispatch({
            type: Constant.PROTECTIONPLAN_ACT_CUSTOMERENDUSERS_FETCHING,
            bool,
        });
    }
    //#endregion

    // Protection plan - fetch invoices
    //#region
    fetchInvoices(csrftoken, invoiceNumber) {
        this.invoiceFetchingError(false);
        this.invoiceFetching(true);
        fetch(AppConfig.ApiUrlProtectionPlan + "/Invoices/" + encodeURIComponent(invoiceNumber), {
            mode: 'cors', credentials: 'include',
            headers: {
                'csrftoken': JSON.stringify(csrftoken)
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(invoices => {
                if (invoices.length === 0) {
                    throw new Error(Messages.REPAIRREQUEST_MSG_ERRORMESSAGE_INVOICE_CANNOT_REGISTER);
                }
                this.setTempInvoiceMaster(invoices);
                this.invoiceFetching(false);
            })
            .catch(e => {
                this.invoiceFetchingError(true, e.message);
                this.invoiceFetching(false);
            });
    }
    invoiceFetchingError(bool, message = '') {
        this.Dispatch({
            type: Constant.PROTECTIONPLAN_ACT_INVOICE_FETCHINGERROR,
            bool: bool,
            message: message,
        });
    }
    invoiceFetching(bool) {
        this.Dispatch({
            type: Constant.PROTECTIONPLAN_ACT_INVOICE_FETCHING,
            bool: bool,
        });
    }
    //#endregion

    // Protection plan - save
    // #region
    saveProtectionPlan(csrftoken, header, details, isSaveEndUser) {
        this.protectionPlanSavingError(false);
        this.protectionPlanSaving(true);
        fetch(AppConfig.ApiUrlProtectionPlan, {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(csrftoken)
            },
            body: JSON.stringify({ header, details, isSaveEndUser }),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                this.protectionPlanSaving(false);
            })
            .catch(e => {
                this.protectionPlanSavingError(true, e.message);
                this.protectionPlanSaving(false);
            });
    }
    protectionPlanSavingError(bool, message = '') {
        this.Dispatch({
            type: Constant.PROTECTIONPLAN_ACT_SAVINGERROR,
            bool: bool,
            message: message,
        });
    }
    protectionPlanSaving(bool) {
        this.Dispatch({
            type: Constant.PROTECTIONPLAN_ACT_SAVING,
            bool: bool,
        });
    }
    // #endregion

    // set state
    setUserInformation(userInformation) {
        this.Dispatch({
            type: Constant.LOGIN_ACT_SET_USERINFORMATION,
            userInformation: userInformation,
        });
    }
    serCsrftoken(csrftoken) {
        this.Dispatch({
            type: Constant.LOGIN_ACT_SET_CSRFTOKEN,
            csrftoken: csrftoken,
        });
    }
    setDisclaimer(isDisclaimerOpen) {
        this.Dispatch({
            type: Constant.REQUEST_ACT_SET_DISCLAIMER,
            isDisclaimerOpen: isDisclaimerOpen,
        });
    }
    setCustomerAddressBook(customerAddressBook) {
        this.Dispatch({
            type: Constant.REQUEST_ACT_SET_CUSTOMERADDRESSBOOK,
            customerAddressBook,
        });
    }
    setTempInvoiceMaster(invoices) {
        this.Dispatch({
            type: Constant.PROTECTOPNPLAN_ACT_SET_TEMPINVOICEMASTER,
            invoices,
        });
    }
    updateProtectionPlan(headers, details) {
        this.Dispatch({
            type: Constant.PROTECTOPNPLAN_ACT_UPDATE_PROTECTIONPLAN,
            headers,
            details,
        });
    }
    setCustomerEndUsers(customerEndUsers) {
        this.Dispatch({
            type: Constant.USERSETTING_ACT_SET_CUSTOMERENDUSERS,
            customerEndUsers,
        });
    }
    setRepairServicePlanMaster(repairServicePlanMaster, repairServicePlanPrices) {
        this.Dispatch({
            type: Constant.PROTECTOPNPLAN_ACT_SET_REPAIRSERVICEPLANMASTER,
            repairServicePlanMaster,
            repairServicePlanPrices,
        });
    }
    setModelRuleMaster(modelRuleMaster) {
        this.Dispatch({
            type: Constant.PROTECTOPNPLAN_ACT_SET_MODELRULEMASTER,
            modelRuleMaster,
        });
    }
    setModelRulesForRepairServicePlanCoverage(coverages) {
        this.Dispatch({
            type: Constant.PROTECTOPNPLAN_ACT_SET_MODELRULESFORREPAIRSERVICEPLANCOVERAGE,
            coverages,
        });
    }

    // set tabStatus
    setTabStatus(tabStatus) {
        this.Dispatch({
            type: Constant.REPAIRREQUEST_ACT_SET_TABSTATUS,
            tabStatus,
        });
    }
}
