import { useEffect } from 'react';
import { Button, Dimmer, Grid, Header, Loader, Modal, Segment, Table } from 'semantic-ui-react';

import { useAppContext } from '../../../Contexts/AppContext';
import Functions from '../../../Functions';
import ErrorMessage from '../../ErrorMessage';

const marginRightStyle = { marginRight: 20, display: 'inline-block' };

export default function ProtectionPlanConfirm(props) {
    const { Reducer, Actions } = useAppContext();

    useEffect(() => {
        return () => {
            Actions.protectionPlanSavingError(false);
        };
    }, []);

    function onRegisterClick() {
        document.querySelector('#confirmModal').scrollTo(0, 0);
        // DB通信を行う
        Actions.saveProtectionPlan(
            Reducer.csrftoken,
            props.header,
            props.invoices,
            props.isSaveEndUser,
        );
    }


    let errorMessage = null;
    if (Reducer.protectionPlanSavingError) {
        errorMessage = <ErrorMessage page active content={Reducer.errorMessage} />;
    }

    const models = props.invoices.map(x => x.model);
    const modelSet = new Set(models);
    const uniqueModelList = [...modelSet.values()];

    const invoiceRows =
        uniqueModelList.map(model => {
            const qty = props.invoices.filter(x => x.model == model).length;
            return <Table.Row>
                <Table.Cell>{model}</Table.Cell>
                <Table.Cell>{qty}</Table.Cell>
            </Table.Row>;
        });

    //Estimated priceの表示
    let planIds = props.invoices.map(x => x.planId);
    //選択したプランがない(null)場合は表示しない
    planIds = planIds.filter(x => x !== null);
    const planIdSet = new Set(planIds);
    const uniquePlanIdList = [...planIdSet.values()];

    let total = 0;

    const estimatedPriceRows =
        uniquePlanIdList.map(planId => {
            const selectedPlan = Reducer.repairServicePlanMaster.find(x => x.Id == planId);
            const price = selectedPlan.getPrice(props.header.purchaseDate);
            const qty = props.invoices.filter(x => x.planId == planId).length;
            const subtotal = Functions.decimalMultiplication(price, qty);
            total = Functions.decimalAddition(total, Number(subtotal));

            return <Table.Row>
                <Table.Cell>{selectedPlan.PlanName}</Table.Cell>
                <Table.Cell>${Functions.toNumberToLocaleString(price, 2)}</Table.Cell>
                <Table.Cell>{qty}</Table.Cell>
                <Table.Cell>${Functions.toNumberToLocaleString(subtotal, 2)}</Table.Cell>
            </Table.Row>;
        });

    return (
        <Modal open={props.modalOpen} size='fullscreen' id='fullscreenModal'>
            <Modal.Header>
                <Header content='Please confirm and press register button' />
            </Modal.Header>
            <Modal.Content scrolling style={{ maxHeight: '70vh' }} id='confirmModal'>
                {errorMessage}
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Segment>
                                <Header icon='user' content='End-user information' dividing />
                                <dl>
                                    <div style={marginRightStyle}>
                                        <dt>End-user name</dt>
                                        <dd>{props.header.endUserName}</dd>
                                    </div>
                                    <div style={marginRightStyle}>
                                        <dt>Contact name</dt>
                                        <dd>{props.header.contactName}</dd>
                                    </div>
                                    <div style={marginRightStyle}>
                                        <dt>Email</dt>
                                        <dd>{props.header.email}</dd>
                                    </div>
                                </dl>
                                <dl style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={marginRightStyle}>
                                        <dt>Address</dt>
                                        <dd>{props.header.addressLineOne}</dd>
                                        <dd>{props.header.addressLineTwo ? props.header.addressLineTwo : '-'}</dd>
                                        <dd>{props.header.addressLineThree ? props.header.addressLineThree : '-'}</dd>
                                        <dd>{props.header.city}, {props.header.state} {props.header.zip}</dd>
                                        <dd>{props.header.country}</dd>
                                        <div style={marginRightStyle}>
                                            <dt>Telephone</dt>
                                            <dd>{props.header.telephone}</dd>
                                        </div>
                                        <div style={marginRightStyle}>
                                            <dt>Fax</dt>
                                            <dd>{props.header.fax ? props.header.fax : '-'}</dd>
                                        </div>
                                    </div>
                                </dl>
                                <dl>
                                    <dt>Note</dt>
                                    <dd>
                                        <pre style={{ whiteSpace: 'pre-line', marginTop: 0, marginBottom: 0 }}>
                                            {props.header.note ? props.header.note : '-'}
                                        </pre>
                                    </dd>
                                </dl>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment style={{ height: '100%' }}>
                                <Header icon='cart' content='Purchase information' dividing />
                                <dl>
                                    <div style={marginRightStyle}>
                                        <dt>PO</dt>
                                        <dd>{props.header.po}</dd>
                                    </div>
                                    <div style={marginRightStyle}>
                                        <dt>Purchase date</dt>
                                        <dd>{Functions.dateForm(props.header.purchaseDate)}</dd>
                                    </div>
                                </dl>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <Header icon='file text' content='Invoice information' dividing />
                                <Table striped celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell content='Model' />
                                            <Table.HeaderCell content='Quantity' />
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {invoiceRows}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <Header icon='dollar' content='Estimated price' dividing />
                                <Table striped celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell content='Plan name' />
                                            <Table.HeaderCell content='Price' />
                                            <Table.HeaderCell content='Quantity' />
                                            <Table.HeaderCell content='Subtotal' />
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {estimatedPriceRows}
                                    </Table.Body>
                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='4' style={{ textAlign: 'right' }}>
                                                <b>Total (tax not included)</b> : ${Functions.toNumberToLocaleString(total, 2)}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Close" onClick={props.onCloseClick} />
                <Button content="Register" color='blue' onClick={onRegisterClick} />
            </Modal.Actions>
            <Dimmer active={Reducer.protectionPlanSaving}>
                <Loader content='Loading...' size='huge' />
            </Dimmer>
        </Modal>
    );
}
