import { useEffect, useRef, useState } from 'react';
import { Button, Dimmer, Divider, Dropdown, Form, Grid, Header, Icon, Input, Loader, Message, Modal, Popup, Segment, Table, TextArea } from 'semantic-ui-react';

import * as Constant from '../../../Constants/Constant';
import { useAppContext } from '../../../Contexts/AppContext';
import Functions from '../../../Functions';
import RepairRequest from '../../../Models/ViewModels/RepairRequest';
import RepairRequestDetail from '../../../Models/ViewModels/RepairRequestDetail';
import TrackingNumberDetail from '../../../Models/ViewModels/TrackingNumberDetail';
import * as Messages from '../../../Resources/Messages';
import ErrorMessage from '../../ErrorMessage';
import RequestConfirm from './RequestConfirm';

const initialState = {
    repairRequest: new RepairRequest(),
    numberOfPackingBox: 1,
    selectedCustomerAddressBookId: 0,
    isSaveAddressBook: true,
    selectedShipToMasterId: 0,
    isConfirmModalOpen: false,

    errors: {},
};

export default function Request() {
    const { Reducer, Actions } = useAppContext();
    const [state, setState] = useState(initialState);
    const prevReducer_requestSubmitting = useRef(Reducer.requestSubmitting);
    const prevReducer_manualDownloading = useRef(Reducer.manualDownloading);
    const prevReducer_logouting = useRef(Reducer.logouting);

    useEffect(() => {
        Actions.customerAddressBookFetch(Reducer.csrftoken);
        initialization();

        return () => {
            loadingErrorFlagInitialization();
        };
    }, []);

    useEffect(() => {
        if (prevReducer_requestSubmitting.current && !Reducer.requestSubmitting && !Reducer.requestSubmittingError) {
            setState(prev => ({ ...prev, isConfirmModalOpen: false }));
        }
        prevReducer_requestSubmitting.current = Reducer.requestSubmitting;
    }, [Reducer.requestSubmitting, Reducer.requestSubmittingError]);

    useEffect(() => {
        if (
            (!prevReducer_manualDownloading.current && Reducer.manualDownloading)
            || (!prevReducer_logouting.current && Reducer.logouting)
        ) {
            setState(prev => ({ ...prev, errors: {} }));
        }
        prevReducer_manualDownloading.current = Reducer.manualDownloading;
        prevReducer_logouting.current = Reducer.logouting;
    }, [Reducer.manualDownloading, Reducer.logouting]);

    function loadingErrorFlagInitialization() {
        Actions.masterDataFetchingError(false);
        Actions.logoutFetchingError(false);
        Actions.fileDownloadingError(false);
        Actions.completeRequestModal(false);
        Actions.manualDownloadingError(false);
        Actions.customerAddressBookFetchingError(false);
    }

    function onAddLineClick() {
        let repairDetail = new RepairRequestDetail();
        let repairRequest = JSON.parse(JSON.stringify(state.repairRequest));
        repairRequest.RepairDetails.push(repairDetail);
        setState(prev => ({
            ...prev,
            repairRequest: repairRequest,
        }));
    }

    function onModelDeleteClick(evn, data) {
        let repairRequest = JSON.parse(JSON.stringify(state.repairRequest));
        const { idx } = data;
        repairRequest.RepairDetails = repairRequest.RepairDetails.filter((x, index) => index != idx);
        setState(prev => ({
            ...prev,
            repairRequest: repairRequest,
        }));
    }

    function onDetailTextChange(evn, data) {
        let repairRequest = JSON.parse(JSON.stringify(state.repairRequest));
        const { name, idx, value } = data;
        repairRequest.RepairDetails[idx][name] = value;
        setState(prev => ({
            ...prev,
            repairRequest: repairRequest,
        }));
    }

    function onTextChange(evn, data) {
        let repairRequest = JSON.parse(JSON.stringify(state.repairRequest));
        const { name, value } = data;
        repairRequest[name] = value;
        setState(prev => ({
            ...prev,
            repairRequest: repairRequest,
        }));
    }

    function initialization() {
        let repairRequest = JSON.parse(JSON.stringify(state.repairRequest));
        let userInformation = Reducer.userInformation;

        repairRequest.UserName = userInformation.UserName;
        repairRequest.CustomerNumber = userInformation.CustomerNumber;
        repairRequest.ContactName = userInformation.UserName;
        repairRequest.Email = userInformation.UserId;
        repairRequest.AddressLineOne = userInformation.AddressLineOne;
        repairRequest.AddressLineTwo = userInformation.AddressLineTwo;
        repairRequest.AddressLineThree = userInformation.AddressLineThree;
        repairRequest.City = userInformation.City;
        if (Reducer.stateProvinceCodeMaster.some(x => x.Code == userInformation.State)) {
            repairRequest.State = userInformation.State;
        }
        repairRequest.Zip = userInformation.Zip;
        repairRequest.Country = "US";
        repairRequest.Telephone = userInformation.Telephone;
        repairRequest.Fax = userInformation.Fax;
        repairRequest.RepairDetails.push(new RepairRequestDetail());
        repairRequest.TrackingNumberDetails.push(new TrackingNumberDetail());

        setState(prev => ({ ...prev, repairRequest }));
    }

    function validate(request) {
        let errors = {};
        if (request.ContactName == null || request.ContactName == '') {
            errors.ContactName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAMENULL;
        } else if (Functions.byteLengthOf(request.ContactName) > 100) {
            errors.ContactName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAMELENGTH;
        }
        if (request.Email == null || request.Email == '') {
            errors.Email = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_EMAILNULL;
        } else if (Functions.byteLengthOf(request.Email) > 100) {
            errors.Email = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_EMAILLENGTH;
        }
        if (request.AddressLineOne == null || request.AddressLineOne == '') {
            errors.AddressLineOne = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSNULL;
        } else if (Functions.byteLengthOf(request.AddressLineOne) > 100) {
            errors.AddressLineOne = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (Functions.byteLengthOf(request.AddressLineTwo) > 100) {
            errors.AddressLineTwo = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (Functions.byteLengthOf(request.AddressLineThree) > 100) {
            errors.AddressLineThree = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (request.City == null || request.City == '') {
            errors.City = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYNULL;
        } else if (Functions.byteLengthOf(request.City) > 100) {
            errors.City = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYLENGTH;
        }
        if (request.State == null || request.State == '') {
            errors.State = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_STATENULL;
        }
        if (request.Zip == null || request.Zip == '') {
            errors.Zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPNULL;
        } else if (Functions.byteLengthOf(request.Zip) > 100) {
            errors.Zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPLENGTH;
        }
        if (request.Telephone == null || request.Telephone == '') {
            errors.Telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONENULL;
        } else if (Functions.byteLengthOf(request.Telephone) > 100) {
            errors.Telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONELENGTH;
        }
        if (Functions.byteLengthOf(request.Fax) > 100) {
            errors.Fax = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_FAXLENGTH;
        }
        if (request.UpsServiceCode == null || request.UpsServiceCode == '') {
            errors.UpsServiceCode = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_UPSSERVICECODENULL;
        }
        if (state.selectedShipToMasterId == null || state.selectedShipToMasterId == 0) {
            errors.ShipTo = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_SHIPTONULL;
        }
        request.TrackingNumberDetails.forEach((detail, idx) => {
            if (detail.Weight == null || detail.Weight == 0) {
                errors.Weight = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_WEIGHTNULL;
            } else if (detail.Weight <= 0 || detail.Weight > 150) {
                errors.Weight = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_WEIGHTNULL;
            } else if (!Number.isInteger(detail.Weight)) {
                // 少数だったら
                if (weightDigitNumberValidation(detail.Weight)) {
                    // 後ろから二文字目が「.」じゃなかったら少数が1桁じゃない
                    errors.Weight = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_WEIGHTDIGITNUMBERLIMIT;
                    errors.isDigitNumberLimit = idx;
                }
            }
        });
        if (request.RepairDetails.length == 0) {
            errors.RepairDetailIsNull = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_REPAIRDETAILSNULL;
        } else {
            request.RepairDetails.forEach(detail => {
                if (detail.Category == null || detail.Category == '') {
                    errors.Category = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CATEGORYNULL;
                }
                if (detail.Model == null || detail.Model == '') {
                    errors.Model = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_MODELNULL;
                }
                if (detail.SerialNumber != null && detail.SerialNumber != '') {
                    if (Functions.byteLengthOf(detail.SerialNumber) > 255) {
                        errors.SerialNumber = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_SERIALNUMBERLENGTH;
                    } else if (!detail.SerialNumber.match(/^[A-Za-z0-9]*$/)) {
                        errors.SerialNumber = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_SERIALNUMBER;
                    }
                }
                if (detail.ProblemDescription != null || detail.ProblemDescription != '') {
                    if (Functions.byteLengthOf(detail.ProblemDescription) > 255) {
                        errors.ProblemDescription = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_PROBLEMDESCRIPTIONLENGTH;
                    }
                }
                if (!(detail.Category == null || detail.Category == '') && detail.Category == Constant.REQUOEST_CATEGORY_RADIO) {
                    if (detail.SerialNumber == null || detail.SerialNumber == '') {
                        errors.RadioSerialNumber = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_SERIALNUMBERNULL;
                    } else if (Functions.byteLengthOf(detail.SerialNumber) != 8) {
                        errors.RadioSerialNumber = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_SERIALNUMBERINCORRECT;
                    }
                    if (detail.ProblemDescription == null || detail.ProblemDescription == '') {
                        errors.RadioProblemDescription = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_PROBLEMDESCRIPTIONNULL;
                    }
                }
            });
            if (!request.RepairDetails.some(x => x.Category == Constant.REQUOEST_CATEGORY_RADIO)) {
                // CategoryがRadioのモデルが一つも無かったら
                errors.Category = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CATEGORYLIMIT;
            }
        }

        return errors;
    }

    function weightDigitNumberValidation(weight) {
        // 後ろから二文字目が「.」じゃなかったら少数が1桁じゃない
        return weight.toString().slice(-2, -1) != '.';
    }

    function onConfirmClick() {
        let repairRequest = getSaveData();
        let errors = validate(repairRequest);
        setState(prev => ({
            ...prev,
            errors,
            repairRequest,
            isConfirmModalOpen: Object.keys(errors).length == 0,
        }));
        loadingErrorFlagInitialization();
    }

    function getSaveData() {
        let saveData = {
            Id: Number(state.repairRequest.Id),
            ContactName: state.repairRequest.ContactName.trim(),
            Email: state.repairRequest.Email.trim().replace(/\s+/g, ''),
            AddressLineOne: state.repairRequest.AddressLineOne.trim(),
            AddressLineTwo: state.repairRequest.AddressLineTwo.trim(),
            AddressLineThree: state.repairRequest.AddressLineThree.trim(),
            City: state.repairRequest.City.trim(),
            State: state.repairRequest.State,
            Zip: state.repairRequest.Zip.trim(),
            Country: state.repairRequest.Country.trim(),
            Telephone: state.repairRequest.Telephone.trim(),
            Fax: state.repairRequest.Fax.trim(),
            ShipToDescription: state.repairRequest.ShipToDescription,
            ShipToAddress: state.repairRequest.ShipToAddress,
            ShipToCity: state.repairRequest.ShipToCity,
            ShipToState: state.repairRequest.ShipToState,
            ShipToZip: state.repairRequest.ShipToZip,
            ShipToCountry: state.repairRequest.ShipToCountry,
            UpsServiceCode: state.repairRequest.UpsServiceCode,

            RepairDetails: state.repairRequest.RepairDetails.map(x => Object.assign(x)),
            TrackingNumberDetails: state.repairRequest.TrackingNumberDetails.map(x => Object.assign(x)),
        };

        saveData.TrackingNumberDetails.forEach(x => {
            x.Weight = Number(x.Weight);
        });
        saveData.RepairDetails.forEach(detail => {
            detail.SerialNumber = detail.SerialNumber.trim().toUpperCase();
        });

        return saveData;
    }

    function onCloseCompleteModal() {
        Actions.setTabStatus(Constant.REPAIRREQUEST_STAT_HISTORY);
    }

    function onFileDownloadClick(evn, data) {
        Actions.fileDownload(Reducer.csrftoken, data.labelType, Reducer.savedRequest.Id);
    }

    function onCalcButtonClick(evn, data) {
        let repairRequest = JSON.parse(JSON.stringify(state.repairRequest));
        let numberOfPackingBox = state.numberOfPackingBox;
        const { calcType, num } = data;
        if (calcType == 'addition') {
            numberOfPackingBox += num;
            if (numberOfPackingBox > Reducer.packageMaxCount) {
                numberOfPackingBox = Reducer.packageMaxCount;
            }
            while (repairRequest.TrackingNumberDetails.length < numberOfPackingBox) {
                repairRequest.TrackingNumberDetails.push(new TrackingNumberDetail());
            }
        } else if (calcType == 'subtraction') {
            numberOfPackingBox -= num;
            if (numberOfPackingBox <= 0) {
                numberOfPackingBox = 1;
            }
            repairRequest.TrackingNumberDetails = repairRequest.TrackingNumberDetails.slice(0, numberOfPackingBox);
        }
        setState(prev => ({ ...prev, repairRequest, numberOfPackingBox }));
    }

    function onWeightChange(evn, data) {
        let repairRequest = JSON.parse(JSON.stringify(state.repairRequest));
        let value = data.value.match(/[0-9\.]/g); // 0-9と"."だけを抜き出す
        let weight = '';
        if (data.value.match(/[\.]/g)?.length > 1) {
            // "."が2つ以上ある場合。2つめ以降の"."は削除
            let array = [];
            let dotFlag = false;
            value.forEach(x => {
                if (x != '.') {
                    array.push(x);
                } else {
                    if (!dotFlag) {
                        array.push(x);
                        dotFlag = true;
                    }
                }
            })
            weight = array.join('');
        } else {
            weight = value ? value.join('') : '';
        }
        repairRequest.TrackingNumberDetails[data.idx].Weight = weight;
        setState(prev => ({ ...prev, repairRequest }));
    }

    function confirmModalClose() {
        setState(prev => ({ ...prev, isConfirmModalOpen: false }));
    }

    function onShipToChange(evn, data) {
        let repairRequest = JSON.parse(JSON.stringify(state.repairRequest));
        let selectedShipToMasterId = data.value;
        let shipTo = Reducer.shipToMaster.find(x => x.ShipToId == selectedShipToMasterId);

        repairRequest.ShipToDescription = shipTo.ShipToDescription;
        repairRequest.ShipToAddress = shipTo.Address;
        repairRequest.ShipToCity = shipTo.City;
        repairRequest.ShipToState = shipTo.State;
        repairRequest.ShipToZip = shipTo.Zip;
        repairRequest.ShipToCountry = shipTo.Country;

        setState(prev => ({
            ...prev,
            repairRequest,
            selectedShipToMasterId,
        }));
    }

    function onCustomerAddressBookChange(evn, data) {
        if (state.selectedCustomerAddressBookId == data.value) { return; }

        let repairRequest = JSON.parse(JSON.stringify(state.repairRequest));
        const selectedCustomerAddressBookId = data.value;
        if (selectedCustomerAddressBookId === -1) {
            repairRequest.AddressLineOne = '';
            repairRequest.AddressLineTwo = '';
            repairRequest.AddressLineThree = '';
            repairRequest.City = '';
            repairRequest.State = '';
            repairRequest.Zip = '';
            repairRequest.Country = 'US';
            repairRequest.Telephone = '';
            repairRequest.Fax = '';
        } else {
            const address = selectedCustomerAddressBookId === 0
                ? Reducer.userInformation
                : Reducer.customerAddressBook.find(x => x.Id == selectedCustomerAddressBookId);

            repairRequest.AddressLineOne = address.AddressLineOne;
            repairRequest.AddressLineTwo = address.AddressLineTwo;
            repairRequest.AddressLineThree = address.AddressLineThree;
            repairRequest.City = address.City;
            repairRequest.State = address.State;
            repairRequest.Zip = address.Zip;
            repairRequest.Country = address.Country;
            repairRequest.Telephone = address.Telephone;
            repairRequest.Fax = address.Fax;
        }

        setState(prev => ({
            ...prev,
            repairRequest,
            selectedCustomerAddressBookId,
        }));
    }

    function onSaveAddressBookChange(evn, data) {
        setState(prev => ({ ...prev, isSaveAddressBook: data.checked }));
    }

    let defaultBtnColor = 'blue';
    let styleCell = { padding: '0.3em 0.5em' };
    let styleClear = { clear: 'both' };

    let customerAddressBookOptions = [{
        key: 0, value: 0,
        text: `${Reducer.userInformation.AddressLineOne} ${Reducer.userInformation.City}, ${Reducer.userInformation.State}`,
    }].concat(Reducer.customerAddressBook.map((x) => ({
        key: x.Id, value: x.Id, text: `${x.AddressLineOne} ${x.City}, ${x.State}`,
    })), [{ key: -1, value: -1, text: 'Other (please specify)' }]);
    let shipToOptions = Reducer.shipToMaster.map(shipTo => {
        return { text: shipTo.ShipToDescription, value: shipTo.ShipToId, key: shipTo.ShipToId };
    });
    let categoryOptions = Reducer.categoryMaster.map(x => {
        return { key: x.value, value: x.value, text: x.value };
    });
    let modelOptions = Reducer.tempModelMaster.map((x, idx) => {
        return { key: idx, value: x.Name, text: x.Name };
    });
    let stateOptions = Reducer.stateProvinceCodeMaster.map(x => {
        return { key: x.Code, value: x.Code, text: `${x.Code} - ${x.State}` };
    });

    let repairRequest = state.repairRequest;
    let shippingOptions = [];
    Reducer.upsServiceCodes.forEach(x => {
        if (x.value == '02' && !Reducer.userInformation.SecondDayAirEnable) {
            return;
        }
        shippingOptions.push({ key: x.value, value: x.value, text: x.text });
    });

    let errorMessage = null;
    if (Object.keys(state.errors).length) {
        errorMessage = (
            <Message negative style={styleClear}>
                {Object.keys(state.errors).map(k => {
                    if (k == 'isDigitNumberLimit') { return ''; }
                    return <>{state.errors[k]}<br /></>;
                })}
            </Message>
        );
    } else if (Reducer.logoutingError || Reducer.manualDownloadingError || Reducer.customerAddressBookFetchingError) {
        errorMessage = <ErrorMessage content={Reducer.errorMessage} active page />;
    }

    let completeModalErrorMessage = null;
    if (Reducer.fileDownloadingError) {
        completeModalErrorMessage = <ErrorMessage content={Reducer.errorMessage} active />;
    }

    let repairDetails = repairRequest.RepairDetails.map((x, idx) => {
        // Serial#とProblemDescriptionが必須かどうか
        let required = x.Category == Constant.REQUOEST_CATEGORY_RADIO;

        let serialNumberErrorFlag = false;
        if (state.errors.SerialNumber) {
            if (Functions.byteLengthOf(x.SerialNumber) > 255) {
                serialNumberErrorFlag = true;
            }
        }
        if (state.errors.RadioSerialNumber && required) {
            if (x.SerialNumber == "" || x.SerialNumber == null || Functions.byteLengthOf(x.SerialNumber) != 8) {
                serialNumberErrorFlag = true;
            }
        }

        let descriptionErrorFlag = false;
        if (state.errors.ProblemDescription) {
            if (Functions.byteLengthOf(x.ProblemDescription) > 255) {
                descriptionErrorFlag = true;
            }
        }
        if (state.errors.RadioProblemDescription && required) {
            if (x.ProblemDescription == "" || x.ProblemDescription == null) {
                descriptionErrorFlag = true;
            }
        }
        return (
            <Table.Row>
                <Table.Cell>
                    <Dropdown
                        name='Category'
                        placeholder='Select category'
                        search selection
                        options={categoryOptions}
                        idx={idx}
                        onChange={onDetailTextChange}
                        value={x.Category}
                        error={state.errors.Category && (x.Category == "" || x.Category == null)}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Dropdown
                        name='Model'
                        placeholder='Select model'
                        search selection
                        options={modelOptions}
                        idx={idx}
                        onChange={onDetailTextChange}
                        value={x.Model}
                        error={state.errors.Model && (x.Model == "" || x.Model == null)}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Input
                        name='SerialNumber'
                        value={x.SerialNumber}
                        idx={idx}
                        onChange={onDetailTextChange}
                        error={serialNumberErrorFlag}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Form>
                        <TextArea
                            name='ProblemDescription'
                            style={{ width: '100%' }} rows={2}
                            className={descriptionErrorFlag ? 'Error-Zone' : null}
                            value={x.ProblemDescription}
                            idx={idx}
                            onChange={onDetailTextChange}
                        />
                    </Form>
                </Table.Cell>
                <Table.Cell>
                    <Button color='red' size='mini' icon='delete' idx={idx} onClick={onModelDeleteClick} />
                </Table.Cell>
            </Table.Row>
        );
    });

    let requiredPopup = <Popup content={Messages.REPAIRREQUEST_MSG_WARNINGMESSAGE_REQUIRED} trigger={<Button icon='exclamation' circular size='mini' style={{ marginLeft: 10 }} />} />;

    let completeModal = Reducer.isCompleteModalOpen ?
        <Modal open>
            <Modal.Header>
                <Header><Icon name='check' />Submit completed!</Header>
            </Modal.Header>
            <Modal.Content>
                {completeModalErrorMessage}
                <Segment basic>
                    <Grid>
                        <Grid.Column width={1} />
                        <Grid.Column width={6}>
                            <dl style={{ marginTop: 0 }}>
                                <dt>No.</dt>
                                <dd>{Reducer.savedRequest.Id}</dd>
                                <dt>Weight & Ship tracking #</dt>
                                <dd>
                                    <ol class='weight-shipTrackingNumber'>
                                        {Reducer.savedRequest.TrackingNumberDetails.map(x => {
                                            return <li>{x.Weight} lbs ({x.TrackingNumber})</li>
                                        })}
                                    </ol>
                                </dd>
                            </dl>
                        </Grid.Column>
                        <Grid.Column width={9}>
                            <Header>
                                Please download shipping label and packing slip
                                <Header.Subheader>(You can also download these from the History screen)</Header.Subheader>
                            </Header>
                            <Button primary icon='file image outline' content='Shipping label download' labelType={Constant.FILEDOWNLOAD_SHIPPINGLABEL} onClick={onFileDownloadClick} />
                            <Button primary icon='file pdf outline' content='Packing slip download' labelType={Constant.FILEDOWNLOAD_PACKINGSLIP} onClick={onFileDownloadClick} />
                            <p className='red'>{Messages.REPAIRREQUEST_MSG_FILEDOWNLOAD_PACKINGSLIP}</p>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onCloseCompleteModal}>Close</Button>
            </Modal.Actions>
        </Modal> : null;

    return (
        <>
            <Header as='h2' icon='box' content='New Shipping' />
            {errorMessage}
            <Segment>
                <Header>
                    <Icon name='building' />
                    Customer information
                </Header>
                <Divider />
                <Form size='small'>
                    <Form.Group>
                        <Form.Input required label='Contact name' name='ContactName' width={6} onChange={onTextChange} value={repairRequest.ContactName} error={state.errors.ContactName ? true : false} />
                        <Form.Input required label='Email' name='Email' width={6} onChange={onTextChange} value={repairRequest.Email} error={state.errors.Email ? true : false} />
                    </Form.Group>
                    <Form.Dropdown options={customerAddressBookOptions} selection search label='Select address' width={8}
                        value={state.selectedCustomerAddressBookId} onChange={onCustomerAddressBookChange} />
                    <Form.Input required label='Address' name='AddressLineOne' width={8} onChange={onTextChange} value={repairRequest.AddressLineOne} error={state.errors.AddressLineOne ? true : false} className='margin-btm'>
                        <input />
                    </Form.Input>
                    <Form.Input name='AddressLineTwo' width={8} onChange={onTextChange} value={repairRequest.AddressLineTwo} error={state.errors.AddressLineTwo ? true : false} className='margin-btm'>
                        <input />
                    </Form.Input>
                    <Form.Input name='AddressLineThree' width={8} onChange={onTextChange} value={repairRequest.AddressLineThree} error={state.errors.AddressLineThree ? true : false}>
                        <input />
                    </Form.Input>
                    <Form.Group>
                        <Form.Input required label='City' name='City' width={4} onChange={onTextChange} value={repairRequest.City} error={state.errors.City ? true : false} />
                        <Form.Dropdown required search selection label='State' name='State' width={4} onChange={onTextChange} value={repairRequest.State} options={stateOptions} placeholder='Select state' error={!!state.errors.State} />
                        <Form.Input required label='Zip/Postal code' name='Zip' width={4} onChange={onTextChange} value={repairRequest.Zip} error={state.errors.Zip ? true : false} />
                        <Form.Input label='Country' width={4} value={repairRequest.Country}>
                            <input disabled style={{ backgroundColor: 'rgba(100,100,100,0.2)' }} className='disabled-input' />
                        </Form.Input>
                    </Form.Group>
                    <Form.Group>
                        <Form.Input required label='Telephone' name='Telephone' width={4} onChange={onTextChange} value={repairRequest.Telephone} error={state.errors.Telephone ? true : false} />
                        <Form.Input label='Fax' name='Fax' width={4} onChange={onTextChange} value={repairRequest.Fax} error={state.errors.Fax ? true : false} />
                    </Form.Group>
                    <Form.Checkbox label='Save this address'
                        checked={state.isSaveAddressBook} onChange={onSaveAddressBookChange} />
                </Form>
            </Segment>
            <Segment>
                <Header>
                    <Icon name='shipping' />
                    Shipping information
                </Header>
                <Divider />
                <Form>
                    <Form.Dropdown search selection required
                        label='Shipping option' name='UpsServiceCode'
                        options={shippingOptions}
                        value={repairRequest.UpsServiceCode}
                        onChange={onTextChange}
                        placeholder='Select shipping option'
                        width={4}
                        error={!!state.errors.UpsServiceCode}
                    />
                    <Form.Dropdown search selection required
                        label='Ship to'
                        options={shipToOptions}
                        value={state.selectedShipToMasterId}
                        onChange={onShipToChange}
                        placeholder='Select ship to'
                        width={8}
                        error={!!state.errors.ShipTo}
                    />
                    <Form.Field>
                        <label>Address</label>
                        <p style={{ marginLeft: 14 }}>
                            {repairRequest?.ShipToAddress}<br />
                            {repairRequest?.ShipToCity
                                ? `${repairRequest.ShipToCity}, ${repairRequest.ShipToState} ${repairRequest.ShipToZip}`
                                : ''}<br />
                            {repairRequest?.ShipToCountry}
                        </p>
                    </Form.Field>
                    <Form.Field>
                        <label>Number of packing boxes<span style={{ fontWeight: 'normal' }}> (Min: 1, Max: {Reducer.packageMaxCount})</span></label>
                        <Form.Group>
                            <ButtonForNumberOfPackingBox calcType='subtraction' num={5} onClick={onCalcButtonClick} />
                            <ButtonForNumberOfPackingBox calcType='subtraction' num={1} onClick={onCalcButtonClick} />
                            <Form.Input width={1} value={state.numberOfPackingBox}>
                                <input disabled style={{ backgroundColor: 'rgba(100,100,100,0.2)' }} className='disabled-input' />
                            </Form.Input>
                            <ButtonForNumberOfPackingBox calcType='addition' num={1} onClick={onCalcButtonClick} />
                            <ButtonForNumberOfPackingBox calcType='addition' num={5} onClick={onCalcButtonClick} />
                        </Form.Group>
                    </Form.Field>
                    <Form.Field>
                        <label>
                            Weight (lbs)<span class='required' />
                            <span style={{ fontWeight: 'normal' }}> {Messages.REPAIRREQUEST_MSG_RQUEST_WEIGHT}</span>
                        </label>
                        <Grid columns={6}>
                            {state.repairRequest.TrackingNumberDetails.map((x, idx) => {
                                return (
                                    <Grid.Column>
                                        <Form.Input error={state.errors.Weight && (x.Weight == null || x.Weight <= 0 || x.Weight > 150 || (state.errors.isDigitNumberLimit == idx))}>
                                            <Input fluid label={`Box ${idx + 1}`} value={x.Weight} idx={idx} onChange={onWeightChange} />
                                        </Form.Input>
                                    </Grid.Column>
                                );
                            })}
                        </Grid>
                    </Form.Field>
                </Form>
            </Segment>
            <Segment>
                <Header>
                    <Icon name='tag' />
                    Repair details
                </Header>
                <Divider />
                <Table celled striped size='small' compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={styleCell} width={2}>Category<span style={{ color: 'red' }}> *</span></Table.HeaderCell>
                            <Table.HeaderCell style={styleCell} width={2}>Model<span style={{ color: 'red' }}> *</span></Table.HeaderCell>
                            <Table.HeaderCell style={styleCell} width={2}>Serial #{requiredPopup}</Table.HeaderCell>
                            <Table.HeaderCell width={10}>Problem description{requiredPopup}</Table.HeaderCell>
                            <Table.HeaderCell width={0}></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {repairDetails}
                    </Table.Body>
                </Table>
                <Button primary onClick={onAddLineClick} color={defaultBtnColor}>
                    <Icon name="plus" />Add line
                </Button>
            </Segment>
            <Segment clearing vertical>
                <Button icon='check' floated='right' color={defaultBtnColor} onClick={onConfirmClick}>
                    <Icon name='check' />Confirm
                </Button>
            </Segment>
            {state.isConfirmModalOpen ? <RequestConfirm repairRequest={state.repairRequest} confirmModalClose={confirmModalClose} isSaveAddressBook={state.isSaveAddressBook} selectedShipToMasterId={state.selectedShipToMasterId} /> : ''}
            {completeModal}
            <Dimmer active={Reducer.fileDownloading || Reducer.customerAddressBookFetching} page>
                <Loader content="Loading..." size="huge" />
            </Dimmer>
        </>
    );
}

function ButtonForNumberOfPackingBox({ calcType, num, onClick }) {
    let content = '';
    if (calcType == 'addition') {
        content = '+' + num.toString();
    } else if (calcType == 'subtraction') {
        content = '-' + num.toString();
    }

    return <Form.Button circular content={content} calcType={calcType} num={num} onClick={onClick} />;
}
