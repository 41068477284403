"use strict";

const regex = new RegExp(/[A-Z0-9\-]+/);

export default class {
    constructor(dataObject = null) {
        this.Id = 0;
        this.ModelPattern = '';

        this.PatternDetails = [];

        // Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.ModelPattern = dataObject.modelPattern;

                this.PatternDetails = this.ModelPattern.split('')
                    .map((char, index) => ({ Digit: index, Value: char }))
                    .filter(x => regex.test(x.Value));
            }
            catch (e) {
                throw "Data import error @ ModelRuleMaster data.";
            }
        }
    }

    isModelMatch(model) {
        // forward-match(前方一致)
        const forward = this.PatternDetails.every(pattern => {
            return model.charAt(pattern.Digit) === pattern.Value;
        });

        // exact-match(完全一致)
        const exact = (this.ModelPattern.length === model.length) ? forward : false;

        return { forward, exact };
    }
}