import { useEffect, useRef, useState } from 'react';
import { Button, Dimmer, Header, Icon, Loader, Segment, Tab } from 'semantic-ui-react';

import { useAppContext } from '../../Contexts/AppContext';
import * as Messages from '../../Resources/Messages';
import AddressBookTab from './AddressBookTab';
import EndUserTab from './EndUserTab';
import UsersTab from './UsersTab';

const initialState = {
    tabActiveIndex: 0,
    hasChanged: false,
};

export default function UserSetting() {
    const { Reducer, Actions } = useAppContext();
    const [state, setState] = useState(initialState);
    const prevReducer_manualDownloading = useRef(Reducer.manualDownloading);

    useEffect(() => {
        Actions.userMasterFetch(Reducer.csrftoken);

        return () => {
            loadingErrorFlagInitialization();
        };
    }, []);

    useEffect(() => {
        // The function is execute when the user clicks the "Manual Download" button
        if (!prevReducer_manualDownloading.current && Reducer.manualDownloading) {
            Actions.masterDataFetchingError(false);
        }
        prevReducer_manualDownloading.current = Reducer.manualDownloading;
    }, [Reducer.manualDownloading]);

    function loadingErrorFlagInitialization() {
        Actions.masterDataFetchingError(false);
        Actions.userMasterFetchError(false);
        Actions.logoutFetchingError(false);
        Actions.manualDownloadingError(false);
    }

    function onTabIndexChange(evn, data) {
        if (!state.hasChanged || window.confirm(Messages.REPAIRREQUEST_MSG_WARNINGMESSAGE_ADDUSER)) {
            setState(prev => ({
                ...prev,
                tabActiveIndex: data.activeIndex,
                hasChanged: false,
            }));
            // loadingErrorFlagInitialization();
        }
    }

    function setHasChanged(bool) {
        setState(prev => ({ ...prev, hasChanged: bool }));
    }

    let marginRight = { marginRight: 20, display: 'inline-block', verticalAlign: 'top' };

    let userInformation = Reducer.userInformation;

    const panes = [
        { menuItem: 'Users', render: () => <UsersTab setHasChanged={setHasChanged} /> },
    ];
    if (!Reducer.userInformation.RepairCenterFlag) {
        panes.push({ menuItem: 'Address book', render: () => <AddressBookTab /> });
        panes.push({ menuItem: 'End-user', render: () => <EndUserTab /> });
    }

    return (
        <>
            <Header as='h2'>
                <Icon name='setting' />
                <Header.Content>
                    User Setting
                </Header.Content>
            </Header>
            <Segment>
                <Header icon='building' content='Customer information' dividing />
                <dl>
                    <div style={marginRight}>
                        <dt>Customer #</dt>
                        <dd>{userInformation.CustomerNumber}</dd>
                    </div>
                    <div style={marginRight}>
                        <dt>Customer name</dt>
                        <dd>{userInformation.CustomerName}</dd>
                    </div>
                    {Reducer.userInformation.RepairCenterFlag ? null
                        : <>
                            <div style={marginRight}>
                                <dt>Address</dt>
                                <dd>{userInformation.AddressLineOne ? userInformation.AddressLineOne : '-'}</dd>
                                <dd>{userInformation.AddressLineTwo ? userInformation.AddressLineTwo : '-'}</dd>
                                <dd>{userInformation.AddressLineThree ? userInformation.AddressLineThree : '-'}</dd>
                                <dd>{userInformation.City}, {userInformation.State} {userInformation.Zip}</dd>
                                <dd>{userInformation.Country}</dd>
                            </div>
                            <div style={marginRight}>
                                <dt>Telephone</dt>
                                <dd>{userInformation.Telephone}</dd>
                            </div>
                            <div style={marginRight}>
                                <dt>Fax</dt>
                                <dd>{userInformation.Fax}</dd>
                            </div>
                        </>}
                </dl>
            </Segment>
            <Tab panes={panes} renderActiveOnly activeIndex={state.tabActiveIndex} onTabChange={onTabIndexChange} />

            <Dimmer active={Reducer.userMasterFetching || Reducer.userSettingUpdating} page>
                <Loader content="Loading..." size="huge"></Loader>
            </Dimmer>
            <Dimmer active={Reducer.isChangeOwnUserId} page>
                <div className='flex-column'>
                    <Header icon inverted>
                        <Icon name='check' />
                        {Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_USERSETTING}
                        <Header.Subheader>{Messages.REPAIRREQUEST_MSG_USERID_NOTMATCH}</Header.Subheader>
                    </Header>
                    <div style={{ display: 'flex' }}>
                        <Button content='Back to Login' icon='sign-in' basic inverted color='blue' size='large' style={{ marginLeft: 'auto' }} onClick={() => window.location.reload()} />
                    </div>
                </div>
            </Dimmer>
        </>
    );
}
