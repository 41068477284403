"use strict";

export default class {
    constructor(dataObject = null) {
        this.Id = 0;
        this.ContactName = '';
        this.Email = '';
        this.AddressLineOne = '';
        this.AddressLineTwo = '';
        this.AddressLineThree = '';
        this.City = '';
        this.State = '';
        this.Zip = '';
        this.Country = '';
        this.Telephone = '';
        this.Fax = '';
        this.RequestedDate = '';
        this.ShipToDescription = '';
        this.ShipToAddress = '';
        this.ShipToCity = '';
        this.ShipToState = '';
        this.ShipToZip = '';
        this.ShipToCountry = '';
        this.TransportationCharges = 0;
        this.ServiceOptionsCharges = 0;
        this.UpsServiceCode = '';

        this.RepairDetails = [];
        this.TrackingNumberDetails = [];

        //Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.ContactName = dataObject.contactName;
                this.Email = dataObject.email;
                this.AddressLineOne = dataObject.addressLineOne;
                this.AddressLineTwo = dataObject.addressLineTwo;
                this.AddressLineThree = dataObject.addressLineThree;
                this.City = dataObject.city;
                this.State = dataObject.state;
                this.Zip = dataObject.zip;
                this.Country = dataObject.country;
                this.Telephone = dataObject.telephone;
                this.Fax = dataObject.fax;
                this.RequestedDate = dataObject.requestedDate;
                this.ShipToDescription = dataObject.shipToDescription;
                this.ShipToAddress = dataObject.shipToAddress;
                this.ShipToCity = dataObject.shipToCity;
                this.ShipToState = dataObject.shipToState;
                this.ShipToZip = dataObject.shipToZip;
                this.ShipToCountry = dataObject.shipToCountry;
                this.TransportationCharges = dataObject.transportationCharges;
                this.ServiceOptionsCharges = dataObject.serviceOptionsCharges;
                this.UpsServiceCode = dataObject.upsServiceCode;
            }
            catch (e) {
                throw 'Data import error @ RepairRequestHeaders data.';
            }
        }
    }
}
