"use strict";
import moment from 'moment';
const dateFormat = 'YYYY-MM-DD';

export default class {
    constructor(dataObject = null) {
        this.Id = 0;
        this.PlanName = '';
        this.Description = '';
        this.EffectiveDate = null;
        this.ExpiryDate = null;

        this.Prices = [];

        // Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.PlanName = dataObject.planName;
                this.Description = dataObject.description;
                this.EffectiveDate = dataObject.effectiveDate ? new Date(dataObject.effectiveDate) : null;
                this.ExpiryDate = dataObject.expiryDate ? new Date(dataObject.expiryDate) : null;
            }
            catch (e) {
                throw "Data import error @ RepairServicePlanMaster data.";
            }
        }
    }

    getPrice(date) {
        const compareValue = moment(date).format(dateFormat);
        const priceData = this.Prices.find(x => {
            const effectiveDate = moment(x.EffectiveDate).format(dateFormat);
            if (compareValue < effectiveDate) { return false; }

            if (x.ExpiryDate == null) { return true; }
            const expiryDate = moment(x.ExpiryDate).format(dateFormat);
            return compareValue <= expiryDate;
        });
        return priceData ? priceData.Price : 0;
    };
}
