import { useEffect } from 'react';
import { Dimmer, Header, Image, Loader, Segment } from 'semantic-ui-react';

import './App.css';
import Disclaimer from './Components/Disclaimer';
import ForgotPassword from './Components/Login/ForgotPassword';
import Login from './Components/Login/Login';
import ResetPassword from './Components/Login/ResetPassword';
import MenuBar from './Components/MenuBar';
import * as Constant from './Constants/Constant';
import { useAppContext } from './Contexts/AppContext';
import logo from './logo.jpg';
import Tab from './Tab';

export default function Root() {
    const { Reducer, Actions } = useAppContext();

    useEffect(() => {
        if (!Reducer.tabStatus != Constant.REPAIRREQUEST_STAT_RESETPASSWORD) {
            Actions.sessionCheck();
        } else {
            Actions.sessionChecking(false);
        }
    }, []);

    let component, headerMenu;

    if (Reducer.sessionChecking) {
        component = <Dimmer active page><Loader content='Loading...' size='huge' /></Dimmer>;
    } else {
        switch (Reducer.tabStatus) {
            case Constant.REPAIRREQUEST_STAT_LOGIN:
                component = <Login />;
                break;
            case Constant.REPAIRREQUEST_STAT_FORGOTPASSWORD:
                component = <ForgotPassword />;
                break;
            case Constant.REPAIRREQUEST_STAT_RESETPASSWORD:
                component = <ResetPassword />;
                break;
            default:
                component = <Tab />;
                headerMenu = <MenuBar />;
                break;
        }
    }

    let header =
        <Segment inverted>
            <Header as='h1'>Extended Services Portal</Header>
            {headerMenu}
        </Segment>;
    let footer =
        <Segment as='footer' inverted color='black'>
            <Header as='h5' textAlign='center'>
                © 2024 JVCKENWOOD USA Corporation. All rights reserved.
            </Header>
        </Segment>;

    return (
        <>
            <Segment style={{ minHeight: '100vh' }} basic>
                <Image src={logo} size='small' />
                {header}
                {component}
                {footer}
            </Segment>

            <Disclaimer />
            <Dimmer active={Reducer.logouting || Reducer.manualDownloading} page>
                <Loader content='Loading...' size='huge' />
            </Dimmer>
        </>
    );
}
