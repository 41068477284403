"use strict";

export default class {
    constructor(dataObject = null) {
        this.Id = 0;
        this.CompanyId = '';
        this.CustomerNumber = '';
        this.InvoiceNumber = '';
        this.InvoiceDate = null;
        this.Model = '';
        this.SerialNumber = '';

        // Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.CompanyId = dataObject.companyId;
                this.CustomerNumber = dataObject.customerNumber;
                this.InvoiceNumber = dataObject.invoiceNumber;
                this.InvoiceDate = new Date(dataObject.invoiceDate);
                this.Model = dataObject.model;
                this.SerialNumber = dataObject.serialNumber;
            }
            catch (e) {
                throw "Data import error @ InvoiceFromJbase data.";
            }
        }
    }
}