"use strict";

const MATCHPATTERN_FORWARD = 0;
const MATCHPATTERN_EXACT = 1;

export default class {
    constructor(dataObject = null) {
        this.Id = 0;
        this.RepairServicePlanMasterId = 0;
        this.ModelRuleMasterId = 0;
        this.MatchPattern = 0;

        // Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.RepairServicePlanMasterId = dataObject.repairServicePlanMasterId;
                this.ModelRuleMasterId = dataObject.modelRuleMasterId;
                this.MatchPattern = dataObject.matchPattern;
            }
            catch (e) {
                throw "Data import error @ ModelRulesForRepairServicePlanCoverage data.";
            }
        }
    }

    isMatchPatternForwardMatching() {
        return this.MatchPattern == MATCHPATTERN_FORWARD;
    }

    isMatchPatternExactMatching() {
        return this.MatchPattern == MATCHPATTERN_EXACT;
    }
}