"use strict";

export default class {
    constructor(dataObject = null) {
        this.Digit = 0;
        this.Value = '';

        //Import data if set
        if (dataObject) {
            try {
                this.Digit = dataObject.digit;
                this.Value = dataObject.value;
            }
            catch (e) {
                throw 'Data import error @ WarrantyRuleMaster data.';
            }
        }
    }
}
