import { useState } from 'react';
import { Button, Container, Dimmer, Form, Header, Icon, List, Loader, Message, Popup, Segment } from 'semantic-ui-react';

import AppConfig from '../../AppConfig';
import * as Constant from '../../Constants/Constant';
import { useAppContext } from '../../Contexts/AppContext';
import * as Messages from '../../Resources/Messages';
import ErrorMessage from '../ErrorMessage';

const initialState = {
    newPassword: '',
    confirmNewPassword: '',
    showPassword: false,

    validationErrors: {},
    loading: false,
    loadingError: '',
    loadingSuccess: false,
};
// Passwordのバリデーション用
// 入力可能文字：a-zA-Z0-9
// 入力可能記号：!#$%&'"()=-~^|@`[]{}:;/?_<>,.*+
const prohibitedCharacters = '!#$%&\'"()=\-~^|@`[\]{}:;\/?_<>,.\*\+';
// 入力不可な文字を洗い出すための正規表現
const prohibitedCharactersRegExp = /[^a-zA-Z0-9!#$%&\'"()=\-~^|@`[\]{}:;\/?_<>,.\*\+]/g;

export default function ResetPassword() {
    const { Reducer, Actions } = useAppContext();
    const [state, setState] = useState(initialState);

    function onFormChange(evn, data) {
        setState(prev => ({ ...prev, [data.name]: data.value }));
    }

    function onSubmitClick() {
        let validationErrors = {};
        // 入力禁止の文字を洗い出す
        let prohibitedValues = state.newPassword.match(prohibitedCharactersRegExp) ?? [];
        if (!state.newPassword) {
            validationErrors.newPassword = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_NEWPASSWORD;
        } else if (state.newPassword.length < 8 || 16 < state.newPassword.length) {
            validationErrors.newPassword = Messages.REPAIRREQUEST_MSG_PASSWORDVALIDATION;
        } else if (prohibitedValues.length != 0) {
            // 入力禁止文字を使用している場合。prohibitedValuesから重複した値を削除。
            let badValues = Array.from(new Set(prohibitedValues)).map(x => `"${x}"`);
            validationErrors.newPassword = Messages.REPAIRREQUEST_MSG_PASSWORD_CONTAIN_UNAVAILABLE_CHARACTER + `(${badValues.join(', ')}).`;
        }
        if (!state.confirmNewPassword) {
            validationErrors.confirmNewPassword = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_CONFIRMPASSWORD;
        } else if (state.newPassword != state.confirmNewPassword) {
            validationErrors.confirmNewPassword = Messages.REPAIRREQUEST_MSG_RESETPASSWORD_NOTMATCH;
        }
        setState(prev => ({ ...prev, validationErrors, loadingError: '' }));
        if (!Object.keys(validationErrors).length) {
            resetPassword();
        }
    }

    function resetPassword() {
        setState(prev => ({ ...prev, loading: true, loadingSuccess: false }));
        fetch(AppConfig.ApiUrlLogin + '/resetPassword', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ newPassword: state.newPassword, recepid: Reducer.recepid })
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                setState(prev => ({ ...prev, loading: false, loadingSuccess: true }));
            })
            .catch(e => {
                setState(prev => ({ ...prev, loading: false, loadingError: e.message }));
            });
    }

    function toLoginScreen() {
        let url = window.location.href;
        let newUrl = url.substring(0, url.indexOf("/?"));
        window.history.pushState("", "", newUrl);
        Actions.setTabStatus(Constant.REPAIRREQUEST_STAT_LOGIN);
    }

    function onShowPasswordClick() {
        setState(prev => ({ ...prev, showPassword: !state.showPassword }));
    }

    let errorMessage = null;
    if (Object.keys(state.validationErrors).length) {
        let errors = Object.keys(state.validationErrors).map(x => <>{state.validationErrors[x]}<br /></>);
        errorMessage = <Message error>{errors}</Message>;
    } else if (state.loadingError) {
        errorMessage = <ErrorMessage content={state.loadingError} active page />;
    }

    let inputType = state.showPassword ? 'text' : 'password';

    return (
        <Container style={{ minHeight: '50vh' }}>
            <Segment style={{ maxWidth: 562.5, margin: 'auto' }}>
                {errorMessage}
                <Form size='big'>
                    <Popup
                        trigger={<Form.Input fluid label='New password' icon='lock' iconPosition='left' type={inputType} name='newPassword' onChange={onFormChange} value={state.newPassword} error={!!state.validationErrors.newPassword} />}
                        position='left center'
                        on='focus'
                    >
                        <Popup.Header content={Messages.REPAIRREQUEST_MSG_PASSWORDREQUIREMENTS_TITLE} />
                        <Popup.Content>
                            <List bulleted>
                                <List.Item content={Messages.REPAIRREQUEST_MSG_PASSWORDREQUIREMENTS_CONTENT_LENGTH} />
                                <List.Item content={Messages.REPAIRREQUEST_MSG_PASSWORDREQUIREMENTS_CONTENT_SPECIALCHARACTERS + prohibitedCharacters} />
                                <List.Item content={Messages.REPAIRREQUEST_MSG_PASSWORDREQUIREMENTS_CONTENT_SPACES} />
                            </List>
                        </Popup.Content>
                    </Popup>

                    <Form.Input fluid label='Confirm new password' icon='lock' iconPosition='left' type={inputType} name='confirmNewPassword' onChange={onFormChange} value={state.confirmNewPassword} error={!!state.validationErrors.confirmNewPassword} />
                    <Form.Checkbox label='Show password' onClick={onShowPasswordClick} />
                    <Button fluid color='blue' content='Submit' size='huge' onClick={onSubmitClick} />
                </Form>
                <Segment basic size='big'>
                    <a className='link_style' onClick={toLoginScreen}>Back to login</a>
                </Segment>
            </Segment>
            <Dimmer active={state.loading} page>
                <Loader content='Loading...' size='huge' />
            </Dimmer>
            <Dimmer active={state.loadingSuccess} page>
                <div>
                    <Header as='h2' icon inverted>
                        <Icon name='check' />
                        {Messages.REPAIRREQUEST_MSG_SUCCESSHEADER_RESETPASSWORD}
                        <Header.Subheader>{Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_RESETPASSWORD}</Header.Subheader>
                    </Header>
                </div>
                <div style={{ display: 'flex' }}>
                    <Button basic inverted color='green' size='large' style={{ marginLeft: 'auto' }} onClick={toLoginScreen}>
                        <Icon name='check' />
                        OK
                    </Button>
                </div>
            </Dimmer>
        </Container>
    );
}
