import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Button, Dimmer, Divider, Form, Header, Loader, Message, Modal, Segment, Table } from 'semantic-ui-react';

import * as Constants from '../../../Constants/Constant';
import { useAppContext } from '../../../Contexts/AppContext';
import Functions from '../../../Functions';
import ProtectionPlanDetail from '../../../Models/ViewModels/ProtectoinPlanDetail';
import * as Messages from '../../../Resources/Messages';
import ErrorMessage from '../../ErrorMessage';
import ProtectionPlanConfirm from './ProtectionPlanConfirm';

const today = new Date();
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const initialState = {
    selectedEndUserId: -1,
    endUserName: '',
    contactName: '',
    email: '',
    addressLineOne: '',
    addressLineTwo: '',
    addressLineThree: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
    telephone: '',
    fax: '',
    note: '',
    isSaveEndUser: true,

    po: '',
    purchaseDate: '',

    searchInvoiceNumber: '',
    invoices: [],

    planModalOpenFlag: false,
    confirmModalOpenFlag: false,
    modalOpenFlag: false,
    validationErrors: {},
};

export default function ProtectionPlanEdit(props) {
    const { Reducer, Actions } = useAppContext();
    const [state, setState] = useState(initialState);
    const prevReducer_invoiceFetching = useRef(Reducer.invoiceFetching);
    const prevReducer_protectionPlanSaving = useRef(Reducer.protectionPlanSaving);
    const prevState_purchaseDate = useRef(state.purchaseDate);

    useEffect(() => {
        Actions.fetchCustomerEndUsers(Reducer.csrftoken);

        return () => {
            Actions.setTempInvoiceMaster([]);
            Actions.invoiceFetchingError(false);
            Actions.customerEndUsersFetchingError(false);
        };
    }, []);

    // Invoiceデータの取得完了後
    useEffect(() => {
        if (prevReducer_invoiceFetching.current && !Reducer.invoiceFetching && !Reducer.invoiceFetchingError) {
            // 取得したInvoiceを一覧に追加する
            const invoices = state.invoices.filter(x => x.InvoiceNumber != state.searchInvoiceNumber);
            Reducer.tempInvoiceMaster
                .forEach(invoice => {
                    // ProtectionPlanMasterから一致するプランを自動選択
                    const repairServicePlans = getRepairServicePlans(invoice.Model);

                    const newDetail = new ProtectionPlanDetail();
                    newDetail.Id = 'I-' + invoice.Id;
                    newDetail.CompanyId = invoice.CompanyId;
                    newDetail.InvoiceNumber = invoice.InvoiceNumber;
                    newDetail.Model = invoice.Model;
                    newDetail.SerialNumber = invoice.SerialNumber;
                    newDetail.InvoiceDate = invoice.InvoiceDate;
                    newDetail.PlanId = repairServicePlans.length ? repairServicePlans[0].Id : null;

                    invoices.push(newDetail);
                });
            invoices.sort((a, b) => {
                if (a.InvoiceNumber != b.InvoiceNumber) {
                    return a.InvoiceNumber < b.InvoiceNumber ? -1 : 1;
                }
                if (a.Model != b.Model) {
                    return a.Model < b.Model ? -1 : 1;
                }
                return a.SerialNumber < b.SerialNumber ? -1 : 1;
            });
            setState(prev => ({ ...prev, invoices, searchInvoiceNumber: '' }));
        }
        prevReducer_invoiceFetching.current = Reducer.invoiceFetching;
    }, [Reducer.invoiceFetching, Reducer.invoiceFetchingError]);

    useEffect(() => {
        // 保存の通信が完了後
        if (prevReducer_protectionPlanSaving.current && !Reducer.protectionPlanSaving && !Reducer.protectionPlanSavingError) {
            setState(prev => ({ ...prev, modalOpenFlag: true, confirmModalOpenFlag: false }));
        }
        prevReducer_protectionPlanSaving.current = Reducer.protectionPlanSaving;
    }, [Reducer.protectionPlanSaving, Reducer.protectionPlanSavingError]);

    useEffect(() => {
        // Purchase dateの値が変更されたとき
        if (prevState_purchaseDate.current !== state.purchaseDate) {
            // Repair service planを再度適用する
            const invoices = state.invoices.map(invoice => {
                const repairServicePlans = getRepairServicePlans(invoice.Model);
                invoice.PlanId = repairServicePlans.length ? repairServicePlans[0].Id : null;
                return invoice;
            });
            setState(prev => ({ ...prev, invoices }));
        }
        prevState_purchaseDate.current = state.purchaseDate;
    }, [state.purchaseDate]);

    function onTextChange(evn, data) {
        setState(prev => ({ ...prev, [data.name]: data.value }));
    }

    function onSelectEndUserChange(evn, data) {
        const { value } = data;
        const customerEndUser = Reducer.customerEndUsers.find(x => x.Id == value);
        setState(prev => ({
            ...prev,
            selectedEndUserId: value,
            endUserName: customerEndUser ? customerEndUser.EndUserName : '',
            contactName: customerEndUser ? customerEndUser.ContactName : '',
            email: customerEndUser ? customerEndUser.Email : '',
            addressLineOne: customerEndUser ? customerEndUser.AddressLineOne : '',
            addressLineTwo: customerEndUser ? customerEndUser.AddressLineTwo : '',
            addressLineThree: customerEndUser ? customerEndUser.AddressLineThree : '',
            city: customerEndUser ? customerEndUser.City : '',
            state: customerEndUser ? customerEndUser.State : '',
            zip: customerEndUser ? customerEndUser.Zip : '',
            country: customerEndUser ? customerEndUser.Country : 'US',
            telephone: customerEndUser ? customerEndUser.Telephone : '',
            fax: customerEndUser ? customerEndUser.Fax : '',
            note: customerEndUser ? customerEndUser.Note : '',
        }));
    }

    function onSaveEndUserClick(evn, data) {
        setState(prev => ({ ...prev, isSaveEndUser: data.checked }));
    }

    function continueToRegister() {
        Actions.fetchCustomerEndUsers(Reducer.csrftoken);
        window.scroll({ top: 0, behavior: 'smooth' });
        setState(prev => ({
            ...prev,
            // Formのクリア
            selectedEndUserId: -1,
            endUserName: '',
            contactName: '',
            email: '',
            addressLineOne: '',
            addressLineTwo: '',
            addressLineThree: '',
            city: '',
            state: '',
            zip: '',
            country: 'US',
            telephone: '',
            fax: '',
            note: '',
            po: '',
            purchaseDate: '',
            isSaveEndUser: true,
            searchInvoiceNumber: '',
            invoices: [],
            // モーダルを閉じる
            modalOpenFlag: false,
        }));
    }

    function formattedHeader() {
        // 送信する値の整形
        return {
            endUserName: state.endUserName.trim(),
            contactName: state.contactName.trim(),
            email: state.email.trim(),
            addressLineOne: state.addressLineOne.trim(),
            addressLineTwo: state.addressLineTwo.trim(),
            addressLineThree: state.addressLineThree.trim(),
            city: state.city.trim(),
            state: state.state,
            zip: state.zip.trim(),
            country: state.country,
            telephone: state.telephone.trim(),
            fax: state.fax.trim(),
            note: state.note.trim(),
            po: state.po.trim(),
            purchaseDate: state.purchaseDate.trim(),
        };
    }

    function formattedDetails() {
        return state.invoices.map(x => {
            return {
                companyId: x.CompanyId,
                invoiceNumber: x.InvoiceNumber,
                invoiceDate: moment(x.InvoiceDate).format('YYYY-MM-DD'),
                model: x.Model,
                serialNumber: x.SerialNumber,
                planId: x.PlanId,
            };
        });
    }

    function onConfirmClick() {
        Actions.invoiceFetchingError(false);

        const header = formattedHeader();
        const details = formattedDetails();
        // バリデーションチェック
        const validationErrors = validate(header, details);
        // バリデーションエラーがなければ、modalを開く
        setState(prev => ({
            ...prev,
            confirmModalOpenFlag: Object.keys(validationErrors).length === 0,
            validationErrors,
        }));
    }

    function backToList() {
        props.setProtectionPlanTabStatus(Constants.PROTECTIONPLAN_STAT_LIST);
    }

    function onInvoiceSearchClick() {
        let searchInvoiceNumber = state.searchInvoiceNumber.trim();

        if (!searchInvoiceNumber) {
            Actions.invoiceFetchingError(false);
            window.alert('Please enter Invoice #.');
        } else {
            Actions.fetchInvoices(Reducer.csrftoken, searchInvoiceNumber);
        }

        setState(prev => ({ ...prev, searchInvoiceNumber, validationErrors: {} }));
    }

    function onInvoiceDeleteClick(evn, data) {
        let invoices = state.invoices.filter(x => x.Id !== data.detailId);
        setState(prev => ({ ...prev, invoices }));
    }

    function validate(header, details) {
        const errors = {};

        if (header.endUserName == null || header.endUserName == '') {
            errors.endUserName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ENDUSERNAME_EMPTY;
        } else if (Functions.byteLengthOf(header.endUserName) > 100) {
            errors.endUserName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ENDUSERNAME_LENGTH;
        }
        if (header.contactName == null || header.contactName == '') {
            errors.contactName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAME_EMPTY;
        } else if (Functions.byteLengthOf(header.contactName) > 100) {
            errors.contactName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAME_LENGTH;
        }
        if (header.email == null || header.email == '') {
            errors.email = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_EMAILNULL;
        } else if (Functions.byteLengthOf(header.email) > 100) {
            errors.email = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_EMAILLENGTH;
        }
        // Address
        if (header.addressLineOne == null || header.addressLineOne == '') {
            errors.addressLineOne = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSNULL;
        } else if (Functions.byteLengthOf(header.addressLineOne) > 100) {
            errors.addressLineOne = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (Functions.byteLengthOf(header.addressLineTwo) > 100) {
            errors.addressLineTwo = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (Functions.byteLengthOf(header.addressLineThree) > 100) {
            errors.addressLineThree = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (header.city == null || header.city == '') {
            errors.city = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYNULL;
        } else if (Functions.byteLengthOf(header.city) > 100) {
            errors.city = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYLENGTH;
        }
        if (header.state == null || header.state == '') {
            errors.state = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_STATENULL;
        }
        if (header.zip == null || header.zip == '') {
            errors.zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPNULL;
        } else if (Functions.byteLengthOf(header.zip) > 100) {
            errors.zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPLENGTH;
        }
        if (header.telephone == null || header.telephone == '') {
            errors.telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONENULL;
        } else if (Functions.byteLengthOf(header.telephone) > 100) {
            errors.telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONELENGTH;
        }
        if (Functions.byteLengthOf(header.fax) > 100) {
            errors.fax = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_FAXLENGTH;
        }
        if (Functions.byteLengthOf(header.note) > 1000) {
            errors.note = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_NOTE_LENGTH;
        }

        // PO
        if (header.po == null || header.po == '') {
            errors.po = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_PONULL;
        } else if (Functions.byteLengthOf(header.po) > 100) {
            errors.po = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_POLENGTH;
        }
        if (header.purchaseDate == null || header.purchaseDate == '') {
            errors.purchaseDate = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_PURCHASEDATE_EMPTY;
        } else if (header.purchaseDate >= moment(tomorrow).format('YYYY-MM-DD')) {
            errors.purchaseDate = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_PURCHASEDATE_LATER_THAN_TODAY;
        }

        // Invoice Information
        if (details.length === 0) {
            errors.invoices = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_INVOICES_EMPTY;
        }

        return errors;
    }

    // 渡されたmodelNameに登録可能な保証プランのリストを返す
    function getRepairServicePlans(modelName) {
        // ModelRuleMasterから、パターンが一致するModelRuleを洗い出す
        const modelMatchResults = Reducer.modelRuleMaster.map(modelRule => ({
            id: modelRule.Id,
            ...modelRule.isModelMatch(modelName),
        }));

        // 洗い出したModelRuleが対象となっている保証プランのIdを洗い出す
        const repairServicePlanMasterIdSet = new Set();
        modelMatchResults.forEach(result => {
            if (!result.forward && !result.exact) { return; }
            Reducer.modelRulesForRepairServicePlanCoverage
                .filter(coverage => {
                    if (coverage.ModelRuleMasterId != result.id) { return false; }
                    if (coverage.isMatchPatternForwardMatching()) { return result.forward; }
                    if (coverage.isMatchPatternExactMatching()) { return result.exact; }
                    return false;
                })
                .forEach(x => repairServicePlanMasterIdSet.add(x.RepairServicePlanMasterId));
        });

        // 有効な保証プランを洗い出して返す
        return selectablePlanFiltering().filter(x => repairServicePlanMasterIdSet.has(x.Id));
    };

    function selectablePlanFiltering() {
        if (!state.purchaseDate) {
            return [];
        }
        const dateFormat = 'YYYY-MM-DD';
        const compareValue = moment(state.purchaseDate).format(dateFormat);

        const displayItems = Reducer.repairServicePlanMaster.filter(x => {
            const effectiveDate = moment(x.EffectiveDate).format(dateFormat);
            if (compareValue < effectiveDate) {
                return false;
            }
            if (x.ExpiryDate == null) {
                return true;
            }
            const expiryDate = moment(x.ExpiryDate).format(dateFormat);
            return compareValue <= expiryDate;
        });
        return displayItems;
    }

    const customerEndUserOptions = Reducer.customerEndUsers
        .sort((a, b) => {
            if (a.EndUserName != b.EndUserName) {
                return a.EndUserName < b.EndUserName ? -1 : 1;
            }
            if (a.ContactName != b.ContactName) {
                return a.ContactName < b.ContactName ? -1 : 1;
            }
            return 0;
        }).map(x => {
            return { key: x.Id, value: x.Id, text: `${x.EndUserName} (${x.ContactName})` };
        }).concat([{ key: -1, value: -1, text: 'Other (please specify)' }]);
    let stateOptions = Reducer.stateProvinceCodeMaster.map(x => {
        return { key: x.Code, value: x.Code, text: `${x.Code} - ${x.State}` };
    });

    let errorMessage = null;
    if (Object.keys(state.validationErrors).length !== 0) {
        errorMessage = <Message error>
            {Object.values(state.validationErrors).map(message => <>{message}<br /></>)}
        </Message>;
    } else if (Reducer.invoiceFetchingError || Reducer.customerEndUsersFetchingError) {
        errorMessage = <ErrorMessage active content={Reducer.errorMessage} />;
    }

    const confirmButton =
        <div style={{ marginLeft: 'auto' }}>
            <Button content='Confirm' icon='check' color='blue' onClick={onConfirmClick} />
        </div>;

    const invoiceDetails = (
        <Table striped celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell content='Invoice #' />
                    <Table.HeaderCell content='Invoice date' />
                    <Table.HeaderCell content='Model #' />
                    <Table.HeaderCell content='Serial #' />
                    <Table.HeaderCell content='Plan' />
                    <Table.HeaderCell content='Delete' collapsing />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {state.invoices.map(invoice => {
                    const selectedPlan = Reducer.repairServicePlanMaster.find(x => x.Id == invoice.PlanId);
                    return <Table.Row>
                        <Table.Cell content={invoice.InvoiceNumber} />
                        <Table.Cell content={moment(invoice.InvoiceDate).format('MM/DD/YY')} />
                        <Table.Cell content={invoice.Model} />
                        <Table.Cell content={invoice.SerialNumber} />
                        <Table.Cell content={selectedPlan ? selectedPlan.PlanName : '-'} />
                        <Table.Cell>
                            <Button color='red' icon='x' onClick={onInvoiceDeleteClick} detailId={invoice.Id} />
                        </Table.Cell>
                    </Table.Row>;
                })}
            </Table.Body>
        </Table>
    );

    return (
        <>
            <Header as='h2' icon='shield' content={`Protection Plan (Register)`} />
            {errorMessage}

            <Segment>
                <Header icon='user' content='End-user information' />
                <Divider />
                <Form>
                    <Form.Dropdown selection search label='Select end-user' width={8} options={customerEndUserOptions}
                        value={state.selectedEndUserId} onChange={onSelectEndUserChange}
                    />
                    <Form.Group widths='equal'>
                        <Form.Input required label='End-user name' name='endUserName' onChange={onTextChange}
                            value={state.endUserName} error={!!state.validationErrors.endUserName} />
                        <Form.Input required label='Contact name' name='contactName' onChange={onTextChange}
                            value={state.contactName} error={!!state.validationErrors.contactName} />
                        <Form.Input required label='Email' name='email' onChange={onTextChange}
                            value={state.email} error={!!state.validationErrors.email} />
                    </Form.Group>
                    <Form.Input required label='Address' name='addressLineOne' width={8} onChange={onTextChange}
                        value={state.addressLineOne} className='margin-btm' error={!!state.validationErrors.addressLineOne} />
                    <Form.Input name='addressLineTwo' width={8} className='margin-btm' onChange={onTextChange}
                        value={state.addressLineTwo} error={!!state.validationErrors.addressLineTwo} />
                    <Form.Input name='addressLineThree' width={8} onChange={onTextChange}
                        value={state.addressLineThree} error={!!state.validationErrors.addressLineThree} />
                    <Form.Group>
                        <Form.Input required label='City' name='city' width={4} onChange={onTextChange}
                            value={state.city} error={!!state.validationErrors.city} />
                        <Form.Dropdown required search selection label='State' name='state' width={4} placeholder='Select state'
                            options={stateOptions} onChange={onTextChange} value={state.state}
                            error={!!state.validationErrors.state} />
                        <Form.Input required label='Zip/Postal code' name='zip' width={4} onChange={onTextChange}
                            value={state.zip} error={!!state.validationErrors.zip} />
                        <Form.Input label='Country' width={4} value={state.country}>
                            <input disabled style={{ backgroundColor: 'rgba(100,100,100,0.2)' }} className='disabled-input' />
                        </Form.Input>
                    </Form.Group>
                    <Form.Group>
                        <Form.Input required label='Telephone' name='telephone' width={4} onChange={onTextChange}
                            value={state.telephone} error={!!state.validationErrors.telephone} />
                        <Form.Input label='Fax' name='fax' width={4} onChange={onTextChange}
                            value={state.fax} error={!!state.validationErrors.fax} />
                    </Form.Group>
                    <Form.TextArea label='Note' name='note' onChange={onTextChange} value={state.note}
                        error={!!state.validationErrors.note} />
                    <Form.Checkbox label='Save this end-user' checked={state.isSaveEndUser} onChange={onSaveEndUserClick} />
                </Form>
            </Segment>

            <Segment>
                <Header icon='cart' content='Purchase information' />
                <Divider />
                <Form>
                    <Form.Group>
                        <Form.Input required label='PO' width={4}
                            value={state.po} onChange={onTextChange} name='po'
                            error={!!state.validationErrors.po}
                        />
                        <Form.Input required type='date' label='Purchase date' width={4}
                            value={state.purchaseDate} onChange={onTextChange} name='purchaseDate'
                            error={!!state.validationErrors.purchaseDate}
                            max={moment(today).format('YYYY-MM-DD')}
                        />
                    </Form.Group>
                </Form>
            </Segment>

            <Segment>
                <Header icon='file text' content='Invoice information' />
                <Divider />
                <Form style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Form.Input label='Invoice #' width={4} placeholder='Enter Invoice #'
                        value={state.searchInvoiceNumber} onChange={onTextChange} name='searchInvoiceNumber'
                        error={Reducer.invoiceFetchingError}
                        action={
                            <Button primary type='button' content='Search' onClick={onInvoiceSearchClick} />
                        }
                    />
                    <Form.Field style={{ marginLeft: 'auto' }}>
                        <Button color='green' type='button'
                            onClick={() => setState(prev => ({ ...prev, planModalOpenFlag: true }))}
                        >View plan list
                        </Button>
                    </Form.Field>
                    <PlanModal
                        modalOpen={state.planModalOpenFlag}
                        purchaseDate={state.purchaseDate}
                        onCloseClick={() => setState(prev => ({ ...prev, planModalOpenFlag: false }))}
                        selectablePlanFiltering={selectablePlanFiltering}
                    />
                </Form>
                {state.invoices.length ? invoiceDetails : null}
            </Segment>
            <div style={{ display: 'flex' }}>{confirmButton}</div>
            {state.confirmModalOpenFlag ?
                <ProtectionPlanConfirm
                    modalOpen={state.confirmModalOpenFlag}
                    onCloseClick={() => setState(prev => ({ ...prev, confirmModalOpenFlag: false }))}
                    header={formattedHeader()}
                    invoices={formattedDetails()}
                    isSaveEndUser={state.isSaveEndUser}
                /> : null}

            <Dimmer page
                active={Reducer.invoiceFetching
                    || Reducer.customerEndUsersFetching || Reducer.protectionPlanListFetching}
            >
                <Loader content='Loading...' size='huge' />
            </Dimmer>
            <SaveCompleteModal
                modalOpen={state.modalOpenFlag}
                continueToRegister={continueToRegister}
                backToList={backToList}
            />
        </>
    )
}

function SaveCompleteModal(props) {
    return (
        <Modal dimmer open={props.modalOpen} size='tiny'>
            <Modal.Header>
                <Header icon='check' content='Saving is completed!' />
            </Modal.Header>
            <Modal.Content>
                <Header as='h3'>Please select the next operation.</Header>
                <Segment compact basic style={{ margin: 'auto' }}>
                    <Button color='blue' content='Continue to register' onClick={props.continueToRegister}
                        className='margin-btm' style={{ display: 'block' }} />
                    <Button color='blue' content='Go to History screen' onClick={props.backToList}
                        className='margin-btm' style={{ display: 'block' }} />
                </Segment>
            </Modal.Content>
        </Modal>
    );
}

function PlanModal(props) {
    return (
        <Modal dimmer open={props.modalOpen}>
            <Modal.Header>
                <Header content='Plan list' />
            </Modal.Header>
            <Modal.Content>
                <Message info content={Messages.REPAIRREQUEST_MSG_PROTECTIONPLAN_PLANLIST} />
                <Table striped celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell content='Plan name' />
                            <Table.HeaderCell content='Description' />
                            <Table.HeaderCell content='Price' />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {props.selectablePlanFiltering().map(p => (
                            <Table.Row>
                                <Table.Cell>{p.PlanName}</Table.Cell>
                                <Table.Cell>{p.Description}</Table.Cell>
                                <Table.Cell>${Functions.toNumberToLocaleString(p.getPrice(props.purchaseDate), 2)}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Close" onClick={props.onCloseClick} />
            </Modal.Actions>
        </Modal>
    );
}
