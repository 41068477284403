import { Card, Header, Icon } from 'semantic-ui-react';

import { useAppContext } from '../../Contexts/AppContext';

export default function SelectItem(props) {
    const { Actions } = useAppContext();

    function onSelectComponent() {
        Actions.setTabStatus(props.nextTabStatus);
    }

    return (
        <Card onClick={onSelectComponent}>
            <Card.Content style={{ paddingTop: 20, paddingBottom: 20 }}>
                <Header as='h2' icon>
                    <Icon name={props.icon} />
                    {props.header}
                    <Header.Subheader>
                        {props.subheader}
                    </Header.Subheader>
                </Header>
                {/* TODO: The CSS written for this label should be deleted along with it. */}
                {props.header == 'Protection Plan' ?
                    <p style={{ fontWeight: 'bold', textAlign: 'center', color: 'orange', fontSize: '20px' }}>
                        Under Preparation
                    </p>
                    : null
                }
            </Card.Content>
        </Card>
    );
}
