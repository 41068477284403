import { useState } from 'react';
import { Button, Container, Dimmer, Form, Header, Icon, Loader, Message, Segment } from 'semantic-ui-react';

import AppConfig from '../../AppConfig';
import * as Constant from '../../Constants/Constant';
import { useAppContext } from '../../Contexts/AppContext';
import * as Messages from '../../Resources/Messages';
import ErrorMessage from '../ErrorMessage';

const initialState = {
    userId: '',
    customerNumber: '',

    validationErrors: {},
    loading: false,
    loadingError: '',
    loadingSuccess: false,
};

export default function ForgotPassword() {
    const { Actions } = useAppContext();
    const [state, setState] = useState(initialState);

    function onFormChange(evn, data) {
        setState(prev => ({ ...prev, [data.name]: data.value }));
    }

    function onSubmitClick() {
        let validationErrors = {};
        if (!state.userId) {
            validationErrors.userId = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_USERID;
        }
        if (!state.customerNumber) {
            validationErrors.customerNumber = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_CUSTOMERNUMBER;
        }
        setState(prev => ({ ...prev, validationErrors, loadingError: '' }));
        if (!Object.keys(validationErrors).length) {
            getResetParam();
        }
    }

    function getResetParam() {
        setState(prev => ({ ...prev, loading: true, loadingSuccess: false }));
        fetch(AppConfig.ApiUrlLogin + '/resetParam', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId: state.userId, customerNumber: state.customerNumber })
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                setState(prev => ({ ...prev, loading: false, loadingSuccess: true }));
            })
            .catch(e => {
                setState(prev => ({ ...prev, loading: false, loadingError: e.message }));
            });
    }

    function toLoginScreen() {
        Actions.setTabStatus(Constant.REPAIRREQUEST_STAT_LOGIN);
    }

    let errorMessage = null;
    if (Object.keys(state.validationErrors).length) {
        let errors = Object.keys(state.validationErrors).map(x => <>{state.validationErrors[x]}<br /></>);
        errorMessage = <Message error>{errors}</Message>;
    } else if (state.loadingError) {
        errorMessage = <ErrorMessage content={state.loadingError} active page />;
    }

    return (
        <Container style={{ minHeight: '50vh' }}>
            <Segment style={{ maxWidth: 562.5, margin: 'auto' }}>
                {errorMessage}
                <Form size='big'>
                    <Form.Input fluid label='User ID' icon='user' iconPosition='left' name='userId' onChange={onFormChange} value={state.userId} error={!!state.validationErrors.userId} />
                    <Form.Input fluid label='Customer number' icon='building' iconPosition='left' name='customerNumber' onChange={onFormChange} value={state.customerNumber} error={!!state.validationErrors.customerNumber} />
                    <Button fluid color='blue' content='Submit' size='huge' onClick={onSubmitClick} />
                </Form>
                <Segment basic size='big'>
                    <a className='link_style' onClick={toLoginScreen}>Back to login</a>
                </Segment>
            </Segment>
            <Dimmer active={state.loading} page>
                <Loader content='Loading...' size='huge' />
            </Dimmer>
            <Dimmer active={state.loadingSuccess} page>
                <div>
                    <Header as='h2' icon inverted>
                        <Icon name='mail' />
                        {Messages.REPAIRREQUEST_MSG_SUCCESSHEADER_FORGOTPASSWORD}
                        <Header.Subheader>{Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_FORGOTPASSWORD}</Header.Subheader>
                    </Header>
                </div>
                <div style={{ display: 'flex' }}>
                    <Button basic inverted color='green' size='large' style={{ marginLeft: 'auto' }} onClick={toLoginScreen}>
                        <Icon name='check' />
                        OK
                    </Button>
                </div>
            </Dimmer>
        </Container>
    );
}
