import { useState } from 'react';
import { Button, Dimmer, Form, Loader, Modal } from 'semantic-ui-react';

import AppConfig from '../../AppConfig';
import { useAppContext } from '../../Contexts/AppContext';
import CustomerAddressBook from '../../Models/ViewModels/CustomerAddressBook';
import * as Messages from '../../Resources/Messages';
import ErrorMessage from '../ErrorMessage';

function generateInitialState(props, Reducer) {
    const addressBook = Reducer.customerAddressBook.find(x => x.Id == props.id);
    return {
        addressLine1: props.edit ? addressBook.AddressLineOne : '',
        addressLine2: props.edit ? addressBook.AddressLineTwo : '',
        addressLine3: props.edit ? addressBook.AddressLineThree : '',
        city: props.edit ? addressBook.City : '',
        state: props.edit ? addressBook.State : '',
        zip: props.edit ? addressBook.Zip : '',
        country: props.edit ? addressBook.Country : 'US',
        telephone: props.edit ? addressBook.Telephone : '',
        fax: props.edit ? addressBook.Fax : '',

        validationErrors: {},
        loading: false,
        loadingError: false,
        errorMessage: '',
    };
}

export default function AddressEditModal(props) {
    const { Reducer, Actions } = useAppContext();
    const [state, setState] = useState(() => generateInitialState(props, Reducer));

    // fetch
    //#region
    function addressInsert(saveData) {
        setState(prev => ({ ...prev, loading: true }));
        fetch(AppConfig.ApiUrlUserSetting + '/addressBook', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(Reducer.csrftoken),
            },
            body: JSON.stringify(saveData),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                let customerAddressBook = response.map(x => new CustomerAddressBook(x));
                Actions.setCustomerAddressBook(customerAddressBook);
                setState(prev => ({ ...prev, loading: false }));
                props.setSuccessFlag(true, false);
                props.onModalClose();
            })
            .catch(e => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    loadingError: true,
                    errorMessage: e.message,
                }));
            });
    }

    function addressUpdate(saveData) {
        setState(prev => ({ ...prev, loading: true }));
        fetch(AppConfig.ApiUrlUserSetting + '/addressBook', {
            mode: 'cors', method: 'put', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(Reducer.csrftoken),
            },
            body: JSON.stringify(saveData),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                let customerAddressBook = response.map(x => new CustomerAddressBook(x));
                Actions.setCustomerAddressBook(customerAddressBook);
                setState(prev => ({ ...prev, loading: false }));
                props.setSuccessFlag(false, true);
                props.onModalClose();
            })
            .catch(e => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    loadingError: true,
                    errorMessage: e.message,
                }));
            });
    }
    //#endregion

    function onValueChange(evn, data) {
        setState(prev => ({ ...prev, [data.stateName]: data.value }));
    }

    function onSubmitClick() {
        let saveData = createSaveData();
        let validationErrors = validationCheck(saveData);
        setState(prev => ({
            ...prev,
            ...saveData,
            validationErrors,
            loadingError: false,
            errorMessage: '',
        }));

        if (!Object.keys(validationErrors).length) {
            if (props.new) {
                addressInsert(saveData);
            } else if (props.edit) {
                addressUpdate(saveData);
            }
        }
    }

    function createSaveData() {
        return {
            id: props.id,
            addressLine1: state.addressLine1.trim(),
            addressLine2: state.addressLine2.trim(),
            addressLine3: state.addressLine3.trim(),
            city: state.city.trim(),
            state: state.state.trim(),
            zip: state.zip.trim(),
            country: state.country.trim(),
            telephone: state.telephone.trim(),
            fax: state.fax.trim(),
        };
    }

    function validationCheck(saveData) {
        let errors = {};

        if (!saveData.addressLine1) {
            errors.addressLine = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSNULL;
        } else if (saveData.addressLine1.length > 100 || saveData.addressLine2?.length > 100 || saveData.addressLine3?.length > 100) {
            errors.addressLine = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (!saveData.city) {
            errors.city = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYNULL;
        } else if (saveData.city.length > 100) {
            errors.city = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYLENGTH;
        }
        if (!saveData.state) {
            errors.state = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_STATENULL;
        }
        if (!saveData.zip) {
            errors.zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPNULL;
        } else if (saveData.zip.length > 100) {
            errors.zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPLENGTH;
        }
        if (!saveData.telephone) {
            errors.telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONENULL;
        } else if (saveData.telephone.length > 100) {
            errors.telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONELENGTH;
        }
        if (saveData.fax.length > 100) {
            errors.fax = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_FAXLENGTH;
        }

        if (Object.keys(errors).length == 0) {
            const userInformation = Reducer.userInformation;
            // CustomerMasterの住所と一致するかをチェック
            if (
                userInformation.AddressLineOne == saveData.addressLine1
                && userInformation.AddressLineTwo == saveData.addressLine2
                && userInformation.AddressLineThree == saveData.addressLine3
                && userInformation.City == saveData.city
                && userInformation.State == saveData.state
                && userInformation.Zip == saveData.zip
                && userInformation.Country == saveData.country
                && userInformation.Telephone == saveData.telephone
                && userInformation.Fax == saveData.fax
            ) {
                errors.duplicate = Messages.REPAIRREQUEST_MSG_VALIDATION_ADDRESS_DUPLICATE;
            }
            // AddressBookの住所と一致するかをチェック
            else if (Reducer.customerAddressBook.some(x => (
                x.Id != props.id
                && x.AddressLineOne == saveData.addressLine1
                && x.AddressLineTwo == saveData.addressLine2
                && x.AddressLineThree == saveData.addressLine3
                && x.City == saveData.city
                && x.State == saveData.state
                && x.Zip == saveData.zip
                && x.Country == saveData.country
                && x.Telephone == saveData.telephone
                && x.Fax == saveData.fax
            ))) {
                errors.duplicate = Messages.REPAIRREQUEST_MSG_VALIDATION_ADDRESS_DUPLICATE;
            }
        }

        return errors;
    }

    let title, submitButtonLabel;
    if (props.new) {
        title = 'Add new address';
        submitButtonLabel = 'Add';
    } else if (props.edit) {
        title = 'Edit address';
        submitButtonLabel = 'Save';
    }

    let errorMessage;
    if (Object.keys(state.validationErrors).length) {
        let content = Object.values(state.validationErrors).map(x => <>{x}<br /></>);
        errorMessage = <ErrorMessage content={content} />;
    } else if (state.loadingError) {
        errorMessage = <ErrorMessage content={state.errorMessage} active />;
    }

    return (
        <Modal open={props.new || props.edit}>
            <Modal.Header content={title} />
            <Modal.Content>
                {errorMessage}
                <Form>
                    <Form.Input label='Address' required error={!!state.validationErrors.addressLine}
                        value={state.addressLine1} onChange={onValueChange} stateName='addressLine1' />
                    <Form.Input error={!!state.validationErrors.addressLine}
                        value={state.addressLine2} onChange={onValueChange} stateName='addressLine2' />
                    <Form.Input error={!!state.validationErrors.addressLine}
                        value={state.addressLine3} onChange={onValueChange} stateName='addressLine3' />
                    <Form.Group widths='equal'>
                        <Form.Input label='City' required error={!!state.validationErrors.city}
                            value={state.city} onChange={onValueChange} stateName='city' />
                        <Form.Dropdown label='State' required selection search placeholder='Select state'
                            error={!!state.validationErrors.state}
                            options={Reducer.stateProvinceCodeMaster.map(x => (
                                { key: x.Code, value: x.Code, text: `${x.Code} - ${x.State}` }
                            ))}
                            value={state.state} onChange={onValueChange} stateName='state' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input label='Zip/Postal code' required error={!!state.validationErrors.zip}
                            value={state.zip} onChange={onValueChange} stateName='zip' />
                        <Form.Input label='Country' value={state.country}>
                            <input disabled style={{ backgroundColor: 'rgba(100,100,100,0.2)' }} className='disabled-input' />
                        </Form.Input>

                    </Form.Group>
                    <Form.Input label='Telephone' required error={!!state.validationErrors.telephone}
                        value={state.telephone} onChange={onValueChange} stateName='telephone' />
                    <Form.Input label='Fax' error={!!state.validationErrors.fax}
                        value={state.fax} onChange={onValueChange} stateName='fax' />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button content={submitButtonLabel} primary onClick={onSubmitClick} />
                <Button content='Close' onClick={props.onModalClose} />
            </Modal.Actions>

            <Dimmer active={state.loading}>
                <Loader content='Loading...' size='huge' />
            </Dimmer>
        </Modal>
    );
}
