import { useEffect, useRef, useState } from 'react';
import { Button, Dimmer, Loader, Message, Tab, Table } from 'semantic-ui-react';

import AppConfig from '../../AppConfig';
import { useAppContext } from '../../Contexts/AppContext';
import * as Messages from '../../Resources/Messages';
import ErrorMessage from '../ErrorMessage';
import EndUserEditModal from './EndUserEditModal';

const MODAL_STAT_ENDUSER_NEW = 'MODAL_STAT_ENDUSER_NEW';
const MODAL_STAT_ENDUSER_EDIT = 'MODAL_STAT_ENDUSER_EDIT';
const initialState = {
    modalStatus: '',
    id: 0,

    loading: false,
    loadingError: false,
    errorMessage: "",

    isAdditionSuccessFlag: false,
    isUpdateSuccessFlag: false,
    isDeleteSuccessFlag: false,
};

export default function EndUserTab() {
    const { Reducer, Actions } = useAppContext();
    const [state, setState] = useState(initialState);
    const prevReducer_manualDownloading = useRef(Reducer.manualDownloading);
    const prevReducer_logoutingError = useRef(Reducer.logoutingError);
    const prevState_modalStatus = useRef(state.modalStatus);

    useEffect(() => {
        return () => {
            flagInitialization(true);
        };
    }, [])

    useEffect(() => {
        // The function is execute when the user clicks the "Manual Download" button
        if ((!prevReducer_manualDownloading.current && Reducer.manualDownloading)
            || (!prevReducer_logoutingError.current && Reducer.logoutingError)) {
            setState(prev => ({
                ...prev,
                isAdditionSuccessFlag: false,
                isUpdateSuccessFlag: false,
                isDeleteSuccessFlag: false,
                loadingError: false,
                errorMessage: "",
            }));
        }
        prevReducer_manualDownloading.current = Reducer.manualDownloading;
        prevReducer_logoutingError.current = Reducer.logoutingError;
    }, [Reducer.manualDownloading, Reducer.logoutingError]);

    useEffect(() => {
        // EndUser edit modal has been opened
        if (!prevState_modalStatus.current && state.modalStatus) {
            flagInitialization();
        }
        prevState_modalStatus.current = state.modalStatus;
    }, [state.modalStatus]);

    function flagInitialization(isUnmount = false) {
        if (!isUnmount || !Reducer.userMasterFetchingError) {
            Actions.manualDownloadingError(false);
            Actions.masterDataFetchingError(false);
            Actions.userMasterFetchError(false);
            Actions.logoutFetchingError(false);
            setState(prev => ({
                ...prev,
                isAdditionSuccessFlag: false,
                isUpdateSuccessFlag: false,
                isDeleteSuccessFlag: false,
                loadingError: false,
                errorMessage: "",
            }));
        }
    }

    function endUserDelete(id) {
        setState(prev => ({ ...prev, loading: true }));
        fetch(AppConfig.ApiUrlUserSetting + '/endUser/' + id, {
            mode: 'cors', method: 'delete', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(Reducer.csrftoken),
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(endUsers => {
                Actions.setCustomerEndUsers(endUsers);
                setState(prev => ({
                    ...prev,
                    loading: false,
                    isDeleteSuccessFlag: true,
                }));
            })
            .catch(e => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    loadingError: true,
                    errorMessage: e.message,
                }));
            });
    }

    function onAddNewEndUserClick() {
        setState(prev => ({ ...prev, modalStatus: MODAL_STAT_ENDUSER_NEW }));
    }

    function onEditClick(id) {
        setState(prev => ({
            ...prev,
            modalStatus: MODAL_STAT_ENDUSER_EDIT,
            id,
        }));
    }

    function onDeleteClick(id) {
        flagInitialization();
        if (window.confirm(Messages.REPAIRREQUEST_MSG_CONFIRMMESSAGE_DELETE)) {
            endUserDelete(id);
        }
    }

    function modalClose() {
        setState(prev => ({ ...prev, modalStatus: '', id: 0 }));
    }

    function setSuccessFlag(isAdditionSuccessFlag, isUpdateSuccessFlag) {
        setState(prev => ({
            ...prev,
            isAdditionSuccessFlag,
            isUpdateSuccessFlag,
        }));
    }

    let errorMessage;
    if (state.loadingError) {
        errorMessage = <ErrorMessage active content={state.errorMessage} page />;
    } else if (Reducer.userMasterFetchingError || Reducer.manualDownloadingError || Reducer.logoutingError) {
        errorMessage = <ErrorMessage active content={Reducer.errorMessage} page />;
    }

    let successMessage;
    if (state.isAdditionSuccessFlag) {
        successMessage = <Message info content={Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_ENDUSER_ADDITION} />;
    } else if (state.isUpdateSuccessFlag) {
        successMessage = <Message info content={Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_ENDUSER_UPDATE} />;
    } else if (state.isDeleteSuccessFlag) {
        successMessage = <Message info content={Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_ENDUSER_DELETE} />;
    }

    let EndUserRows = Reducer.customerEndUsers
        .sort((a, b) => b.Id - a.Id)
        .map(x => {
            return (
                <Table.Row key={x.Id}>
                    <Table.Cell>{x.Id}</Table.Cell>
                    <Table.Cell style={{ wordWrap: "break-word" }}>{x.EndUserName}</Table.Cell>
                    <Table.Cell style={{ wordWrap: "break-word" }}>{x.ContactName}</Table.Cell>
                    <Table.Cell style={{ wordWrap: "break-word" }}>{x.Email}</Table.Cell>
                    <Table.Cell style={{ wordWrap: "break-word" }}>
                        <p className="endUserNote">{x.Note ? x.Note : "-"}</p>
                    </Table.Cell>
                    <Table.Cell collapsing>
                        <Button content='Edit' color='green' onClick={() => onEditClick(x.Id)} disabled={Reducer.userMasterFetchingError} />
                        <Button content='Delete' color='red' onClick={() => onDeleteClick(x.Id)} disabled={Reducer.userMasterFetchingError} />
                    </Table.Cell>
                </Table.Row>
            );
        });

    return (
        <Tab.Pane>
            {errorMessage}
            {successMessage}

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button primary content='Add new end-user' icon='plus' onClick={onAddNewEndUserClick} disabled={Reducer.userMasterFetchingError} />
            </div>
            <Table striped celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell content='No.' collapsing />
                        <Table.HeaderCell content='End-user name' />
                        <Table.HeaderCell content='Contact name' />
                        <Table.HeaderCell content='Email' />
                        <Table.HeaderCell content='Note' />
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {EndUserRows}
                </Table.Body>
            </Table>

            {state.modalStatus
                ? <EndUserEditModal
                    new={state.modalStatus === MODAL_STAT_ENDUSER_NEW}
                    edit={state.modalStatus === MODAL_STAT_ENDUSER_EDIT}
                    id={state.id}
                    onModalClose={modalClose}
                    setSuccessFlag={setSuccessFlag}
                />
                : null}
            <Dimmer active={state.loading} page>
                <Loader content='Loading...' size='huge' />
            </Dimmer>
        </Tab.Pane>
    );
}
