"use strict";

export default class {
    constructor(dataObject = null) {
        this.ShipToId = 0;
        this.ShipToDescription = '';
        this.Address = '';
        this.City = '';
        this.State = '';
        this.Zip = '';
        this.Country = '';

        // Import data if set
        if (dataObject) {
            try {
                this.ShipToId = dataObject.shipToId;
                this.ShipToDescription = dataObject.shipToDescription;
                this.Address = dataObject.address;
                this.City = dataObject.city;
                this.State = dataObject.state;
                this.Zip = dataObject.zip;
                this.Country = dataObject.country;
            }
            catch (e) {
                throw "Data import error @ ShipToMaster data.";
            }
        }
    }
}