"use strict";

export default class {
    constructor(dataObject = null) {
        this.Id = 0;
        this.EndUserName = '';
        this.ContactName = '';
        this.Email = '';
        this.AddressLineOne = '';
        this.AddressLineTwo = '';
        this.AddressLineThree = '';
        this.City = '';
        this.State = '';
        this.Zip = '';
        this.Country = '';
        this.Telephone = '';
        this.Fax = '';
        this.Note = '';
        this.PO = '';
        this.PurchaseDate = null;
        this.CreatedDate = null;
        this.CreatedByUserId = '';
        this.CreatedByUserName = '';
        this.UpdatedDate = null;
        this.CustomerNumber = "";
        this.CustomerName = "";
        this.IsNotified = false;
        this.LastNotificationDate = null;

        this.Details = [];

        //Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.EndUserName = dataObject.endUserName;
                this.ContactName = dataObject.contactName;
                this.Email = dataObject.email;
                this.AddressLineOne = dataObject.addressLineOne;
                this.AddressLineTwo = dataObject.addressLineTwo;
                this.AddressLineThree = dataObject.addressLineThree;
                this.City = dataObject.city;
                this.State = dataObject.state;
                this.Zip = dataObject.zip;
                this.Country = dataObject.country;
                this.Telephone = dataObject.telephone;
                this.Fax = dataObject.fax;
                this.Note = dataObject.note;
                this.PO = dataObject.po;
                this.PurchaseDate = new Date(dataObject.purchaseDate);
                this.CreatedDate = new Date(dataObject.createdDate);
                this.CreatedByUserId = dataObject.createdByUserId;
                this.CreatedByUserName = dataObject.createdByUserName;
                this.UpdatedDate = new Date(dataObject.updatedDate);
                this.CustomerNumber = dataObject.customerNumber;
                this.CustomerName = dataObject.customerName;
                this.IsNotified = dataObject.isNotified;
                this.LastNotificationDate = dataObject.lastNotificationDate ? new Date(dataObject.lastNotificationDate) : null;
            }
            catch (e) {
                throw 'Data import error @ ProtectionPlanHeaders data.';
            }
        }
    }
}
