import { useEffect, useState } from 'react';
import { Button, Dimmer, Dropdown, Header, Icon, Input, Loader, Pagination, Popup, Segment, Table } from 'semantic-ui-react';

import { useAppContext } from '../../../Contexts/AppContext';
import Functions from '../../../Functions';
import ErrorMessage from '../../ErrorMessage';
import HistoryDetail from './HistoryDetail';

const sortOptions = [
    { key: 'requestedDateDesc', value: 'requestedDateDesc', text: 'Requested date (DESC)' },
    { key: 'requestedDateAsc', value: 'requestedDateAsc', text: 'Requested date (ASC)' },
];
const initialState = {
    requestedDateFrom: '',
    requestedDateTo: '',
    modelSerial: '',
    shipTracking: '',

    currentPage: 1,
    sort: 'requestedDateDesc',
    openModal: false,
    selectedRepairRequestId: 0,
};

export default function History() {
    const { Reducer, Actions } = useAppContext();
    const [state, setState] = useState(initialState);

    useEffect(() => {
        Actions.requestFetch(Reducer.csrftoken, !!Reducer.repairRequests.length);

        return () => {
            loadingErrorFlagInitialization();
        };
    }, []);

    function loadingErrorFlagInitialization() {
        Actions.requestFetchingError(false);
        Actions.logoutFetchingError(false);
        Actions.manualDownloadingError(false);
    }

    function onSearchConditionChange(evn, data) {
        setState(prev => ({ ...prev, [data.name]: data.value, currentPage: 1 }));
    }

    function onClearClick() {
        setState(prev => ({
            ...prev,
            shipTracking: '',
            modelSerial: '',
            requestedDateFrom: '',
            requestedDateTo: '',
            currentPage: 1,
        }));
    }

    function onModalButtonClick(evn, data) {
        let selectedRepairRequestId = data.id ?? 0;
        setState(prev => ({ ...prev, openModal: !state.openModal, selectedRepairRequestId }));
        loadingErrorFlagInitialization();
    }

    function onSortChange(evn, data) {
        setState(prev => ({ ...prev, sort: data.value, currentPage: 1 }));
    }

    function onPageChange(evn, data) {
        setState(prev => ({ ...prev, currentPage: data.activePage }));
    }

    function filteringAndReordering() {
        let repairRequests = JSON.parse(JSON.stringify(Reducer.repairRequests));

        // filter
        if (state.requestedDateFrom) {
            let requestedDateFrom = new Date(state.requestedDateFrom).setHours(0, 0, 0, 0);
            repairRequests = repairRequests.filter(x => new Date(x.RequestedDate) >= requestedDateFrom);
        }
        if (state.requestedDateTo) {
            let requestedDateTo = new Date(state.requestedDateTo).setHours(23, 59, 59, 999);
            repairRequests = repairRequests.filter(x => new Date(x.RequestedDate) <= requestedDateTo);
        }
        if (state.modelSerial) {
            let modelSerial = state.modelSerial.toLocaleLowerCase();
            let filterdRepairRequests = [];
            repairRequests.forEach(header => {
                header.RepairDetails = header.RepairDetails.filter(x => x.Model.toLocaleLowerCase().indexOf(modelSerial) != -1 || x.SerialNumber.toLocaleLowerCase().indexOf(modelSerial) != -1);
                if (header.RepairDetails.length) {
                    filterdRepairRequests.push(header);
                }
            })
            repairRequests = filterdRepairRequests;
        }
        if (state.shipTracking) {
            let shipTracking = state.shipTracking.toLocaleLowerCase();
            repairRequests = repairRequests.filter(request => {
                return request.TrackingNumberDetails.some(x => x.TrackingNumber.toLocaleLowerCase().indexOf(shipTracking) != -1);
            });
        }

        // sort
        switch (state.sort) {
            case 'requestedDateAsc':
                repairRequests.sort((a, b) => {
                    return new Date(a.RequestedDate) - new Date(b.RequestedDate);
                });
                break;
            case 'requestedDateDesc':
            default:
                repairRequests.sort((a, b) => {
                    return new Date(b.RequestedDate) - new Date(a.RequestedDate);
                });
                break;
        }
        repairRequests = repairRequests.map(request => {
            request.RepairDetails.sort((a, b) => {
                if (a.Category == b.Category) {
                    if (a.Model == b.Model) {
                        return a.SerialNumber < b.SerialNumber ? -1 : 1;
                    }
                    return a.Model < b.Model ? -1 : 1;
                }
                return a.Category < b.Category ? -1 : 1;
            });
            return request;
        });

        return repairRequests;
    }

    let searchConditionItemStyle = { display: 'inline-block', paddingTop: 0, paddingRight: 0, paddingBottom: 0, marginBottom: 0 };
    let labelStyle = { marginRight: 10, fontWeight: 'bolder' };

    let errorMessage = null;
    if (Reducer.requestFetchingError || Reducer.logoutingError || Reducer.manualDownloadingError) {
        errorMessage = <ErrorMessage content={Reducer.errorMessage} active page />;
    }

    let tableRows = [];
    filteringAndReordering().forEach(request => {
        let requestedDate = Functions.dateForm(request.RequestedDate);
        let shipTrackingNumber = <Table.Cell content={request.TrackingNumberDetails[0].TrackingNumber} />;
        if (request.TrackingNumberDetails.length > 1) {
            shipTrackingNumber = <Popup trigger={<Table.Cell content={request.TrackingNumberDetails[0].TrackingNumber + ', ...'} />}>
                <Popup.Header>All ship tracking #</Popup.Header>
                <Popup.Content>
                    <ol class='weight-shipTrackingNumber'>
                        {request.TrackingNumberDetails.map(x => <li>{x.TrackingNumber}</li>)}
                    </ol>
                </Popup.Content>
            </Popup>;
        }
        request.RepairDetails.forEach(detail => {
            tableRows.push(
                <Table.Row>
                    <Table.Cell content={request.Id} />
                    <Table.Cell content={requestedDate} />
                    <Table.Cell content={request.ContactName} />
                    <Table.Cell content={detail.Category} />
                    <Table.Cell content={detail.Model} />
                    <Table.Cell content={detail.SerialNumber} />
                    <Table.Cell>
                        <pre>{detail.ProblemDescription}</pre>
                    </Table.Cell>
                    {shipTrackingNumber}
                    <Table.Cell>
                        <Button icon color='blue' onClick={onModalButtonClick} id={request.Id}>
                            <Icon.Group>
                                <Icon name='file alternate outline' />
                                <Icon corner name='search' style={{ color: 'black' }} />
                            </Icon.Group>
                        </Button>
                    </Table.Cell>
                </Table.Row>
            );
        });
    });

    return (
        <>
            <Header as='h2' icon='box' content='Shipping History' />
            {errorMessage}
            <Segment>
                <Header content='Search Condition' icon='filter' />
                <div style={{ display: 'flex' }}>
                    <div style={{ marginLeft: 'auto' }}>
                        <Segment style={{ paddingLeft: 0, ...searchConditionItemStyle }} basic>
                            <Button icon='x' color='red' onClick={onClearClick} />
                        </Segment>
                        <Segment style={searchConditionItemStyle} basic>
                            <label style={labelStyle}>Requested date</label>
                            <Input name='requestedDateFrom' type='date' value={state.requestedDateFrom} onChange={onSearchConditionChange} />
                            <span style={{ margin: 'auto 7px' }}>-</span>
                            <Input name='requestedDateTo' type='date' value={state.requestedDateTo} onChange={onSearchConditionChange} />
                        </Segment>
                        <Segment style={searchConditionItemStyle} basic>
                            <label style={labelStyle}>Model / Serial #</label>
                            <Input name='modelSerial' onChange={onSearchConditionChange} value={state.modelSerial} />
                        </Segment>
                        <Segment style={searchConditionItemStyle} basic>
                            <label style={labelStyle}>Ship tracking #</label>
                            <Input name='shipTracking' onChange={onSearchConditionChange} value={state.shipTracking} />
                        </Segment>
                    </div>
                </div>
            </Segment>
            <Pagination firstItem={null} lastItem={null} style={{ marginRight: 10 }} activePage={state.currentPage}
                totalPages={Math.ceil(tableRows.length / 30)} onPageChange={onPageChange} />
            <Dropdown selection options={sortOptions} value={state.sort} onChange={onSortChange} />
            <div style={{ overflowX: 'scroll', marginTop: 10 }}>
                <Table striped celled style={{ whiteSpace: 'nowarp' }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell content='No.' />
                            <Table.HeaderCell content='Requested date' />
                            <Table.HeaderCell content='Contact name' />
                            <Table.HeaderCell content='Category' />
                            <Table.HeaderCell content='Model' />
                            <Table.HeaderCell content='Serial #' />
                            <Table.HeaderCell content='Problem description' />
                            <Table.HeaderCell content='Ship tracking #' />
                            <Table.HeaderCell collapsing />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {tableRows.slice((state.currentPage - 1) * 30, state.currentPage * 30)}
                    </Table.Body>
                </Table>
            </div>
            {state.openModal ? <HistoryDetail onModalButtonClick={onModalButtonClick} id={state.selectedRepairRequestId} /> : ''}
            <Dimmer active={Reducer.requestFetching} page>
                <Loader content='Loading...' size='huge' />
            </Dimmer>
        </>
    );
}
