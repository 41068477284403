import { useState } from 'react';
import { Button, Dimmer, Form, Loader, Modal } from 'semantic-ui-react';

import AppConfig from '../../AppConfig';
import { useAppContext } from '../../Contexts/AppContext';
import Functions from '../../Functions';
import * as Messages from '../../Resources/Messages';
import ErrorMessage from '../ErrorMessage';

function generateInitialState(props, Reducer) {
    const EndUser = Reducer.customerEndUsers.find(x => x.Id == props.id);
    return {
        endUserName: props.edit ? EndUser.EndUserName : '',
        contactName: props.edit ? EndUser.ContactName : '',
        email: props.edit ? EndUser.Email : '',
        addressLine1: props.edit ? EndUser.AddressLineOne : '',
        addressLine2: props.edit ? EndUser.AddressLineTwo : '',
        addressLine3: props.edit ? EndUser.AddressLineThree : '',
        city: props.edit ? EndUser.City : '',
        state: props.edit ? EndUser.State : '',
        zip: props.edit ? EndUser.Zip : '',
        country: props.edit ? EndUser.Country : 'US',
        telephone: props.edit ? EndUser.Telephone : '',
        fax: props.edit ? EndUser.Fax : '',
        note: props.edit ? EndUser.Note : '',

        validationErrors: {},
        loading: false,
        loadingError: false,
        errorMessage: '',
    };
}

export default function EndUserEditModal(props) {
    const { Reducer, Actions } = useAppContext();
    const [state, setState] = useState(() => generateInitialState(props, Reducer));

    // CustomerEndUsersテーブルにデータを追加する
    function endUserInsert(saveData) {
        setState(prev => ({ ...prev, loading: true }));
        fetch(AppConfig.ApiUrlUserSetting + '/endUser', {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(Reducer.csrftoken),
            },
            body: JSON.stringify(saveData),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(endUsers => {
                Actions.setCustomerEndUsers(endUsers);
                setState(prev => ({ ...prev, loading: false }));
                props.setSuccessFlag(true, false);
                props.onModalClose();
            })
            .catch(e => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    loadingError: true,
                    errorMessage: e.message,
                }));
            });
    }

    // CustomerEndUsersテーブルのデータを更新する
    function endUserUpdate(saveData) {
        setState(prev => ({ ...prev, loading: true }));
        fetch(AppConfig.ApiUrlUserSetting + '/endUser', {
            mode: 'cors', method: 'put', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(Reducer.csrftoken),
            },
            body: JSON.stringify(saveData),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(endUsers => {
                Actions.setCustomerEndUsers(endUsers);
                setState(prev => ({ ...prev, loading: false }));
                props.setSuccessFlag(false, true);
                props.onModalClose();
            })
            .catch(e => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    loadingError: true,
                    errorMessage: e.message,
                }));
            });
    }

    function onValueChange(evn, data) {
        setState(prev => ({ ...prev, [data.stateName]: data.value }));
    }

    function onSubmitClick() {
        let saveData = createSaveData();
        let validationErrors = validationCheck(saveData);
        setState(prev => ({
            ...prev,
            ...saveData,
            validationErrors,
            loadingError: false,
            errorMessage: '',
        }));

        if (!Object.keys(validationErrors).length) {
            if (props.new) {
                endUserInsert(saveData);
            } else if (props.edit) {
                endUserUpdate(saveData);
            }
        }
    }

    function createSaveData() {
        return {
            id: Number(props.id),
            endUserName: state.endUserName.trim(),
            contactName: state.contactName.trim(),
            email: state.email.trim(),
            addressLine1: state.addressLine1.trim(),
            addressLine2: state.addressLine2.trim(),
            addressLine3: state.addressLine3.trim(),
            city: state.city.trim(),
            state: state.state.trim(),
            zip: state.zip.trim(),
            country: state.country.trim(),
            telephone: state.telephone.trim(),
            fax: state.fax.trim(),
            note: state.note.trim(),
        };
    }

    function validationCheck(saveData) {
        let errors = {};

        if (!saveData.endUserName) {
            errors.endUserName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ENDUSERNAMENULL;
        } else if (Functions.byteLengthOf(saveData.endUserName) > 100) {
            errors.endUserName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ENDUSERNAMELENGTH;
        }
        if (!saveData.contactName) {
            errors.contactName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAMENULL;
        } else if (Functions.byteLengthOf(saveData.contactName) > 100) {
            errors.contactName = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAMELENGTH;
        }
        if (!saveData.email) {
            errors.email = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_EMAILNULL;
        } else if (Functions.byteLengthOf(saveData.email) > 100) {
            errors.email = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_EMAILLENGTH;
        }
        if (!saveData.addressLine1) {
            errors.addressLine = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSNULL;
        } else if (Functions.byteLengthOf(saveData.addressLine1) > 100
            || Functions.byteLengthOf(saveData.addressLine2) > 100
            || Functions.byteLengthOf(saveData.addressLine3) > 100) {
            errors.addressLine = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH;
        }
        if (!saveData.city) {
            errors.city = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYNULL;
        } else if (Functions.byteLengthOf(saveData.city) > 100) {
            errors.city = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYLENGTH;
        }
        if (!saveData.state) {
            errors.state = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_STATENULL;
        }
        if (!saveData.zip) {
            errors.zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPNULL;
        } else if (Functions.byteLengthOf(saveData.zip) > 100) {
            errors.zip = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPLENGTH;
        }
        if (!saveData.telephone) {
            errors.telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONENULL;
        } else if (Functions.byteLengthOf(saveData.telephone) > 100) {
            errors.telephone = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONELENGTH;
        }
        if (Functions.byteLengthOf(saveData.fax) > 100) {
            errors.fax = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_FAXLENGTH;
        }
        if (Functions.byteLengthOf(saveData.note) > 1000) {
            errors.note = Messages.REPAIRREQUEST_MSG_VALIDATEMESSAGE_NOTELENGTH;
        }

        return errors;
    }

    let stateOptions = Reducer.stateProvinceCodeMaster.map(x => (
        { key: x.Code, value: x.Code, text: `${x.Code} - ${x.State}` }
    ));

    let errorMessageComponent;
    if (Object.keys(state.validationErrors).length) {
        let content = Object.values(state.validationErrors).map(x => <>{x}<br /></>);
        errorMessageComponent = <ErrorMessage content={content} />;
    } else if (state.loadingError) {
        errorMessageComponent = <ErrorMessage content={state.errorMessage} active />;
    }

    return (
        <Modal dimmer open={props.new || props.edit}>
            <Modal.Header content={props.new ? 'Add new end-user' : 'Edit end-user'} />
            <Modal.Content scrolling>
                {errorMessageComponent}
                <Form>
                    <Form.Input label='End-user name' required error={!!state.validationErrors.endUserName}
                        value={state.endUserName} onChange={onValueChange} stateName='endUserName' />
                    <Form.Input label='Contact name' required error={!!state.validationErrors.contactName}
                        value={state.contactName} onChange={onValueChange} stateName='contactName' />
                    <Form.Input label='Email' required error={!!state.validationErrors.email}
                        value={state.email} onChange={onValueChange} stateName='email' />
                    <Form.Input label='Address' required error={!!state.validationErrors.addressLine}
                        value={state.addressLine1} onChange={onValueChange} stateName='addressLine1' />
                    <Form.Input error={!!state.validationErrors.addressLine}
                        value={state.addressLine2} onChange={onValueChange} stateName='addressLine2' />
                    <Form.Input error={!!state.validationErrors.addressLine}
                        value={state.addressLine3} onChange={onValueChange} stateName='addressLine3' />
                    <Form.Group widths='equal'>
                        <Form.Input label='City' required error={!!state.validationErrors.city}
                            value={state.city} onChange={onValueChange} stateName='city' />
                        <Form.Dropdown label='State' required error={!!state.validationErrors.state}
                            selection search placeholder='Select state' options={stateOptions}
                            value={state.state} onChange={onValueChange} stateName='state' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input label='Zip/Postal code' required error={!!state.validationErrors.zip}
                            value={state.zip} onChange={onValueChange} stateName='zip' />
                        <Form.Input label='Country' value={state.country}>
                            <input disabled style={{ backgroundColor: 'rgba(100,100,100,0.2)' }} className='disabled-input' />
                        </Form.Input>

                    </Form.Group>
                    <Form.Input label='Telephone' required error={!!state.validationErrors.telephone}
                        value={state.telephone} onChange={onValueChange} stateName='telephone' />
                    <Form.Input label='Fax' error={!!state.validationErrors.fax}
                        value={state.fax} onChange={onValueChange} stateName='fax' />
                    <Form.TextArea label='Note' error={!!state.validationErrors.note}
                        value={state.note} onChange={onValueChange} stateName='note' />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button content={props.new ? 'Add' : 'Save'} primary onClick={onSubmitClick} />
                <Button content='Close' onClick={props.onModalClose} />
            </Modal.Actions>
            <Dimmer active={state.loading}>
                <Loader content='Loading...' size='huge' />
            </Dimmer>
        </Modal>
    );
}
