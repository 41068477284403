"use strict";

export default class {
    constructor(dataObject = null) {
        this.Id = 0;
        this.AddressLineOne = '';
        this.AddressLineTwo = '';
        this.AddressLineThree = '';
        this.City = '';
        this.State = '';
        this.Zip = '';
        this.Country = '';
        this.Telephone = '';
        this.Fax = '';

        //Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.AddressLineOne = dataObject.addressLineOne;
                this.AddressLineTwo = dataObject.addressLineTwo;
                this.AddressLineThree = dataObject.addressLineThree;
                this.City = dataObject.city;
                this.State = dataObject.state;
                this.Zip = dataObject.zip;
                this.Country = dataObject.country;
                this.Telephone = dataObject.telephone;
                this.Fax = dataObject.fax;
            }
            catch (e) {
                throw 'Data import error @ UserInformation data.';
            }
        }
    }
}
