import Root from './Root';
import AppContext from './Contexts/AppContext';

export default function App() {
    return (
        <AppContext>
            <Root />
        </AppContext>
    );
}
