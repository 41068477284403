import { useEffect, useRef, useState } from 'react';
import { Button, Dimmer, Loader, Message, Tab, Table } from 'semantic-ui-react';

import AppConfig from '../../AppConfig';
import { useAppContext } from '../../Contexts/AppContext';
import CustomerAddressBook from '../../Models/ViewModels/CustomerAddressBook';
import * as Messages from '../../Resources/Messages';
import ErrorMessage from '../ErrorMessage';
import AddressEditModal from './AddressEditModal';

const MODAL_STAT_ADDRESSBOOK_NEW = 'MODAL_STAT_ADDRESSBOOK_NEW';
const MODAL_STAT_ADDRESSBOOK_EDIT = 'MODAL_STAT_ADDRESSBOOK_EDIT';
const initialState = {
    modalStatus: '',
    customerAddressId: 0,

    loading: false,
    loadingError: false,
    errorMessage: '',

    isAdditionSuccessFlag: false,
    isUpdateSuccessFlag: false,
    isDeleteSuccessFlag: false,
};

export default function AddressBookTab() {
    const { Reducer, Actions } = useAppContext();
    const [state, setState] = useState(initialState);
    const prevReducer_manualDownloading = useRef(Reducer.manualDownloading);
    const prevState_modalStatus = useRef(state.modalStatus);

    useEffect(() => {
        return () => {
            loadingErrorFlagInitialization(true);
        };
    }, []);

    useEffect(() => {
        // The function is execute when the user clicks the "Manual Download" button
        if (!prevReducer_manualDownloading.current && Reducer.manualDownloading) {
            setState(prev => ({
                ...prev,
                loadingError: false,
                errorMessage: '',
                isAdditionSuccessFlag: false,
                isUpdateSuccessFlag: false,
                isDeleteSuccessFlag: false,
            }));
        }
        prevReducer_manualDownloading.current = Reducer.manualDownloading;
    }, [Reducer.manualDownloading]);

    useEffect(() => {
        // Address edit modal has been opened
        if (!prevState_modalStatus.current && state.modalStatus) {
            loadingErrorFlagInitialization();
        }
        prevState_modalStatus.current = state.modalStatus;
    }, [state.modalStatus]);

    function loadingErrorFlagInitialization(isUnmount = false) {
        if (!isUnmount || !Reducer.userMasterFetchingError) {
            Actions.manualDownloadingError(false);
            Actions.masterDataFetchingError(false);
            Actions.userMasterFetchError(false);
            Actions.logoutFetchingError(false);
            setState(prev => ({
                ...prev,
                loadingError: false,
                errorMessage: '',
                isAdditionSuccessFlag: false,
                isUpdateSuccessFlag: false,
                isDeleteSuccessFlag: false,
            }));
        }
    }

    function addressDelete(id) {
        setState(prev => ({ ...prev, loading: true }));
        fetch(AppConfig.ApiUrlUserSetting + '/addressBook/' + id, {
            mode: 'cors', method: 'delete', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(Reducer.csrftoken),
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        })
                }
                return response;
            })
            .then(response => response.json())
            .then(response => {
                let customerAddressBook = response.map(x => new CustomerAddressBook(x));
                Actions.setCustomerAddressBook(customerAddressBook);
                setState(prev => ({
                    ...prev,
                    loading: false,
                    isDeleteSuccessFlag: true,
                }));
            })
            .catch(e => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    loadingError: true,
                    errorMessage: e.message,
                }));
            });
    }

    function onModalStatusChange(modalStatus, customerAddressId = 0) {
        setState(prev => ({ ...prev, modalStatus, customerAddressId }));
    }

    function onDeleteClick(evn, data) {
        loadingErrorFlagInitialization();
        if (window.confirm(Messages.REPAIRREQUEST_MSG_CONFIRMMESSAGE_DELETE)) {
            addressDelete(data.id);
        }
    }

    function setSuccessFlag(isAdditionSuccessFlag, isUpdateSuccessFlag) {
        setState(prev => ({
            ...prev,
            isAdditionSuccessFlag,
            isUpdateSuccessFlag,
        }));
    }

    let managerFlag = Reducer.userInformation.ManagerFlag;

    let errorMessage;
    if (state.loadingError) {
        errorMessage = <ErrorMessage active content={state.errorMessage} page />;
    } else if (Reducer.userMasterFetchingError || Reducer.manualDownloadingError || Reducer.logoutingError) {
        errorMessage = <ErrorMessage active content={Reducer.errorMessage} page />;
    }

    let successMessage;
    if (state.isAdditionSuccessFlag) {
        successMessage = <Message info content={Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_ADDRESS_ADDITION} />;
    } else if (state.isUpdateSuccessFlag) {
        successMessage = <Message info content={Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_ADDRESS_UPDATE} />;
    } else if (state.isDeleteSuccessFlag) {
        successMessage = <Message info content={Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_ADDRESS_DELETE} />;
    }

    let addressRows = Reducer.customerAddressBook.map(x => {
        return (
            <Table.Row key={x.Id}>
                <Table.Cell>
                    <p>
                        {x.AddressLineOne}<br />
                        {x.AddressLineTwo ? x.AddressLineTwo : '-'}<br />
                        {x.AddressLineThree ? x.AddressLineThree : '-'}
                    </p>
                </Table.Cell>
                <Table.Cell content={x.City} />
                <Table.Cell content={x.State} />
                <Table.Cell content={x.Zip} />
                <Table.Cell content={x.Country} />
                <Table.Cell content={x.Telephone} />
                <Table.Cell content={x.Fax} />
                {managerFlag
                    ? <Table.Cell collapsing>
                        <Button color='green' content='Edit' disabled={Reducer.userMasterFetchingError}
                            onClick={() => onModalStatusChange(MODAL_STAT_ADDRESSBOOK_EDIT, x.Id)} />
                        <Button color='red' content='Delete' onClick={onDeleteClick} id={x.Id}
                            disabled={Reducer.userMasterFetchingError} />
                    </Table.Cell>
                    : null}
            </Table.Row>
        );
    });

    return (
        <Tab.Pane>
            {successMessage}
            {errorMessage}
            {managerFlag
                ? <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button primary content='Add new address' icon='plus' disabled={Reducer.userMasterFetchingError}
                        onClick={() => onModalStatusChange(MODAL_STAT_ADDRESSBOOK_NEW)} />
                </div>
                : null}
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell content='Address' />
                        <Table.HeaderCell content='City' />
                        <Table.HeaderCell content='State' />
                        <Table.HeaderCell content='Zip/Postal code' />
                        <Table.HeaderCell content='Country' />
                        <Table.HeaderCell content='Telephone' />
                        <Table.HeaderCell content='Fax' />
                        {managerFlag ? <Table.HeaderCell /> : null}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {addressRows}
                </Table.Body>
            </Table>

            {state.modalStatus
                ? <AddressEditModal id={state.customerAddressId}
                    new={state.modalStatus == MODAL_STAT_ADDRESSBOOK_NEW}
                    edit={state.modalStatus == MODAL_STAT_ADDRESSBOOK_EDIT}
                    onModalClose={() => onModalStatusChange('')}
                    setSuccessFlag={setSuccessFlag} />
                : null}

            <Dimmer active={state.loading} page>
                <Loader content='Loading...' size='huge' />
            </Dimmer>
        </Tab.Pane>
    );
}
