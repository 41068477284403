class AppConfig {
}

// Api Url userd .env variables
AppConfig.ApiUrl = process.env.REACT_APP_API_URL;

AppConfig.ApiUrlLogin = AppConfig.ApiUrl + "/login";
AppConfig.ApiUrlHistory = AppConfig.ApiUrl + "/history";
AppConfig.ApiUrlRequest = AppConfig.ApiUrl + "/request";
AppConfig.ApiUrlContactUs = AppConfig.ApiUrl + "/contactus";
AppConfig.ApiUrlUserSetting = AppConfig.ApiUrl + "/userSetting";
AppConfig.ApiUrlFileDownload = AppConfig.ApiUrl + "/fileDownload";
AppConfig.ApiUrlProtectionPlan = AppConfig.ApiUrl + "/protectionPlan";

export default AppConfig;
