import { useEffect, useRef, useState } from 'react';
import { Button, Dimmer, Form, Header, Icon, Loader, Message, Modal, Segment } from 'semantic-ui-react';

import AppConfig from '../../AppConfig';
import { useAppContext } from '../../Contexts/AppContext';
import * as Messages from '../../Resources/Messages';
import ErrorMessage from '../ErrorMessage';

function generateInitialState(Reducer) {
    return {
        contactName: Reducer.userInformation.UserName,
        email: Reducer.userInformation.UserId,
        subject: '',
        message: '',

        validationErrors: {},
        isModalOpen: false,
        loading: false,
        loadingError: '',
        loadingSuccess: false,
    };
}

export default function ContactUs() {
    const { Reducer, Actions } = useAppContext();
    const [state, setState] = useState(() => generateInitialState(Reducer));
    const prevReducer_manualDownloading = useRef(Reducer.manualDownloading);
    const prevReducer_logouting = useRef(Reducer.logouting);

    useEffect(() => {
        return () => {
            loadingErrorFlagInitialization();
        };
    }, []);

    useEffect(() => {
        if (
            (!prevReducer_manualDownloading.current && Reducer.manualDownloading)
            || (!prevReducer_logouting.current && Reducer.logouting)
        ) {
            setState(prev => ({
                ...prev,
                validationErrors: {},
                loadingError: '',
                loadingSuccess: false,
            }));
        }
        prevReducer_manualDownloading.current = Reducer.manualDownloading;
        prevReducer_logouting.current = Reducer.logouting;
    }, [Reducer.manualDownloading, Reducer.logouting]);

    function loadingErrorFlagInitialization() {
        Actions.logoutFetchingError(false);
        Actions.manualDownloadingError(false);
    }

    function onTextChange(evn, data) {
        setState(prev => ({ ...prev, [data.name]: data.value }));
    }

    function onConfirmClick() {
        let validationErrors = {};
        let email = state.email.replace(/\s+/g, '');
        if (!state.contactName) {
            validationErrors.contactName = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_CONTACTNAME;
        }
        if (!email) {
            validationErrors.email = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_EMAIL;
        }
        if (!state.subject) {
            validationErrors.subject = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_SUBJECT;
        }
        if (!state.message) {
            validationErrors.message = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_MESSAGE;
        }
        setState(prev => ({
            ...prev,
            validationErrors,
            loadingError: '',
            isModalOpen: !Object.keys(validationErrors).length,
            email,
        }));

        loadingErrorFlagInitialization();
    }

    function confirmModalClose() {
        setState(prev => ({
            ...prev,
            isModalOpen: false,
            loadingError: '',
        }));
    }

    function sendMail() {
        setState(prev => ({ ...prev, loading: true }));
        fetch(AppConfig.ApiUrlContactUs, {
            mode: 'cors', method: 'post', credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'csrftoken': JSON.stringify(Reducer.csrftoken),
            },
            body: JSON.stringify({
                contactName: state.contactName,
                email: state.email,
                subject: state.subject,
                body: state.message,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .then(function (err) {
                            throw new Error(err.Message);
                        });
                }
                setState(prev => ({ ...prev, isModalOpen: false, loading: false, loadingSuccess: true }));
            })
            .catch(e => {
                setState(prev => ({ ...prev, loading: false, loadingError: e.message }));
            });
    }

    function onOkClick() {
        setState(prev => ({
            ...prev,
            contactName: Reducer.userInformation.UserName,
            email: Reducer.userInformation.UserId,
            subject: '',
            message: '',
            loadingSuccess: false,
        }));
    }

    let errorMessage = null;
    if (Object.keys(state.validationErrors).length) {
        let errors = Object.keys(state.validationErrors).map(x => <>{state.validationErrors[x]}<br /></>);
        errorMessage = <Message error>{errors}</Message>;
    } else if (state.loadingError) {
        errorMessage = <ErrorMessage content={state.loadingError} active />;
    } else if (Reducer.logoutingError) {
        errorMessage = <ErrorMessage content={Reducer.errorMessage} active />;
    } else if (Reducer.manualDownloadingError) {
        errorMessage = <ErrorMessage content={Reducer.errorMessage} active page />;
    }

    let confirmModal = state.isModalOpen ?
        <Modal open={true} onClose={confirmModalClose}>
            <Modal.Content>
                <Message warning icon='exclamation triangle'
                    header={Messages.REPAIRREQUEST_MSG_WARNINGHEADER_CONTACTUS}
                    content={Messages.REPAIRREQUEST_MSG_WARNINGMESSAGE_CONTACTUS}
                />
                {errorMessage}
                <dl>
                    <dt>Contact Name</dt>
                    <dd>{state.contactName}</dd>
                    <dt>Email</dt>
                    <dd>{state.email}</dd>
                    <dt>Subject</dt>
                    <dd>{state.subject}</dd>
                    <dt>Message</dt>
                    <dd style={{ whiteSpace: 'pre-wrap' }}>{state.message}</dd>
                </dl>
            </Modal.Content>
            <Modal.Actions>
                <Button content='Cancel' onClick={confirmModalClose} />
                <Button content='Send' icon='paper plane outline' color='blue' onClick={sendMail} />
            </Modal.Actions>
        </Modal> : null;

    let successDimmer = state.loadingSuccess ?
        <Dimmer active={state.loadingSuccess} page>
            <Header as='h2' icon inverted>
                <Icon name='check' />
                {Messages.REPAIRREQUEST_MSG_SUCCESSMESSAGE_CONTACTUS}
            </Header>
            <div style={{ display: 'flex' }}>
                <Button basic inverted color='green' size='large' style={{ marginLeft: 'auto' }} onClick={onOkClick} icon='check' content='OK' />
            </div>
        </Dimmer> : null;

    return (
        <>
            <Header as='h2' content='Contact Us' icon='mail outline' />
            {errorMessage}
            <Segment>
                <Header icon='pencil' content='Send message' dividing />
                <Form>
                    <Form.Group>
                        <Form.Input label='Contact name' name='contactName' value={state.contactName} onChange={onTextChange} width={4} error={!!state.validationErrors.contactName} />
                        <Form.Input label='Email' name='email' value={state.email} onChange={onTextChange} width={4} error={!!state.validationErrors.email} />
                    </Form.Group>
                    <Form.Input label='Subject' name='subject' value={state.subject} onChange={onTextChange} error={!!state.validationErrors.subject} />
                    <Form.TextArea label='Message' rows={10} name='message' value={state.message} onChange={onTextChange} error={!!state.validationErrors.message} />
                    <div style={{ display: 'flex' }}>
                        <Button primary icon='check' content='Confirm' style={{ marginLeft: 'auto' }} onClick={onConfirmClick} />
                    </div>
                </Form>
            </Segment>
            {confirmModal}{successDimmer}
            <Dimmer active={state.loading} page>
                <Loader content='Loading...' size='huge' />
            </Dimmer>
        </>
    );
}
