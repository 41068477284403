import { useEffect, useRef, useState } from 'react';
import { Button, Dimmer, Header, Label, Loader, Message, Segment, Table } from 'semantic-ui-react';

import * as Constants from '../../../Constants/Constant';
import { useAppContext } from '../../../Contexts/AppContext';
import Functions from '../../../Functions';
import * as Messages from '../../../Resources/Messages';
import ErrorMessage from '../../ErrorMessage';
import BasicInformation from './BasicInformation';

function generateInitialState(props, Reducer) {
    return {
        proptectionPlan: Reducer.protectionPlans.find(x => x.Id == props.id),
        deletedFlag: false,
    };
};

export default function ProtectionPlanView(props) {
    const { Reducer, Actions } = useAppContext();
    const [state, setState] = useState(() => generateInitialState(props, Reducer));
    const prevReducer_protectionPlanFetching = useRef(Reducer.protectionPlanFetching);

    useEffect(() => {
        Actions.fetchProtectionPlan(Reducer.csrftoken, props.id);

        return () => {
            Actions.protectionPlanFetchingError(false);
            Actions.logoutFetchingError(false);
            Actions.manualDownloadingError(false);
        };
    }, []);

    useEffect(() => {
        // 選択したProtection planの最新データ取得完了後
        if (prevReducer_protectionPlanFetching.current && !Reducer.protectionPlanFetching && !Reducer.protectionPlanFetchingError) {
            const proptectionPlan = Reducer.protectionPlans.find(x => x.Id == props.id);
            if (proptectionPlan == null) {
                // 他のユーザがデータを削除した
                setState(prev => ({ ...prev, deletedFlag: true }));
            } else {
                setState(prev => ({ ...prev, proptectionPlan }));
            }
        }
        prevReducer_protectionPlanFetching.current = Reducer.protectionPlanFetching;
    }, [Reducer.protectionPlanFetching, Reducer.protectionPlanFetchingError]);

    function onBackToListClick() {
        props.setProtectionPlanTabStatus(Constants.PROTECTIONPLAN_STAT_LIST);
    }

    const pageTitle = <Header as='h2' icon='shield' content='Protection Plan History' />;
    const backToListButton = <Button content='Back to List' onClick={onBackToListClick} />;
    const buttons = <div className='flex-floatRight'>{backToListButton}</div>;

    let errorMessage = null;
    if (Reducer.protectionPlanFetchingError || Reducer.logoutingError || Reducer.manualDownloadingError) {
        errorMessage = <ErrorMessage active content={Reducer.errorMessage} page />;
    }

    if (state.deletedFlag) {
        return (
            <>
                <div style={{ display: 'flex' }}>
                    {pageTitle}
                    {buttons}
                </div>

                {errorMessage}
                <Message error content={Messages.REPAIRREQUEST_MSG_ERROROCCURRED_PROTECTIONPLAN_DELETED} />
            </>
        );
    }

    return (
        <>
            <div style={{ display: 'flex' }}>
                {pageTitle}
                {buttons}
            </div>

            {errorMessage}

            <BasicInformation proptectionPlan={state.proptectionPlan} />

            <Segment>
                <Header icon='user' content='End-user information' dividing />
                <dl>
                    <div style={marginRightStyle}>
                        <dt>End-user name</dt>
                        <dd>{state.proptectionPlan.EndUserName}</dd>
                    </div>
                    <div style={marginRightStyle}>
                        <dt>Contact name</dt>
                        <dd>{state.proptectionPlan.ContactName}</dd>
                    </div>
                    <div style={marginRightStyle}>
                        <dt>Email</dt>
                        <dd>{state.proptectionPlan.Email}</dd>
                    </div>
                </dl>
                <dl style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div style={marginRightStyle}>
                        <dt>Address</dt>
                        <dd>{state.proptectionPlan.AddressLineOne}</dd>
                        <dd>{state.proptectionPlan.AddressLineTwo ? state.proptectionPlan.AddressLineTwo : '-'}</dd>
                        <dd>{state.proptectionPlan.AddressLineThree ? state.proptectionPlan.AddressLineThree : '-'}</dd>
                        <dd>{state.proptectionPlan.City}, {state.proptectionPlan.State} {state.proptectionPlan.Zip}</dd>
                        <dd>{state.proptectionPlan.Country}</dd>
                    </div>
                    <div style={marginRightStyle}>
                        <dt>Telephone</dt>
                        <dd>{state.proptectionPlan.Telephone}</dd>
                    </div>
                    <div style={marginRightStyle}>
                        <dt>Fax</dt>
                        <dd>{state.proptectionPlan.Fax ? state.proptectionPlan.Fax : '-'}</dd>
                    </div>
                </dl>
                <dl>
                    <dt>Note</dt>
                    <dd>{state.proptectionPlan.Note ? state.proptectionPlan.Note : '-'}</dd>
                </dl>
            </Segment>

            <Segment>
                <Header icon='cart' content='Purchase information' dividing />
                <dl>
                    <div style={marginRightStyle}>
                        <dt>PO</dt>
                        <dd>{state.proptectionPlan.PO}</dd>
                    </div>
                    <div style={marginRightStyle}>
                        <dt>Purchase date</dt>
                        <dd>{Functions.dateForm(state.proptectionPlan.PurchaseDate)}</dd>
                    </div>
                </dl>
            </Segment>

            <Segment>
                <Header icon='file text' content='Invoice information' dividing />
                <Table striped celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell content='Invoice #' />
                            <Table.HeaderCell content='Invoice date' />
                            <Table.HeaderCell content='Model' />
                            <Table.HeaderCell content='Serial #' />
                            <Table.HeaderCell content='Plan' />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {state.proptectionPlan.Details
                            .sort((a, b) => {
                                if (a.InvoiceNumber != b.InvoiceNumber) {
                                    return a.InvoiceNumber < b.InvoiceNumber ? -1 : 1;
                                }
                                return 0;
                            }).map(detail => {
                                const plan = Reducer.repairServicePlanMaster.find(x => x.Id == detail.PlanId);
                                return <Table.Row key={detail.Id}>
                                    <Table.Cell content={detail.InvoiceNumber} />
                                    <Table.Cell content={Functions.dateForm(detail.InvoiceDate)} />
                                    <Table.Cell content={detail.Model} />
                                    <Table.Cell>
                                        {detail.IsReturned
                                            ? <Label content='Returned' color='red' horizontal />
                                            : null}
                                        {detail.SerialNumber}
                                    </Table.Cell>
                                    <Table.Cell content={plan ? plan.PlanName : '-'} />
                                </Table.Row>;
                            })}
                    </Table.Body>
                </Table>
            </Segment>
            <div style={{ display: 'flex' }}>{buttons}</div>

            <Dimmer active={Reducer.protectionPlanFetching} page>
                <Loader content='Loading...' size='huge' />
            </Dimmer>
        </>
    );
}

export const marginRightStyle = { marginRight: 20, display: 'inline-block' };
