import { useEffect, useRef, useState } from 'react';

import * as Constants from '../../Constants/Constant';
import { useAppContext } from '../../Contexts/AppContext';
import ProtectionPlanList from './History/ProtectionPlanList';
import ProtectionPlanView from './History/ProtectionPlanView';
import ProtectionPlanEdit from './Register/ProtectionPlanEdit';

function generateInitialState(Reducer) {
    return {
        tabStatus: (Reducer.tabStatus == Constants.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY)
            ? Constants.PROTECTIONPLAN_STAT_LIST
            : Constants.PROTECTIONPLAN_STAT_REGISTER,
        id: 0,
    };
};

export default function ProtectionPlan() {
    const { Reducer, Actions } = useAppContext();
    const [state, setState] = useState(() => generateInitialState(Reducer));
    const prevReducer_tabStatus = useRef(Reducer.tabStatus);
    const prevState_tabStatus = useRef(state.tabStatus);

    useEffect(() => {
        if (prevReducer_tabStatus.current !== Reducer.tabStatus) {
            const tabStatus = Reducer.tabStatus == Constants.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY
                ? Constants.PROTECTIONPLAN_STAT_LIST
                : Constants.PROTECTIONPLAN_STAT_REGISTER
            setState(prev => ({ ...prev, tabStatus }));
        }
        prevReducer_tabStatus.current = Reducer.tabStatus;
    }, [Reducer.tabStatus]);

    useEffect(() => {
        if (prevState_tabStatus.current !== state.tabStatus) {
            if (state.tabStatus === Constants.PROTECTIONPLAN_STAT_REGISTER) {
                Actions.setTabStatus(Constants.REPAIRREQUEST_STAT_PROTECTIONPLAN_REGISTER);
            } else {
                Actions.setTabStatus(Constants.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY);
            }
        }
        prevState_tabStatus.current = state.tabStatus;
    }, [state.tabStatus]);

    function setProtectionPlanTabStatus(tabStatus, id = 0) {
        window.scroll({ top: 0 });
        setState(prev => ({ ...prev, tabStatus, id }));
    }

    switch (state.tabStatus) {
        case Constants.PROTECTIONPLAN_STAT_LIST:
            return <ProtectionPlanList setProtectionPlanTabStatus={setProtectionPlanTabStatus} />;

        case Constants.PROTECTIONPLAN_STAT_REGISTER:
            return <ProtectionPlanEdit setProtectionPlanTabStatus={setProtectionPlanTabStatus} />;

        case Constants.PROTECTIONPLAN_STAT_VIEW:
            return <ProtectionPlanView setProtectionPlanTabStatus={setProtectionPlanTabStatus} id={state.id} />;

        default:
            return <div>No content...</div>;
    }
}
