import { Header, Segment } from 'semantic-ui-react';

import { useAppContext } from '../../../Contexts/AppContext';
import Functions from '../../../Functions';
import { marginRightStyle } from './ProtectionPlanView';

export default function BasicInformation(props) {
    const { Reducer } = useAppContext();

    return (
        <Segment>
            <Header icon='info' content='Basic information' dividing />
            <dl>
                <div style={marginRightStyle}>
                    <dt>No.</dt>
                    <dd>{props.proptectionPlan.Id}</dd>
                </div>
                {Reducer.userInformation.RepairCenterFlag
                    ? <div style={marginRightStyle}>
                        <dt>Customer</dt>
                        <dd>{`${props.proptectionPlan.CustomerName} (#${props.proptectionPlan.CustomerNumber})`}</dd>
                    </div>
                    : null
                }
                <div style={marginRightStyle}>
                    <dt>Registered date</dt>
                    <dd>{Functions.dateForm(props.proptectionPlan.CreatedDate)}</dd>
                </div>
                <div style={marginRightStyle}>
                    <dt>Registered by</dt>
                    <dd>{props.proptectionPlan.CreatedByUserName}</dd>
                </div>
                {Reducer.userInformation.RepairCenterFlag
                    ? <div style={marginRightStyle}>
                        <dt>updated date</dt>
                        <dd>{Functions.dateForm(props.proptectionPlan.UpdatedDate)}</dd>
                    </div>
                    : null
                }
                {Reducer.userInformation.RepairCenterFlag
                    ? <div style={marginRightStyle}>
                        <dt>Notification date to Repair Center</dt>
                        <dd>{props.proptectionPlan.IsNotified ? Functions.dateForm(props.protectionPlan.LastNotificationDate) : '-'}</dd>
                    </div>
                    : null
                }
            </dl>
        </Segment>
    );
}
