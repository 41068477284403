"use strict";

export default class {
    constructor(dataObject = null) {
        this.Id = 0;
        this.CompanyId = '';
        this.InvoiceNumber = '';
        this.InvoiceDate = null;
        this.Model = '';
        this.SerialNumber = '';
        this.PlanId = 0;
        this.IsReturned = false;
        this.IsDeleted = false;

        // Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.CompanyId = dataObject.companyId;
                this.InvoiceNumber = dataObject.invoiceNumber;
                this.InvoiceDate = new Date(dataObject.invoiceDate);
                this.Model = dataObject.model;
                this.SerialNumber = dataObject.serialNumber;
                this.PlanId = dataObject.planId;
                this.IsReturned = dataObject.isReturned;
                this.IsDeleted = dataObject.isDeleted;
            }
            catch (e) {
                throw "Data import error @ ProtectionPlanDetail data.";
            }
        }
    }
}