import { useEffect, useState } from 'react';
import { Button, Container, Dimmer, Form, Grid, Header, Icon, Image, Loader, Message, Segment } from 'semantic-ui-react';

import * as Constant from '../../Constants/Constant';
import { useAppContext } from '../../Contexts/AppContext';
import * as Messages from '../../Resources/Messages';
import ErrorMessage from '../ErrorMessage';

const initialState = {
    userId: '',
    password: '',
    showPassword: false,

    validationErrors: {},
};

export default function Login() {
    const { Reducer, Actions } = useAppContext();
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (!Object.keys(Reducer.information).length) {
            Actions.informationFetch();
        }

        return () => {
            Actions.loginCheckingError(false);
        };
    }, []);

    function onFormChange(evn, data) {
        setState(prev => ({ ...prev, [data.name]: data.value }));
    }

    function onLoginClick() {
        Actions.loginCheckingError(false);
        let validationErrors = {};
        if (!state.userId) {
            validationErrors.userId = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_USERID;
        }
        if (!state.password) {
            validationErrors.password = Messages.REPAIRREQUEST_MSG_NULLISHVALUE_PASSWORD;
        }

        let password = state.password;
        if (!Object.keys(validationErrors).length) {
            Actions.LoginCheck(state.userId, state.password);
            password = '';
        }
        setState(prev => ({ ...prev, validationErrors, password }));
    }

    function onForgotPasswordClick() {
        Actions.setTabStatus(Constant.REPAIRREQUEST_STAT_FORGOTPASSWORD);
    }

    function onShowPasswordClick() {
        setState(prev => ({ ...prev, showPassword: !state.showPassword }));
    }

    let errorMessage = null;
    if (Object.keys(state.validationErrors).length) {
        let errors = Object.keys(state.validationErrors).map(x => <>{state.validationErrors[x]}<br /></>);
        errorMessage = <Message error>{errors}</Message>;
    } else if (Reducer.loginCheckingError) {
        errorMessage = <ErrorMessage content={Reducer.errorMessage} active page />;
    }

    let information = (
        <Segment attached style={{ minHeight: 80 }}>
            {Reducer.informationIsLoading || Reducer.informationIsLoadingError ? null : <p>No information...</p>}
            <Dimmer active={Reducer.informationIsLoading}>
                <Loader content="Loading..." size='small' />
            </Dimmer>
            <Dimmer active={Reducer.informationIsLoadingError}>
                <Icon name='exclamation triangle' size='large' />
                <p>{Messages.REPAIRREQUEST_MSG_ERROROCCURRED_INFORMATION}</p>
            </Dimmer>
        </Segment>
    );
    if (Object.keys(Reducer.information).length) {
        let date = new Date(Reducer.information.notificationDate);
        information = (
            <Segment attached>
                <Header as='h3'>
                    {date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                </Header>
                <p>{Reducer.information.information}</p>
            </Segment>
        );
    }

    return (
        <Container style={{ minHeight: '50vh' }}>
            <Grid columns={2} stackable>
                <Grid.Column>
                    <Segment>
                        {errorMessage}
                        <Form size='big'>
                            <Form.Input fluid label='User ID' icon='user' iconPosition='left' error={!!state.validationErrors.userId} name='userId' onChange={onFormChange} value={state.userId} />
                            <Form.Input fluid label='Password' icon='lock' iconPosition='left' type={state.showPassword ? 'text' : 'password'} error={!!state.validationErrors.password} name='password' onChange={onFormChange} value={state.password} />
                            <Form.Checkbox label='Show password' onClick={onShowPasswordClick} />
                            <Button fluid color='blue' content='Login' size='huge' onClick={onLoginClick} />
                        </Form>
                        <Segment basic size='big'>
                            <a className='link_style' onClick={onForgotPasswordClick}>Forgot password?</a>
                        </Segment>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Header as='h2' icon='info' content='Information' attached='top' />
                    {information}
                    <Segment style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <div>
                            <p>Welcome to the JVCKENWOOD USA Service Website.</p>
                            <p>
                                This is a site for authorized users, only accessible for JVCKENWOOD Service Partners with a valid User ID and Password.<br />
                                Please set a password from link of "Forgot password". The User ID is your email address.
                            </p>
                            <p>End users: please visit the <a href="https://store.jvc.com/" className='link_style' target='jvc'>JVC</a> or <a href="https://store.kenwoodusa.com/" className='link_style' target='kenwood'>Kenwood</a> Accessory Store.</p>
                        </div>
                        <Image src='/images/radio_and_repeater_v1.png' alt='Image of radio and repeater'
                            spaced='left' id='radioAndRepeaterImage' className='maintainAspectRatio' />
                    </Segment>
                </Grid.Column>
            </Grid>
            <Dimmer active={Reducer.loginChecking} page>
                <Loader content='Loading...' size='huge' />
            </Dimmer>
        </Container>
    );
}
