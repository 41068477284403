"use strict";

export default class {
    constructor(dataObject = null) {
        this.Code = '';
        this.State = '';

        // Import data if set
        if (dataObject) {
            try {
                this.Code = dataObject.code;
                this.State = dataObject.state;
            }
            catch (e) {
                throw "Data import error @ StateProvinceCodeMaster data.";
            }
        }
    }
}