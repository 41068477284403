// alert, errorMessage, otherMessage
// Nullish Value
export const REPAIRREQUEST_MSG_NULLISHVALUE_USERID = 'Please Enter User ID.';
export const REPAIRREQUEST_MSG_NULLISHVALUE_CUSTOMERNUMBER = 'Please Enter Customer number.';
export const REPAIRREQUEST_MSG_NULLISHVALUE_PASSWORD = 'Please Enter Password.';
export const REPAIRREQUEST_MSG_NULLISHVALUE_NEWPASSWORD = 'Please Enter New password.';
export const REPAIRREQUEST_MSG_NULLISHVALUE_CONFIRMPASSWORD = 'Please Enter Confirm password.';
export const REPAIRREQUEST_MSG_NULLISHVALUE_CONTACTNAME = 'Please Enter Contact name.';
export const REPAIRREQUEST_MSG_NULLISHVALUE_EMAIL = 'Please Enter Email.';
export const REPAIRREQUEST_MSG_NULLISHVALUE_SUBJECT = 'Please Enter Subject.';
export const REPAIRREQUEST_MSG_NULLISHVALUE_MESSAGE = 'Please Enter Message.';

// Error Occurred
export const REPAIRREQUEST_MSG_ERROROCCURRED = 'An error has occurred while connecting. Please try again.';
export const REPAIRREQUEST_MSG_ERROROCCURRED_INFORMATION = 'An error has occured while loading information. Please reaccess the system.';
export const REPAIRREQUEST_MSG_ERROROCCURREDATDB = 'An error has occurred while connecting database. Please try again.';
export const REPAIRREQUEST_MSG_ERROROCCURRED_REPAIRDETAILS = 'Please enter at least one repairing model in Repair details.';
export const REPAIRREQUEST_MSG_ERROROCCURRED_PROTECTIONPLAN_DELETED = 'Another user has deleted the data. Please back to the list screen.';

// Reset Password
export const REPAIRREQUEST_MSG_PASSWORDVALIDATION = 'The password must be between 8 and 16 characters.';
export const REPAIRREQUEST_MSG_PASSWORD_CONTAIN_UNAVAILABLE_CHARACTER = 'The following special characters are not allowed ';// + '("\", " ").'
export const REPAIRREQUEST_MSG_RESETPASSWORD_NOTMATCH = 'New Password and Confirm New Password did not match.';
export const REPAIRREQUEST_MSG_PASSWORDREQUIREMENTS_TITLE = 'Password requirements';
export const REPAIRREQUEST_MSG_PASSWORDREQUIREMENTS_CONTENT_LENGTH = 'Must be between 8 and 16 characters.';
export const REPAIRREQUEST_MSG_PASSWORDREQUIREMENTS_CONTENT_SPECIALCHARACTERS = 'May include the following characters: ';// + '!"#$...'
export const REPAIRREQUEST_MSG_PASSWORDREQUIREMENTS_CONTENT_SPACES = 'Cannot have any spaces.';

// Success Message
export const REPAIRREQUEST_MSG_SUCCESSHEADER_RESETPASSWORD = 'Password setting completed!';
export const REPAIRREQUEST_MSG_SUCCESSMESSAGE_RESETPASSWORD = 'Please press OK button and login to the system.';
export const REPAIRREQUEST_MSG_SUCCESSHEADER_FORGOTPASSWORD = 'Accepted your reset password request!';
export const REPAIRREQUEST_MSG_SUCCESSMESSAGE_FORGOTPASSWORD = 'Please set a new password from the email sent to you.';
export const REPAIRREQUEST_MSG_SUCCESSMESSAGE_CONTACTUS = 'We have received your message and one of our representative will contact you soon.';
export const REPAIRREQUEST_MSG_SUCCESSMESSAGE_USERSETTING = 'Update completed!';
export const REPAIRREQUEST_MSG_SUCCESSMESSAGE_ADDRESS_ADDITION = 'Address addition completed!';
export const REPAIRREQUEST_MSG_SUCCESSMESSAGE_ADDRESS_UPDATE = 'Address updated successfully!';
export const REPAIRREQUEST_MSG_SUCCESSMESSAGE_ADDRESS_DELETE = 'Address deleted successfully!';
export const REPAIRREQUEST_MSG_SUCCESSMESSAGE_ENDUSER_ADDITION = 'End-user addition completed!';
export const REPAIRREQUEST_MSG_SUCCESSMESSAGE_ENDUSER_UPDATE = 'End-user updated successfully!';
export const REPAIRREQUEST_MSG_SUCCESSMESSAGE_ENDUSER_DELETE = 'End-user deleted successfully!';

// Validate Message
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_ENDUSERNAMELENGTH = 'Please enter End-user name within 100 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAMELENGTH = 'Please enter Contact name within 100 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_EMAILLENGTH = 'Please enter Email within 100 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSLENGTH = 'Please enter Address within 100 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYLENGTH = 'Please enter City within 100 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPLENGTH = 'Please enter Zip/Postal code within 100 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONELENGTH = 'Please enter Telephone within 100 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_FAXLENGTH = 'Please enter Fax within 100 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_SERIALNUMBERLENGTH = 'Please enter Serial # within 255 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_PROBLEMDESCRIPTIONLENGTH = 'Please enter Problem description within 255 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_ENDUSERNAMENULL = 'Please enter End-user name.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAMENULL = 'Please enter Contact name.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_EMAILNULL = 'Please enter Email.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_ADRESSNULL = 'Please enter Address.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_CITYNULL = 'Please enter City.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_STATENULL = 'Please select State.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_ZIPNULL = 'Please enter Zip/Postal code.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_TELEPHONENULL = 'Please enter Telephone.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_NOTELENGTH = 'Please enter Note within 1000 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_UPSSERVICECODENULL = 'Please select Shipping option.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_SHIPTONULL = 'Please select Ship to.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_WEIGHTNULL = 'Please enter a number between 0.1 and 150.0 for Weights.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_WEIGHTDIGITNUMBERLIMIT = 'Weights are valid to 1 decimal place.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_CATEGORYNULL = 'Please select Category.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_CATEGORYLIMIT = 'Please add at least one model with Category "Radio" to Repair details.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_MODELNULL = 'Please select Model.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_SERIALNUMBERNULL = 'Please enter Serial #, if Category is "Radio".';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_SERIALNUMBER = 'Please enter Serial # in alphanumeric characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_SERIALNUMBERINCORRECT = 'Please enter Serial # in 8 alphanumeric characters, if Category is "Radio".';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_PROBLEMDESCRIPTIONNULL = 'Please enter Problem description, if Category is "Radio".';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_REPAIRDETAILSNULL = 'Please enter at least one repairing model in Repair details.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERID_EMPTY = 'Please enter User ID.'
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERID_LENGTH = 'Please enter User ID within 100 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERID_DUPLICATE = 'is a duplicate. Please set unique User ID.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_USERNAME_LENGTH = 'Please enter User name within 255 characters.';
export const REPAIRREQUEST_MSG_VALIDATION_ADDRESS_DUPLICATE = 'A duplicate address has been found. Please enter a unique address.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_ENDUSERNAME_EMPTY = 'Please enter End-user name.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_ENDUSERNAME_LENGTH = 'Please enter End-user name within 100 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAME_EMPTY = 'Please enter Contact name.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_CONTACTNAME_LENGTH = 'Please enter Contact name within 100 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_NOTE_LENGTH = 'Please enter Note within 1000 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_PONULL = 'Please enter PO.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_POLENGTH = 'Please enter PO within 100 characters.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_PURCHASEDATE_EMPTY = 'Please enter Purchase date.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_INVOICES_EMPTY = 'Please add at least one invoice in Invoice information.';
export const REPAIRREQUEST_MSG_VALIDATEMESSAGE_PURCHASEDATE_LATER_THAN_TODAY = 'Please enter a date earlier than today in Purchase date.'

// Warning Message
export const REPAIRREQUEST_MSG_WARNINGHEADER_CONTACTUS = 'Message has not been sent yet.';
export const REPAIRREQUEST_MSG_WARNINGMESSAGE_CONTACTUS = 'Please click Send button below to confirm your message.';
export const REPAIRREQUEST_MSG_WARNINGMESSAGE_REQUIRED = 'Required if Category is "Radio".';
export const REPAIRREQUEST_MSG_WARNINGMESSAGE_NOCONTENT = 'There is no content to update.';
export const REPAIRREQUEST_MSG_WARNINGMESSAGE_ADDUSER = 'Some content has not been updated yet. The changes will be discarded if you add users, are you sure?\nTo reflect the changes, please press Update button first.';

// Confirm Message
export const REPAIRREQUEST_MSG_CONFIRMMESSAGE_DELETE = 'Are you sure you want to delete this data?';

// Error Message
export const REPAIRREQUEST_MSG_ERRORMESSAGE_INVOICE_CANNOT_REGISTER = 'This invoice has already been registered for other protection plans. Please enter a different invoice # to search.';

// User ID not match
export const REPAIRREQUEST_MSG_USERID_NOTMATCH = 'Your ID has changed. Please login again.';

// Request
export const REPAIRREQUEST_MSG_RQUEST_WEIGHT = 'Please enter a number between 0.1 and 150.0';
export const REPAIRREQUEST_MSG_WARRANTYPERIODVALIDATION_VERIFIED = 'Confirmed purchase information';
export const REPAIRREQUEST_MSG_WARRANTYPERIODVALIDATION_WARRANTYEXPIRED = 'Some products are out of warranty. Please delete them and reconfirm.';

// File download
export const REPAIRREQUEST_MSG_FILEDOWNLOAD_PACKINGSLIP = '* Please include one packing slip with all boxes.';

// Protection plan confirm modal
export const REPAIRREQUEST_MSG_PROTECTIONPLAN_PLANLIST = 'The plan information displayed will vary depending on the purchase date entered.';
