import { Button, Dropdown, Menu } from 'semantic-ui-react';

import { useAppContext } from '../Contexts/AppContext';
import * as Constant from '../Constants/Constant';

const menuItemsForGeneral = [
    {
        name: 'Shipping',
        children: [
            { name: 'New Shipping', tabStatus: Constant.REPAIRREQUEST_STAT_NEW_SHIPMENT },
            { name: 'History', tabStatus: Constant.REPAIRREQUEST_STAT_HISTORY },
        ],
    },
    {
        name: 'Protection Plan',
        children: [
            { name: 'Register', tabStatus: Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_REGISTER },
            { name: 'History', tabStatus: Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY },
        ],
    },
    { name: 'User Setting', tabStatus: Constant.REPAIRREQUEST_STAT_USERSETTING },
    { name: 'Contact Us', tabStatus: Constant.REPAIRREQUEST_STAT_CONATCTUS },
];
const menuItemsForRepairCenter = [
    {
        name: 'Protection Plan',
        children: [
            { name: 'History', tabStatus: Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY },
        ],
    },
    { name: 'User Setting', tabStatus: Constant.REPAIRREQUEST_STAT_USERSETTING },
    { name: 'Contact Us', tabStatus: Constant.REPAIRREQUEST_STAT_CONATCTUS },
];

export default function MenuBar() {
    const { Reducer, Actions } = useAppContext();

    function loadingErrorFlagInitialization() {
        Actions.loginCheckingError(false);
        Actions.requestFetchingError(false);
        Actions.masterDataFetchingError(false);
        Actions.requestSubmittingError(false);
        Actions.logoutFetchingError(false);
        Actions.userMasterFetchError(false);
        Actions.userSettingUpdatingError(false);
        Actions.customerUserAddingError(false);
        Actions.fileDownloadingError(false);
        Actions.manualDownloadingError(false);
        Actions.customerAddressBookFetchingError(false);
        Actions.protectionPlanListFetchingError(false);
        Actions.protectionPlanFetchingError(false);
        Actions.invoiceFetchingError(false);
        Actions.protectionPlanSavingError(false);
        Actions.customerEndUsersFetchingError(false);
    }

    function onMenuClick(evn, data) {
        Actions.setTabStatus(data.tabStatus);
    }

    function onLogoutClick() {
        loadingErrorFlagInitialization();
        Actions.logout();
    }

    function onDisclaimerClick() {
        Actions.setDisclaimer(true);
    }

    function onManualDownloadClick() {
        loadingErrorFlagInitialization();
        Actions.manualDownload(Reducer.csrftoken);
    }

    let userInformation = Object.keys(Reducer.userInformation).length
        ? `#${Reducer.userInformation.CustomerNumber} : ${Reducer.userInformation.UserName}` : "";

    let menuItems = Reducer.userInformation.RepairCenterFlag ? menuItemsForRepairCenter : menuItemsForGeneral;
    return (
        <Menu inverted secondary stackable>
            {menuItems.map(item => {
                if (item.hasOwnProperty('children')) {
                    const className = item.children.some(x => x.tabStatus === Reducer.tabStatus)
                        ? 'inverted active'
                        : 'inverted';
                    return <Dropdown item text={item.name} className={className}>
                        <Dropdown.Menu>
                            {item.children.map(item => (
                                <Dropdown.Item
                                    text={item.name}
                                    selected={item.tabStatus === Reducer.tabStatus}
                                    tabStatus={item.tabStatus}
                                    onClick={onMenuClick}
                                />
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>;
                }
                return <Menu.Item name={item.name} active={Reducer.tabStatus == item.tabStatus}
                    tabStatus={item.tabStatus} onClick={onMenuClick} />;
            })}
            <Menu.Menu position='right' stackable>
                <Menu.Item>{userInformation}</Menu.Item>
                <Button compact basic inverted content='Disclaimer' onClick={onDisclaimerClick} />
                <Button compact basic inverted content='Manual download' onClick={onManualDownloadClick} />
                <Button compact basic inverted content='Logout' onClick={onLogoutClick} />
            </Menu.Menu>
        </Menu>
    );
}
