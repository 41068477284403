import { Card, Dimmer, Loader, Segment } from 'semantic-ui-react';

import * as Constant from '../../Constants/Constant';
import { useAppContext } from '../../Contexts/AppContext';
import ErrorMessage from '../ErrorMessage';
import SelectItem from './SelectItem';

export default function ComponentSelect() {
    const { Reducer } = useAppContext();

    let errorMessage = null;
    if (Reducer.masterDataIsLoadingError || Reducer.logoutingError || Reducer.manualDownloadingError) {
        errorMessage = <ErrorMessage content={Reducer.errorMessage} active page />;
    }

    return (
        <>
            {errorMessage}
            <Segment placeholder basic>
                <Card.Group centered>
                    <SelectItem
                        icon='box'
                        header='Shipping'
                        subheader='Request shipping label for repair'
                        nextTabStatus={Constant.REPAIRREQUEST_STAT_NEW_SHIPMENT}
                    />
                    <SelectItem
                        icon='shield'
                        header='Protection Plan'
                        subheader='Register protection plan for sales product'
                        nextTabStatus={Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_REGISTER}
                    />
                </Card.Group>
            </Segment>
            <Dimmer active={Reducer.masterDataIsLoading || Reducer.fileDownloading || Reducer.customerAddressBookFetching} page>
                <Loader content="Loading..." size="huge"></Loader>
            </Dimmer>
        </>
    );
}
