"use strict";

export default class {
    constructor(dataObject = null) {
        this.Id = 0;
        this.CompanyId = '';
        this.Name = '';

        // Import data if set
        if (dataObject) {
            try {
                this.Id = dataObject.id;
                this.CompanyId = dataObject.companyId;
                this.Name = dataObject.name;
            }
            catch (e) {
                throw "Data import error @ ModelFromJbase data.";
            }
        }
    }
}