import { useEffect, useRef } from 'react';

import ComponentSelect from './Components/ComponentSelect/ComponentSelect';
import ContactUs from './Components/ContactUs/ContactUs';
import ProtectionPlan from './Components/ProtectionPlan/ProtectionPlan';
import History from './Components/Shipping/History/History';
import Request from './Components/Shipping/NewShipment/Request';
import UserSetting from './Components/UserSetting/UserSetting';
import * as Constant from './Constants/Constant';
import { useAppContext } from './Contexts/AppContext';

export default function Tab() {
    const { Reducer, Actions } = useAppContext();
    const prevReducer_masterDataIsLoading = useRef(Reducer.masterDataIsLoading);

    useEffect(() => {
        Actions.masterDataFetch(Reducer.csrftoken);
    }, []);

    useEffect(() => {
        if (prevReducer_masterDataIsLoading.current && !Reducer.masterDataIsLoading && !Reducer.masterDataIsLoadingError) {
            Actions.setDisclaimer(Reducer.userInformation.FirstLogin);
        }
        prevReducer_masterDataIsLoading.current = Reducer.masterDataIsLoading;
    }, [Reducer.masterDataIsLoading, Reducer.masterDataIsLoadingError]);

    let component;
    switch (Reducer.tabStatus) {
        case Constant.REPAIRREQUEST_STAT_NEW_SHIPMENT:
            component = <Request />;
            break;
        case Constant.REPAIRREQUEST_STAT_HISTORY:
            component = <History />;
            break;
        case Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_HISTORY:
        case Constant.REPAIRREQUEST_STAT_PROTECTIONPLAN_REGISTER:
            component = <ProtectionPlan />;
            break;
        case Constant.REPAIRREQUEST_STAT_USERSETTING:
            component = <UserSetting />;
            break;
        case Constant.REPAIRREQUEST_STAT_CONATCTUS:
            component = <ContactUs />;
            break;
        case Constant.REPAIRREQUEST_STAT_SELECT_COMPONENT:
            component = <ComponentSelect />;
            break;
        default:
            component = <div>No content...</div>;
            break;
    }

    return (
        <div className="App">
            {component}
        </div>
    );
}
