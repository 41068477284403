import { createContext, useReducer, useContext } from 'react';

import Reducer, { initialState } from '../Reducers/Reducer';
import Actions from '../Actions/Action';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export default function ApplicationProvider({ children }) {
    const [storeState, dispatch] = useReducer(Reducer, initialState);
    const actions = new Actions(dispatch);

    return (
        <AppContext.Provider value={{ Reducer: storeState, Actions: actions }}>
            {children}
        </AppContext.Provider>
    );
};
